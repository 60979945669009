import React from 'react';
import * as PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Button from '../button';
import withStyles from '../with-styles';

import MUI_STYLES from './styles';
import styles from './styles.less';

const withDialog = (options = {}) => WrappedComponent => {
  const {
    propName = null,
    expanded = false,
    submitButtonText = 'Submit',
    showSubmitButton = true
  } = options;

  class ComponentWithDialog extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isDialogOpen: false,
        dialogData: null
      };
    }

    render() {
      const {isDialogOpen, dialogData} = this.state;

      const dialogProps = {
        isDialogOpen,
        dialogData,
        renderDialog: this._renderDialog,
        openDialog: this._openDialog,
        closeDialog: this._closeDialog,
        setDialogData: this._setDialogData
      };

      const props = propName ? {
        [propName]: dialogProps,
        ...this.props
      } : {
        ...dialogProps,
        ...this.props
      };

      return (
        <WrappedComponent {...props}/>
      );
    }

    _renderDialog = ({renderTitle, renderContent, submitCallback, submitDisabledCallback, closeDisabledCallback}) => {
      const {fullScreen, dialogClasses} = this.props;

      return (
        <Dialog
          fullScreen={fullScreen}
          classes={{
            paper: (expanded ? dialogClasses.dialogPaperExpanded : dialogClasses.dialogPaper),
            paperFullScreen: dialogClasses.dialogPaperFullScreen
          }}
          open={this.state.isDialogOpen}
          onClose={(closeDisabledCallback && closeDisabledCallback()) ? null : this._closeDialog}
        >
          <DialogTitle>
            {
              renderTitle && renderTitle()
            }
            <div className={styles.divider}>
              <Divider/>
            </div>
          </DialogTitle>
          <DialogContent>
            {
              renderContent && renderContent()
            }
          </DialogContent>
          <DialogActions>
            {
              showSubmitButton && (
                <Button
                  size={Button.SIZE.EXTRA_LARGE}
                  text={submitButtonText}
                  disabled={submitDisabledCallback ? submitDisabledCallback() : false}
                  onClick={submitCallback}
                />
              )
            }
          </DialogActions>
        </Dialog>
      );
    };

    _openDialog = dialogData => {
      this.setState(state => {
        const newState = {
          ...state,
          isDialogOpen: true
        };

        if (dialogData !== undefined) {
          newState.dialogData = dialogData;
        }

        return newState;
      });
    };

    _closeDialog = () => {
      this.setState(state => ({
        ...state,
        isDialogOpen: false
      }));
    };

    _setDialogData = dialogData => {
      this.setState(state => ({
        ...state,
        dialogData
      }));
    };
  }

  ComponentWithDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    dialogClasses: PropTypes.object.isRequired
  };

  const Component = withStyles(MUI_STYLES)(withMobileDialog()(ComponentWithDialog), 'dialogClasses');

  Component.WrappedComponent = WrappedComponent;

  return Component;
};

export default withDialog;
