export const handleSubmitFailure = getErrors => (errors, onSubmitError, {setError}) => {
  if (!onSubmitError) {
    return;
  }

  const submitErrors = getErrors(onSubmitError);

  setImmediate(() => { // NOTE: Workaround
    Object.keys(submitErrors).forEach(key => {
      setError(key, submitErrors[key]);
    });
  });
};
