import {applyMiddleware, createStore as reduxCreateStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import {persistReducer, persistStore} from 'redux-persist';
import localForage from 'localforage';

import {apiInitialized} from '../lib/api';

import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer({
  key: 'redux-store',
  storage: localForage,
  whitelist: ['user', 'organisation']
}, reducers);

export function createStore() {
  const store = reduxCreateStore(persistedReducer, composeWithDevTools(
    applyMiddleware(sagaMiddleware)
  ));
  const persistor = persistStore(store, null, () => {
    store.dispatch(apiInitialized());
  });

  sagaMiddleware.run(sagas);

  return {store, persistor};
}
