import React from 'react';
import * as PropTypes from 'prop-types';

import useFirebaseEntity from '../../../hooks/use-firebase-entity';

import {getCampaignDefaultValues} from '../../../lib/form/default-values';
import {getCreateCampaignErrors, getEditCampaignErrors} from '../../../lib/form/error-getters';
import {getCreateCampaignFormFields, getEditCampaignFormFields} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';
import {createWindow, isCampaignActive} from '../../../lib/helper';

import CampaignPreview from '../../common/campaign-preview';
import EntityInfo from '../../common/entity-info';
import EntityPlaceholder from '../../common/entity-placeholder';
import FolderView from '../../common/folder-view';
import Form from '../../common/form';
import Page from '../../common/page';
import TreeView from '../../common/tree-view';
import withCurrentDate from '../../common/with-current-date';
import withDialog, {DIALOG_SHAPE} from '../../common/with-dialog';

import Editor from './editor';

import styles from './styles.less';

const Preview = ({campaign, schedules}) => {
  const [screens] = useFirebaseEntity('screens', [['defaultCampaignId', campaign.id]]);

  const openCampaignPreview = () => createWindow(`/campaigns/${campaign.id}/preview`);

  const isLive = (screens.length > 0) || schedules.some(({timeSlots}) => {
    return timeSlots.some(({campaignId}) => campaignId === campaign.id);
  });

  return (
    <div className={styles['campaign-preview']}>
      <div className={styles['campaign-preview-header']}>{isLive ? 'Live' : 'Preview'}</div>
      <div className={styles['campaign-preview-body']} onClick={openCampaignPreview}>
        <CampaignPreview campaign={campaign} userClassName={styles['campaign-preview-wrapper']}/>
      </div>
    </div>
  );
};

Preview.propTypes = {
  campaign: PropTypes.object.isRequired,
  schedules: PropTypes.arrayOf(PropTypes.object).isRequired
};

@withDialog({
  propName: 'createCampaignDialog',
  submitButtonText: 'Create Campaign'
})
@withDialog({
  propName: 'updateCampaignDialog',
  submitButtonText: 'Update Campaign'
})
@withCurrentDate()
class CampaignsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCampaignId: null,
      isEditorVisible: false,
      createCampaignFormHasErrors: false,
      updateCampaignFormHasErrors: false,
      isCreatingCampaign: false,
      isUpdatingCampaign: false
    };
  }

  render() {
    const {createCampaignDialog, updateCampaignDialog, isFetching} = this.props;
    const {selectedCampaignId} = this.state;

    return (
      <Page title="Campaigns" isLoading={isFetching}>
        <div className={styles.content}>
          <FolderView
            entityType={TreeView.ENTITY_TYPE.CAMPAIGN}
            createEntityText="Create a Campaign"
            selectedEntityId={selectedCampaignId}
            userClassName={styles.sidebar}
            checkEntityLiveCallback={this._checkEntityLive}
            onEntitySelect={this._handleEntitySelect}
            onEntityCreateRequested={this._handleEntityCreateRequested}
          />
          {
            selectedCampaignId ? this._renderCampaign() : (
              <EntityPlaceholder text="Select a campaign to view"/>
            )
          }
          {
            createCampaignDialog.isDialogOpen && this._renderCreateCampaignDialog()
          }
          {
            updateCampaignDialog.isDialogOpen && this._renderUpdateCampaignDialog()
          }
        </div>
      </Page>
    );
  }

  _renderCampaign() {
    const {updateCampaignDialog, currentDate, campaigns, folders, schedules} = this.props;
    const {selectedCampaignId, isEditorVisible} = this.state;

    const campaign = campaigns.find(item => item.id === selectedCampaignId);

    const menuItems = [{
      title: 'Edit Details',
      onClick: updateCampaignDialog.openDialog
    }, {
      title: 'Content Editor',
      onClick: this._showEditor
    }, 'separator', {
      title: 'Delete Campaign',
      secondary: true,
      onClick: this._deleteCampaign
    }];

    const badges = [];

    if (isCampaignActive(campaign.id, schedules, currentDate)) {
      badges.push({
        type: 'live',
        text: 'LIVE'
      });
    }

    if (campaign.folderId) {
      badges.push({
        type: 'folder',
        text: folders.find(fld => fld.id === campaign.folderId).name
      });
    }

    return (
      <div className={styles.campaign}>
        <div className={styles['campaign-info']}>
          <EntityInfo
            name={campaign.name}
            description={campaign.description}
            menuItems={menuItems}
            badges={badges}
          />
        </div>
        <Preview campaign={campaign} schedules={schedules}/>
        {
          isEditorVisible && (
            <Editor campaign={campaign} onCloseRequested={this._hideEditor} onSaveRequested={this._handleSave}/>
          )
        }
      </div>
    );
  }

  _renderCreateCampaignDialog() {
    const {createCampaignDialog} = this.props;
    const {isCreatingCampaign} = this.state;

    return createCampaignDialog.renderDialog({
      renderTitle: () => 'Create Campaign',
      renderContent: this._renderCreateCampaignDialogContent,
      submitCallback: this._submitCampaignForm,
      closeDisabledCallback: () => isCreatingCampaign,
      submitDisabledCallback: () => {
        const {isCreatingCampaign, createCampaignFormHasErrors} = this.state;

        return isCreatingCampaign || createCampaignFormHasErrors;
      }
    });
  }

  _renderCreateCampaignDialogContent = () => {
    const {createCampaignDialog, folders, organisations} = this.props;
    const {isCreatingCampaign} = this.state;

    const {folderId} = createCampaignDialog.dialogData;

    const defaultValues = {
      ...getCampaignDefaultValues(),
      folderId
    };

    return (
      <Form
        disabled={isCreatingCampaign}
        fields={getCreateCampaignFormFields({folders, organisations})}
        defaultValues={defaultValues}
        userClassName={styles['campaign-dialog-form']}
        submitCallbackRef={this._setSubmitCallbackRef}
        onChange={this._handleCreateCampaignFormChange}
        onSubmit={this._createCampaign}
        onSubmitFailure={handleSubmitFailure(getCreateCampaignErrors)}
      />
    );
  };

  _renderUpdateCampaignDialog() {
    const {updateCampaignDialog} = this.props;
    const {isUpdatingCampaign} = this.state;

    return updateCampaignDialog.renderDialog({
      renderTitle: () => 'Update Campaign',
      renderContent: this._renderUpdateCampaignDialogContent,
      submitCallback: this._submitCampaignForm,
      closeDisabledCallback: () => isUpdatingCampaign,
      submitDisabledCallback: () => {
        const {isUpdatingCampaign, updateCampaignFormHasErrors} = this.state;

        return isUpdatingCampaign || updateCampaignFormHasErrors;
      }
    });
  }

  _renderUpdateCampaignDialogContent = () => {
    const {campaigns, folders, organisations} = this.props;
    const {selectedCampaignId, isUpdatingCampaign} = this.state;

    const campaign = campaigns.find(item => item.id === selectedCampaignId);

    return (
      <Form
        disabled={isUpdatingCampaign}
        fields={getEditCampaignFormFields({folders, organisations})}
        defaultValues={getCampaignDefaultValues(campaign)}
        userClassName={styles['campaign-dialog-form']}
        submitCallbackRef={this._setSubmitCallbackRef}
        onChange={this._handleUpdateCampaignFormChange}
        onSubmit={this._updateCampaign}
        onSubmitFailure={handleSubmitFailure(getEditCampaignErrors)}
      />
    );
  };

  _checkEntityLive = ({id}, currentDate) => {
    const {schedules} = this.props;

    return isCampaignActive(id, schedules, currentDate);
  };

  _handleEntitySelect = id => {
    this.setState({selectedCampaignId: id});
  };

  _handleEntityCreateRequested = folderId => {
    const {createCampaignDialog} = this.props;

    return new Promise((resolve, reject) => {
      createCampaignDialog.openDialog({resolve, reject, folderId});
    });
  };

  _showEditor = () => {
    this.setState({isEditorVisible: true});
  };

  _hideEditor = () => {
    this.setState({isEditorVisible: false});
  };

  _handleSave = ({id, ...rest}) => {
    const {updateCampaign} = this.props;

    return updateCampaign(id, rest);
  };

  _deleteCampaign = () => {
    const {schedules, screens, deleteCampaign} = this.props;
    const {selectedCampaignId} = this.state;

    const isUsed = schedules.some(scd => {
      return scd.timeSlots.some(ts => ts.campaignId === selectedCampaignId);
    }) || screens.some(scr => scr.defaultCampaignId === selectedCampaignId);

    if (isUsed) {
      // eslint-disable-next-line no-alert
      alert('This campaign is used in schedule(s) and can\'t be deleted');

      return;
    }

    // eslint-disable-next-line no-alert
    if (!confirm('Are you sure?')) {
      return;
    }

    this.setState({selectedCampaignId: null}, async () => {
      try {
        await deleteCampaign(selectedCampaignId);
      } catch (err) {
        console.error(err);

        this.setState({selectedCampaignId});
      }
    });
  };

  _createCampaign = ({name, description, resolution, folderId}) => {
    const {createCampaignDialog, createCampaign} = this.props;

    const {dialogData} = createCampaignDialog;

    const [width, height] = resolution.split('x').map(value => Number(value));

    return new Promise((resolve, reject) => {
      this.setState({isCreatingCampaign: true}, async () => {
        try {
          const organisationSpecified = Boolean(folderId) && folderId.startsWith('organisation-');

          const data = {
            name,
            description,
            folderId: organisationSpecified ? null : folderId,
            data: {
              resolution: {width, height},
              items: [{
                backgroundColor: '#fff',
                layoutType: 'single',
                layoutData: [{
                  backgroundAsset: null
                }],
                texts: [],
                time: 90 * 1000
              }]
            }
          };

          if (organisationSpecified) {
            data.organisationId = folderId.match(/^organisation-(.+)/)[1];
          }

          const {item} = await createCampaign(data);

          createCampaignDialog.closeDialog();

          setTimeout(() => {
            dialogData.resolve(item);

            this.setState({
              isCreatingCampaign: false,
              selectedCampaignId: item.id,
              isEditorVisible: true
            }, resolve);
          }, 0);
        } catch (err) {
          dialogData.reject(err);

          this.setState({isCreatingCampaign: false}, reject);
        }
      });
    });
  };

  _updateCampaign = ({folderId, ...rest}) => {
    const {updateCampaignDialog, updateCampaign} = this.props;
    const {selectedCampaignId} = this.state;

    return new Promise((resolve, reject) => {
      this.setState({isUpdatingCampaign: true}, async () => {
        try {
          const data = {
            ...rest,
            folderId: (folderId && folderId.startsWith('organisation-')) ? null : folderId
          };

          await updateCampaign(selectedCampaignId, data);

          updateCampaignDialog.closeDialog();

          this.setState({isUpdatingCampaign: false}, resolve);
        } catch (err) {
          this.setState({isUpdatingCampaign: false}, reject);
        }
      });
    });
  };

  _handleCreateCampaignFormChange = ({errors}) => {
    this.setState({createCampaignFormHasErrors: Boolean(errors)});
  };

  _handleUpdateCampaignFormChange = ({errors}) => {
    this.setState({updateCampaignFormHasErrors: Boolean(errors)});
  };

  _submitCampaignForm = () => {
    if (!this.submitCallback) {
      return;
    }

    this.submitCallback();
  };

  _setSubmitCallbackRef = callback => {
    this.submitCallback = callback;
  };
}

CampaignsPage.WrappedComponent.WrappedComponent.WrappedComponent.propTypes = {
  createCampaignDialog: PropTypes.shape(DIALOG_SHAPE).isRequired,
  updateCampaignDialog: PropTypes.shape(DIALOG_SHAPE).isRequired,
  currentDate: PropTypes.object.isRequired
};

CampaignsPage.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  organisations: PropTypes.arrayOf(PropTypes.object),
  schedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  screens: PropTypes.arrayOf(PropTypes.object).isRequired,
  createCampaign: PropTypes.func.isRequired,
  deleteCampaign: PropTypes.func.isRequired,
  updateCampaign: PropTypes.func.isRequired
};

CampaignsPage.defaultProps = {
  organisations: null
};

export default CampaignsPage;
