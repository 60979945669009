import {RX_EMAIL} from '../constants';

export function validateCampaignName(value) {
  if (!value) {
    return 'Name is required';
  }
}

export function validateScheduleName(value) {
  if (!value) {
    return 'Name is required';
  }
}

export const validateScheduleScreenIds = (defaultScreenIds = [], schedules = []) => value => {
  if (!value || (value.length < 1)) {
    return 'At least one screen is required';
  }

  if (defaultScreenIds.length > 0) {
    const hasError = value.some(id => {
      if (defaultScreenIds.includes(id)) {
        return false;
      }

      return schedules.find(scd => scd.screenIds.includes(id));
    });

    if (hasError) {
      return 'Some screens are already used in another schedule';
    }
  }
};

export function validateTimeSlotCampaignId(value) {
  if (!value) {
    return 'Campaign is required';
  }
}

export function validateTimeSlotRepeat(value) {
  if (!value) {
    return 'Repeat type is required';
  }
}

export const validateTimeSlot = ({timeSlots, freeTimeRanges, freeTimeRangesNonWeek, currentDate}) => values => {
  const {date, timeStart, timeEnd, repeat} = values;

  const start = Number(timeStart);
  const end = Number(timeEnd);

  if (isNaN(start) || isNaN(end) || (start >= end)) {
    return {timeEnd: 'Invalid time range'};
  }

  const ranges = ((repeat !== 'daily') && freeTimeRangesNonWeek) ? freeTimeRangesNonWeek : freeTimeRanges;

  const range = ranges.find(rng => (start >= rng.timeStart) && (end <= rng.timeEnd));

  if (!range) {
    return {timeEnd: 'Invalid time range'};
  }

  if ((repeat === 'once') && !date) {
    return {date: 'Invalid date'};
  }

  if (repeat === 'daily') {
    const timeSlot = timeSlots
      .filter(ts => !currentDate || (ts.repeat !== 'once') || (ts.date >= currentDate))
      .find(ts => {
        return [start, end].some(time => (time > ts.timeStart) && (time < ts.timeEnd));
      });

    if (timeSlot) {
      return {timeEnd: 'Invalid time range'};
    }
  }

  return {date: null, timeEnd: null};
};

// Credentials

export function validateEmail(value) {
  if (!value) {
    return 'Email is required';
  }

  if (!RX_EMAIL.test(value)) {
    return 'Invalid email';
  }
}

export function validateUserName(value) {
  if (!value) {
    return 'Name is required';
  }
}

export function validatePassword(value) {
  if (!value) {
    return 'Password is required';
  }

  if (value.length < 8) {
    return 'Password is too short';
  }
}

export function validatePasswordsMatch(values) {
  if (values.password !== values.password2) {
    return {password2: 'Passwords must match'};
  }

  return {password2: null};
}

export function validateUserRole(value) {
  if (!value) {
    return 'User role is required';
  }
}

export function validateFolderName(value) {
  if (!value) {
    return 'Folder name is required';
  }
}

export function validateScreenName(value) {
  if (!value) {
    return 'Name is required';
  }
}

export function validateResolution(value) {
  if (!value) {
    return 'Resolution is required';
  }
}
