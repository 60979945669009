import axios from 'axios';
import * as firebase from 'firebase/app';

import {createApiAction} from '../../../lib/api';
import {stringifyQuery} from '../../../lib/helper';

import {API} from '../../action-types';

interface ICreateSingleData {
  name: string;
}

interface IDeleteSingleData {
  id: string;
}

interface IUpdateSingleData extends IDeleteSingleData, ICreateSingleData {}

export const createSingle = createApiAction(
  API.ORGANISATIONS.CREATE_SINGLE.ACTION,
  async ({data}: {data: ICreateSingleData}) => {
    const organisations = firebase.firestore().collection('organisations');

    const organisationRef = await organisations.add({...data});

    return {
      item: {
        ...data,
        id: organisationRef.id
      }
    };
  }
);

export const deleteSingle = createApiAction(
  API.ORGANISATIONS.DELETE_SINGLE.ACTION,
  async ({data: {id}}: {data: IDeleteSingleData}) => {
    const {currentUser} = firebase.auth();

    if (!currentUser) {
      throw new Error('currentUser is null');
    }

    const token = await currentUser.getIdToken(true);

    const query = stringifyQuery({organisationId: id});

    await axios.request({
      method: 'delete',
      url: `https://us-central1-voyr-6753d.cloudfunctions.net/express/deleteOrganisation?${query}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
);

export const updateSingle = createApiAction(
  API.ORGANISATIONS.UPDATE_SINGLE.ACTION,
  async ({data: {id, ...rest}}: {data: IUpdateSingleData}) => {
    const organisations = firebase.firestore().collection('organisations');

    await organisations
      .doc(id)
      .update({...rest});
  }
);
