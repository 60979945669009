import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import Icon from '../icon';
import Input from '../input';
import MenuContainer from '../menu-container';
import withCurrentDate from '../with-current-date';

import styles from './styles.less';

const NESTING_PADDING_OFFSET = 20.5;
const NESTING_PADDING_VLINE = NESTING_PADDING_OFFSET + 3;
const NESTING_PADDING_NODE = 12;
const NESTING_PADDING_ENTITY = 27;

function _findChildren(folders, entities, id) {
  const childFolders = folders
    .filter(folder => folder.parentId === id)
    .map(folder => ({
      ...folder,
      children: _findChildren(folders, entities, folder.id)
    }));

  return [
    ...childFolders,
    ...entities.filter(entity => entity.folderId === id)
  ];
}

function _createTree(type, folders, entities, organisations) {
  const foldersFiltered = folders.filter(folder => folder.type === type);

  const topLevelEntities = entities.filter(entity => !entity.folderId);

  const topLevelFolders = foldersFiltered
    .filter(folder => !folder.parentId)
    .map(folder => ({
      ...folder,
      children: _findChildren(foldersFiltered, entities, folder.id)
    }));

  if (!organisations) {
    return {
      children: [...topLevelFolders, ...topLevelEntities]
    };
  }

  return {
    children: organisations.map(({id, name}) => ({
      name,
      id: `organisation-${id}`,
      children: [
        ...topLevelFolders.filter(folder => folder.organisationId === id),
        ...topLevelEntities.filter(entity => entity.organisationId === id)
      ]
    }))
  };
}

function _createFolderNodesData({folders, entities, organisations, defaultSelectedEntityId, defaultOpenedFolderId}) {
  if (defaultSelectedEntityId) {
    const entity = entities.find(entity => entity.id === defaultSelectedEntityId);

    if (!entity.folderId) {
      if (organisations) {
        return {
          [`organisation-${entity.organisationId}`]: {isOpened: true}
        };
      }

      return {};
    }

    const folderNodesData = {
      [entity.folderId]: {isOpened: true}
    };

    let folder = folders.find(folder => folder.id === entity.folderId);

    while (folder.parentId) {
      folderNodesData[folder.parentId] = {isOpened: true};

      folder = folders.find(folder => folder.id === folder.parentId);
    }

    if (organisations) {
      folderNodesData[`organisation-${folder.organisationId}`] = {isOpened: true};
    }

    return folderNodesData;
  }

  if (defaultOpenedFolderId) {
    const folderNodesData = {};

    let folder = folders.find(folder => folder.id === defaultOpenedFolderId);

    if (!folder) {
      folder = organisations.find(org => `organisation-${org.id}` === defaultOpenedFolderId);
    }

    while (folder.parentId) {
      folderNodesData[folder.parentId] = {isOpened: true};

      folder = folders.find(folder => folder.id === folder.parentId);
    }

    if (organisations) {
      if (folder.organisationId) {
        folderNodesData[`organisation-${folder.organisationId}`] = {isOpened: true};
      }
    }

    return folderNodesData;
  }

  return {};
}

@withCurrentDate()
class TreeView extends React.Component {
  static get ENTITY_TYPE() {
    return {
      CAMPAIGN: 'campaign',
      SCHEDULE: 'schedule',
      SCREEN: 'screen'
    };
  }

  constructor(props) {
    super(props);

    const {folders, entities, organisations, defaultSelectedEntityId, defaultOpenedFolderId} = props;

    this.state = {
      folderNodesData: _createFolderNodesData({
        folders,
        entities,
        organisations,
        defaultSelectedEntityId,
        defaultOpenedFolderId
      }),
      isFolderMenuHovered: false,
      editedFolderId: null,
      newFolderName: null,
      activeMenuFolderId: null
    };
  }

  render() {
    const {
      badgesDisabled,
      entityType,
      folders,
      entities,
      organisations,
      userClassName,
      userStyle,
      selectEntityCallback,
      openFolderCallback,
      editFolderCallback
    } = this.props;

    if (selectEntityCallback) {
      selectEntityCallback(this._selectEntity);
    }

    if (openFolderCallback) {
      openFolderCallback(this._openFolder);
    }

    if (editFolderCallback) {
      editFolderCallback(id => {
        this._openFolder(id);
        this._editFolder(id)();
      });
    }

    const root = _createTree(entityType, folders, entities, organisations);

    const treeViewClassNames = classNames(styles['tree-view'], userClassName);

    return (
      <div className={treeViewClassNames} style={userStyle}>
        {
          root.children.map(this._renderNode(0, badgesDisabled))
        }
      </div>
    );
  }

  _renderNode = (nestingLevel = 0, badgesDisabled = false) => node => {
    if (node.children) {
      return this._renderFolder(node, nestingLevel, badgesDisabled);
    }

    return this._renderEntity(node, nestingLevel, badgesDisabled);
  };

  _renderFolder({id, name, children}, nestingLevel, badgesDisabled) {
    const {selectedEntityId, folderSelectable, editable, defaultOpenedFolderId} = this.props;
    const {folderNodesData, editedFolderId, newFolderName, activeMenuFolderId} = this.state;

    const nodeData = folderNodesData[id];

    const isDisabled = children.length < 1;
    const isOpened = !isDisabled && Boolean(nodeData && nodeData.isOpened);
    const isEditing = id === editedFolderId;
    const isActive = Boolean(children.find(child => !child.children && (child.id === selectedEntityId)));

    const folderClassNames = classNames({
      [styles.folder]: true,
      [styles['folder-active']]: isActive,
      [styles['folder-disabled']]: isDisabled,
      [styles['folder-selectable']]: folderSelectable,
      [styles['folder-selected']]: id === defaultOpenedFolderId
    });
    const folderMenuContainerClassNames = classNames({
      [styles['folder-menu-container']]: true,
      [styles['folder-menu-container-active']]: id === activeMenuFolderId
    });

    const handleClick = this._handleFolderClick(id, isDisabled);

    const folderStyle = {paddingLeft: `${(NESTING_PADDING_NODE * nestingLevel) + NESTING_PADDING_OFFSET}px`};
    const verticalLineStyle = {left: `${(NESTING_PADDING_NODE * nestingLevel) + NESTING_PADDING_VLINE}px`};

    return (
      <div
        key={`folder-${id}`}
        className={folderClassNames}
        onClick={folderSelectable ? this._handleFolderSelect(id) : handleClick}
      >
        <div className={styles['folder-name-container']} style={folderStyle}>
          <div
            className={styles['folder-triangle-wrapper']}
            onClick={(folderSelectable && !isDisabled) ? handleClick : null}
          >
            <Icon
              type={isOpened ? Icon.TYPE.TRIANGLE_DOWN : Icon.TYPE.TRIANGLE_RIGHT}
              active={isActive}
              userClassName={styles['folder-triangle']}
            />
          </div>
          <Icon type={Icon.TYPE.FOLDER} active={isActive} userClassName={styles['folder-icon']}/>
          {
            isEditing ? (
              <Input
                focusOnMount
                name="name"
                type={Input.TYPE.TEXT}
                value={newFolderName}
                userClassName={styles['folder-name-input']}
                onChange={this._handleFolderNameChange}
                onKeyPress={this._handleFolderKeyPress(id)}
                onBlur={this._handleEditFolderNameCancel}
              />
            ) : (
              <div>
                {name}
              </div>
            )
          }
          {
            editable && !isEditing && (
              <MenuContainer
                position={MenuContainer.POSITION.BOTTOM_RIGHT}
                renderMenu={this._renderFolderMenu(id)}
                renderButton={this._renderFolderMenuButton}
                userClassName={folderMenuContainerClassNames}
                onVisibilityChange={this._handleFolderMenuVisibilityChange(id)}
              />
            )
          }
        </div>
        {
          isOpened && (
            <div className={styles['folder-children-container']}>
              {
                children.map(this._renderNode(nestingLevel + 1, badgesDisabled))
              }
              <div className={styles['folder-vertical-line']} style={verticalLineStyle}/>
            </div>
          )
        }
      </div>
    );
  }

  _renderEntity(entity, nestingLevel, badgesDisabled) {
    const {currentDate, selectedEntityId, alternateLiveEntityColor, checkEntityLiveCallback} = this.props;

    const {id, name} = entity;

    const entityClassNames = classNames({
      [styles.entity]: true,
      [styles['entity-selected']]: selectedEntityId === id
    });
    const entityBadgeClassNames = classNames({
      [styles['entity-badge']]: true,
      [styles['entity-badge-alternate']]: alternateLiveEntityColor,
      [styles['entity-badge-live']]: checkEntityLiveCallback && checkEntityLiveCallback(entity, currentDate)
    });

    const padding = (NESTING_PADDING_NODE * nestingLevel) + NESTING_PADDING_OFFSET + NESTING_PADDING_ENTITY;

    const entityStyle = {paddingLeft: `${padding}px`};

    return (
      <div
        key={`entity-${id}`}
        className={entityClassNames}
        style={entityStyle}
        onClick={this._handleEntityClick(id)}
      >
        {
          !badgesDisabled && <div className={entityBadgeClassNames}/>
        }
        <div>
          {name}
        </div>
      </div>
    );
  }

  _renderFolderMenu = id => setVisible => {
    const {organisations} = this.props;

    const isOrganisation = Boolean((organisations || []).find(org => `organisation-${org.id}` === id));

    return (
      <div className={styles['folder-menu']}>
        <div className={styles['folder-menu-item']} onClick={this._createFolder(id, setVisible)}>Create</div>
        {
          !isOrganisation && (
            <>
              <div className={styles['folder-menu-item']} onClick={this._editFolder(id)}>Edit</div>
              <div
                className={`${styles['folder-menu-item']} ${styles['folder-menu-item-secondary']}`}
                onClick={this._deleteFolder(id, setVisible)}
              >
                Delete
              </div>
            </>
          )
        }
      </div>
    );
  };

  _renderFolderMenuButton = (toggle, setVisible, isMenuVisible) => {
    const {isFolderMenuHovered} = this.state;

    const folderMenuButtonClassNames = classNames({
      [styles['folder-menu-button']]: true,
      [styles['folder-menu-button-active']]: isMenuVisible
    });

    return (
      <div
        className={folderMenuButtonClassNames}
        onClick={this._toggleMenu(toggle)}
        onMouseEnter={this._setFolderMenuHovered(true)}
        onMouseLeave={this._setFolderMenuHovered(false)}
      >
        <Icon
          type={Icon.TYPE.OPTIONS}
          active={isFolderMenuHovered || isMenuVisible}
          userClassName={styles['folder-menu-button-icon']}
        />
      </div>
    );
  };

  _toggleMenu = toggle => e => {
    e.stopPropagation();

    toggle();
  };

  _handleFolderMenuVisibilityChange = id => isVisible => {
    this.setState(({activeMenuFolderId}) => ({
      activeMenuFolderId: isVisible ? id : ((id === activeMenuFolderId) ? null : activeMenuFolderId)
    }));
  };

  _handleFolderClick = (id, isDisabled) => e => {
    e.stopPropagation();

    this.setState(state => {
      const {folderNodesData} = state;
      const nodeData = folderNodesData[id] || {};

      return {
        ...state,
        folderNodesData: {
          ...folderNodesData,
          [id]: {
            ...nodeData,
            isOpened: !isDisabled && !nodeData.isOpened
          }
        }
      };
    });
  };

  _handleFolderSelect = id => e => {
    const {onFolderSelect} = this.props;

    e.stopPropagation();

    if (onFolderSelect) {
      onFolderSelect(id);
    }
  };

  _handleEntityClick = id => e => {
    const {onEntitySelect} = this.props;

    e.stopPropagation();

    if (onEntitySelect) {
      onEntitySelect(id);
    }
  };

  _setFolderMenuHovered = isHovered => () => {
    this.setState({isFolderMenuHovered: isHovered});
  };

  _createFolder = (id, setVisible) => e => {
    const {folders, organisations, onFolderCreateRequested} = this.props;

    e.stopPropagation();

    setVisible(false);

    if (!onFolderCreateRequested) {
      return;
    }

    if (id.startsWith('organisation-')) {
      const organisation = organisations.find(org => `organisation-${org.id}` === id);

      onFolderCreateRequested(null, organisation.id);

      return;
    }

    const folder = folders.find(fld => fld.id === id);

    onFolderCreateRequested(id, folder.organisationId);
  };

  _editFolder = id => e => {
    const {folders} = this.props;

    if (e) {
      e.stopPropagation();
    }

    const newFolderName = folders.find(folder => folder.id === id).name;

    this.setState({
      newFolderName,
      editedFolderId: id
    });
  };

  _deleteFolder = (id, setVisible) => e => {
    const {folders, entities, onFolderDeleteRequested} = this.props;

    e.stopPropagation();

    setVisible(false);

    const hasChildren = Boolean(folders.find(folder => folder.parentId === id) ||
      entities.find(entity => entity.folderId === id));

    if (hasChildren) {
      // eslint-disable-next-line no-alert
      alert('The folder is not empty.');

      return;
    }

    if (onFolderDeleteRequested) {
      onFolderDeleteRequested(id);
    }
  };

  _handleFolderNameChange = newFolderName => {
    this.setState({newFolderName});
  };

  _handleEditFolderNameCancel = () => {
    this.setState({
      editedFolderId: null,
      newFolderName: null
    });
  };

  _handleFolderKeyPress = id => e => {
    const {folders, onFolderEditRequested} = this.props;
    const {newFolderName} = this.state;

    if (e.keyCode === 27) { // Escape
      this._handleEditFolderNameCancel();

      return;
    }

    if (e.key !== 'Enter') {
      return;
    }

    this.setState({
      editedFolderId: null,
      newFolderName: null
    }, () => {
      const folder = folders.find(folder => folder.id === id);

      if (newFolderName && (folder.name !== newFolderName) && onFolderEditRequested) {
        onFolderEditRequested(id, {name: newFolderName});
      }
    });
  };

  _selectEntity = id => {
    const {folders, entities, organisations} = this.props;

    const entity = entities.find(entity => entity.id === id);

    if (!entity.folderId) {
      if (organisations) {
        const folderNodesData = {
          [`organisation-${entity.organisationId}`]: {isOpened: true}
        };

        this.setState(state => ({
          ...state,
          folderNodesData: {
            ...state.folderNodesData,
            ...folderNodesData
          }
        }));
      }

      return;
    }

    const folderNodesData = {
      [entity.folderId]: {isOpened: true}
    };

    let folder = folders.find(folder => folder.id === entity.folderId);

    while (folder.parentId) {
      folderNodesData[folder.parentId] = {isOpened: true};

      folder = folders.find(folder => folder.id === folder.folderId);
    }

    if (organisations) {
      folderNodesData[`organisation-${folder.organisationId}`] = {isOpened: true};
    }

    this.setState(state => ({
      ...state,
      folderNodesData: {
        ...state.folderNodesData,
        ...folderNodesData
      }
    }));
  };

  _openFolder = id => {
    const {folders, organisations} = this.props;

    const folderNodesData = {};

    let folder = folders.find(fld => fld.id === id);

    if (!folder) {
      folder = organisations.find(org => `organisation-${org.id}` === id);
    }

    while (folder.parentId) {
      folderNodesData[folder.parentId] = {isOpened: true};

      folder = folders.find(fld => fld.id === folder.parentId);
    }

    if (organisations && folder.organisationId) {
      folderNodesData[`organisation-${folder.organisationId}`] = {isOpened: true};
    }

    this.setState(state => ({
      ...state,
      folderNodesData: {
        ...state.folderNodesData,
        ...folderNodesData
      }
    }));
  };
}

TreeView.ENTITY_TYPE = TreeView.WrappedComponent.ENTITY_TYPE;

TreeView.WrappedComponent.propTypes = {
  currentDate: PropTypes.object.isRequired
};

TreeView.propTypes = {
  entityType: PropTypes.oneOf(Object.values(TreeView.ENTITY_TYPE)).isRequired,
  folders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    organisationId: PropTypes.string.isRequired,
    parentId: PropTypes.string
  })).isRequired,
  entities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    organisationId: PropTypes.string.isRequired,
    folderId: PropTypes.string
  })),
  organisations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  selectedEntityId: PropTypes.string,
  defaultSelectedEntityId: PropTypes.string,
  defaultOpenedFolderId: PropTypes.string,
  folderSelectable: PropTypes.bool,
  editable: PropTypes.bool,
  alternateLiveEntityColor: PropTypes.bool,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  selectEntityCallback: PropTypes.func,
  openFolderCallback: PropTypes.func,
  editFolderCallback: PropTypes.func,
  checkEntityLiveCallback: PropTypes.func,
  onEntitySelect: PropTypes.func,
  onFolderSelect: PropTypes.func,
  onFolderCreateRequested: PropTypes.func,
  onFolderDeleteRequested: PropTypes.func,
  onFolderEditRequested: PropTypes.func,
  badgesDisabled: PropTypes.bool
};

TreeView.defaultProps = {
  entities: [],
  organisations: null,
  selectedEntityId: null,
  defaultSelectedEntityId: null,
  defaultOpenedFolderId: null,
  folderSelectable: false,
  editable: false,
  alternateLiveEntityColor: false,
  userClassName: null,
  userStyle: null,
  selectEntityCallback: null,
  openFolderCallback: null,
  editFolderCallback: null,
  checkEntityLiveCallback: null,
  onEntitySelect: null,
  onFolderSelect: null,
  onFolderCreateRequested: null,
  onFolderDeleteRequested: null,
  onFolderEditRequested: null,
  badgesDisabled: false
};

export default TreeView;
