import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.less';

class RadioGroup extends React.Component {
  render() {
    const {options, userClassName, userStyle} = this.props;

    const radioGroupClassNames = classNames(styles['radio-group'], userClassName);

    return (
      <div className={radioGroupClassNames} style={userStyle}>
        {
          options.map(this._renderOption)
        }
      </div>
    );
  }

  _renderOption = ({value, label}) => {
    const {name, disabled, onFocus, onBlur} = this.props;

    const isSelected = value === this.props.value;

    const optionClassNames = classNames(styles.option, {
      [styles['option-disabled']]: disabled,
      [styles['option-selected']]: isSelected
    });

    return (
      <div key={value} className={optionClassNames} onClick={disabled ? null : this._selectValue(value)}>
        <input
          readOnly
          name={name}
          type="radio"
          value={value}
          checked={isSelected}
          className={styles['option-input']}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <div className={styles['option-control']}/>
        <div className={styles['option-label']}>{label || value}</div>
      </div>
    );
  };

  _selectValue = value => () => {
    const {onChange} = this.props;

    if (onChange) {
      onChange(value);
    }
  };
}

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string
  })).isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

RadioGroup.defaultProps = {
  name: null,
  disabled: false,
  userClassName: null,
  userStyle: null,
  onChange: null,
  onFocus: null,
  onBlur: null
};

export default RadioGroup;
