exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1jj-W_Ka{-js-display:flex;display:flex;align-items:center}.vSvjEJ3x{width:22px;height:22px;cursor:pointer}.vSvjEJ3x+.vSvjEJ3x{margin-left:15px}.vSvjEJ3x ._3ePf4WDt{width:100%;height:100%}.vSvjEJ3x ._3sdUDGC4{opacity:.5}.vSvjEJ3x.VwyMiBHW{width:48px;height:48px;-js-display:flex;display:flex;justify-content:center;align-items:center;border-radius:9px}.vSvjEJ3x.VwyMiBHW ._3ePf4WDt{width:22px;height:22px}.vSvjEJ3x:hover.VwyMiBHW{background-color:rgba(0,0,0,.4)}.vSvjEJ3x:hover ._3sdUDGC4{opacity:1}.vSvjEJ3x.xB28-GmM.VwyMiBHW{background-color:rgba(0,0,0,.4)}.vSvjEJ3x.xB28-GmM ._3sdUDGC4{opacity:1}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,WACE,gBAAa,CAAb,YAAa,CACb,kBACF,CACA,UACE,UAAW,CACX,WAAY,CACZ,cACF,CACA,oBACE,gBACF,CACA,qBACE,UAAW,CACX,WACF,CACA,qBACE,UACF,CACA,mBACE,UAAW,CACX,WAAY,CACZ,gBAAa,CAAb,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,iBACF,CACA,8BACE,UAAW,CACX,WACF,CACA,yBACE,+BACF,CACA,2BACE,SACF,CACA,4BACE,+BACF,CACA,8BACE,SACF","file":"styles.less","sourcesContent":[".icon-checkbox-group {\n  display: flex;\n  align-items: center;\n}\n.item {\n  width: 22px;\n  height: 22px;\n  cursor: pointer;\n}\n.item + .item {\n  margin-left: 15px;\n}\n.item .icon-wrapper {\n  width: 100%;\n  height: 100%;\n}\n.item .icon {\n  opacity: 0.5;\n}\n.item.item-with-background {\n  width: 48px;\n  height: 48px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 9px;\n}\n.item.item-with-background .icon-wrapper {\n  width: 22px;\n  height: 22px;\n}\n.item:hover.item-with-background {\n  background-color: rgba(0, 0, 0, 0.4);\n}\n.item:hover .icon {\n  opacity: 1;\n}\n.item.item-selected.item-with-background {\n  background-color: rgba(0, 0, 0, 0.4);\n}\n.item.item-selected .icon {\n  opacity: 1;\n}\n"]}]);
// Exports
exports.locals = {
	"icon-checkbox-group": "_1jj-W_Ka",
	"item": "vSvjEJ3x",
	"icon-wrapper": "_3ePf4WDt",
	"icon": "_3sdUDGC4",
	"item-with-background": "VwyMiBHW",
	"item-selected": "xB28-GmM"
};