import {defineAction} from 'redux-define';

const folders = defineAction('folders');

export const ADD_SINGLE = defineAction('ADD_SINGLE', folders);
export const ADD_LIST = defineAction('ADD_LIST', folders);
export const CLEAR = defineAction('CLEAR', folders);
export const DELETE_SINGLE = defineAction('DELETE_SINGLE', folders);
export const SET_FETCHED = defineAction('SET_FETCHED', folders);
export const UPDATE_SINGLE = defineAction('UPDATE_SINGLE', folders);
