import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import Icon from '../icon';
import MenuContainer from '../menu-container';
import TreeView from '../tree-view';

import styles from './styles.less';

function _getPath(folders, entities, organisations, value) {
  if (!value) {
    return null;
  }

  const entity = entities.find(ent => ent.id === value);

  const path = [entity.name];

  let folder = folders
    .find(fld => fld.id === entity.folderId);

  if (!folder) {
    folder = organisations.find(org => org.id === entity.organisationId);
  }

  path.unshift(folder.name);

  while (folder.parentId) {
    folder = folders.find(fld => fld.id === folder.parentId);

    path.unshift(folder.name);
  }

  if (organisations) {
    const organisation = organisations.find(org => org.id === folder.organisationId);

    if (organisation) {
      path.unshift(organisation.name);
    }
  }

  return path;
}

function _getDefaultSelectedEntityId(value) {
  if (!value || (value.length < 1)) {
    return null;
  }

  return value[value.length - 1];
}

class EntitySelect extends React.Component {
  render() {
    const {multi, userClassName, userStyle} = this.props;

    const wrapperClassNames = classNames(styles['select-wrapper'], userClassName);

    return (
      <MenuContainer
        renderButton={multi ? this._renderButtonMulti : this._renderButton}
        renderMenu={this._renderMenu}
        userClassName={wrapperClassNames}
        userStyle={userStyle}
        menuWrapperUserClassName={styles['menu-wrapper']}
      />
    );
  }

  _renderButton = (toggle, setVisible, isMenuVisible) => {
    const {folders, entities, organisations, value, placeholder} = this.props;

    const path = _getPath(folders, entities, organisations, value);

    const buttonClassNames = classNames({
      [styles.button]: true,
      [styles['button-active']]: isMenuVisible
    });

    return (
      <div className={buttonClassNames} onClick={toggle}>
        {
          path ? this._renderPath(path) : placeholder
        }
      </div>
    );
  };

  _renderButtonMulti = (toggle, setVisible, isMenuVisible) => {
    const {value, placeholder} = this.props;

    const buttonClassNames = classNames({
      [styles.button]: true,
      [styles['button-multi']]: true,
      [styles['button-active']]: isMenuVisible
    });

    return (
      <div className={buttonClassNames} onClick={toggle}>
        {
          (value && (value.length > 0)) ? value.map(this._renderButtonItem(setVisible)) : placeholder
        }
      </div>
    );
  };

  _renderButtonItem = setVisible => id => {
    const {entities} = this.props;

    const entity = entities.find(ent => ent.id === id);

    return (
      <div key={id} className={styles['button-item-wrapper']}>
        <div key={id} className={styles['button-item']}>
          <div>{entity.name}</div>
          <div className={styles['button-item-icon-wrapper']} onClick={this._removeItem(id, setVisible)}>
            <Icon type={Icon.TYPE.CROSS} userClassName={styles['button-item-icon']}/>
          </div>
        </div>
      </div>
    );
  };

  _renderMenu = setVisible => {
    const {entityType, multi, folders, entities, organisations, value} = this.props;

    return (
      <TreeView
        entityType={entityType}
        folders={folders}
        entities={entities}
        organisations={organisations}
        defaultSelectedEntityId={multi ? _getDefaultSelectedEntityId(value) : value}
        selectedEntityId={multi ? _getDefaultSelectedEntityId(value) : value}
        userClassName={styles.menu}
        onEntitySelect={this._handleEntitySelect(setVisible)}
      />
    );
  };

  _renderPath(path) {
    return (
      <>
        {
          path
            .reduce((acc, part) => acc.concat(part, 'separator'), [])
            .slice(0, -1)
            .map(this._renderPathPart)
        }
      </>
    );
  }

  _renderPathPart = (part, index) => {
    if (part === 'separator') {
      return (
        <Icon key={index} type={Icon.TYPE.CHEVRON_RIGHT} userClassName={styles['path-part-separator']}/>
      );
    }

    return (
      <div key={index} className={styles['path-part']}>{part}</div>
    );
  };

  _handleEntitySelect = setVisible => entityId => {
    const {multi, value, onChange} = this.props;

    setVisible(false, () => {
      if (onChange) {
        if (multi) {
          const newValue = [...value] || [];

          if (!newValue.includes(entityId)) {
            newValue.push(entityId);
          }

          onChange(newValue);
        } else {
          onChange(entityId);
        }
      }
    });
  };

  _removeItem = (entityId, setVisible) => () => {
    const {onChange, value} = this.props;

    const newValue = [...value].filter(id => id !== entityId);

    setVisible(false, () => {
      if (onChange) {
        onChange(newValue);
      }
    });
  };
}

EntitySelect.propTypes = {
  entityType: PropTypes.oneOf(Object.values(TreeView.ENTITY_TYPE)).isRequired,
  folders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    organisationId: PropTypes.string.isRequired,
    parentId: PropTypes.string
  })).isRequired,
  entities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    organisationId: PropTypes.string.isRequired,
    folderId: PropTypes.string
  })).isRequired,
  organisations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  multi: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  placeholder: PropTypes.string,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onChange: PropTypes.func
};

EntitySelect.defaultProps = {
  organisations: null,
  multi: false,
  value: null,
  placeholder: null,
  userClassName: null,
  userStyle: null,
  onChange: null
};

export default EntitySelect;
