import React from 'react';
import * as PropTypes from 'prop-types';

import ClickableText from '../clickable-text';
import Form from '../form';

import styles from './styles.less';

class AuthPage extends React.Component {
  render() {
    const {formProps, header, footer} = this.props;

    return (
      <div className={styles['auth-page']}>
        <div className={styles['auth-panel']}>
          <div className={styles.header}/>
          {
            header && (
              <div className={styles.subheader}>
                {header}
              </div>
            )
          }
          {
            formProps && (
              <div className={styles.body}>
                <Form {...formProps}/>
              </div>
            )
          }
          <div className={styles.footer}>
            {
              footer && this._renderFooterContent()
            }
          </div>
        </div>
      </div>
    );
  }

  _renderFooterContent() {
    const {footer} = this.props;

    return (
      <div>
        {`${footer.text} `}
        <ClickableText text={footer.action.text} href={footer.action.href}/>
      </div>
    );
  }
}

AuthPage.propTypes = {
  formProps: PropTypes.object,
  header: PropTypes.node,
  footer: PropTypes.shape({
    text: PropTypes.string.isRequired,
    action: PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    }).isRequired
  })
};

AuthPage.defaultProps = {
  formProps: null,
  header: null,
  footer: null
};

export default AuthPage;
