import {createReducer, getSourceAction} from '@ololoepepe/redux-tools';

import IUser from '../../models/user';

import {API, USER} from '../action-types';

interface IState {
  user: IUser | null;
  isUpdating: boolean;
  error: any;
}

const INITIAL_STATE: IState = {
  user: null,
  isUpdating: false,
  error: null
};

function loginSuccess(state: IState, {payload}): IState {
  const user = {...payload.response};

  return {
    ...state,
    user
  };
}

function registerSuccess(state: IState, {payload}): IState {
  const {user} = payload.response.data;

  return {
    ...state,
    user
  };
}

function updatePending(state: IState): IState {
  return {
    ...state,
    isUpdating: true
  };
}

function updateSuccess(state: IState, {payload}): IState {
  const data = getSourceAction(payload).data;

  return {
    ...state,
    error: null,
    isUpdating: false,
    user: {
      ...state.user,
      ...data
    }
  };
}

function setSubscriptionSuccess(state: IState, {payload}): IState {
  const data = payload.response.item;

  return {
    ...state,
    user: {
      ...state.user,
      ...data,
      isStripeSubscriptionActive: Boolean(data.subscriptionType)
    }
  };
}

function updateError(state: IState, {payload}): IState {
  return {
    ...state,
    error: payload.error,
    isUpdating: false
  };
}

function getSuccess(state: IState, {payload}): IState {
  return {
    ...state,
    user: {
      ...payload.response.item
    }
  };
}

export default createReducer(INITIAL_STATE, {
  [API.AUTH.LOGIN.SUCCESS]: loginSuccess,
  [API.AUTH.REGISTER.SUCCESS]: registerSuccess,
  [API.USER.UPDATE_SELF.PENDING]: updatePending,
  [API.USER.UPDATE_SELF.SUCCESS]: updateSuccess,
  [API.USER.UPDATE_SELF.ERROR]: updateError,
  [API.USER.GET_SELF.SUCCESS]: getSuccess,
  [API.USER.SET_SUBSCRIPTION.SUCCESS]: setSubscriptionSuccess,
  [USER.CLEAR.ACTION]: () => ({...INITIAL_STATE})
});
