import moment from 'moment';
import uuid from 'uuid/v4';

import {
  USER_ROLE_OPTIONS,
  RESOLUTION_OPTIONS,
  TIME_SLOT_REPEAT_OPTIONS,
  getTimeSlotTimeOptions
} from './select-options';
import {
  validateCampaignName,
  validateEmail,
  validatePassword,
  validateScheduleName,
  validateUserName,
  validateUserRole,
  validateFolderName,
  validateScreenName,
  validateResolution,
  validateScheduleScreenIds,
  validateTimeSlotCampaignId,
  validateTimeSlotRepeat
} from './validators';

function _parseTimeSlotTime(value) {
  const date = moment(value, ['h:mm A']).toDate();

  const msecs = (date.getHours() * 60 * 60 * 1000) + (date.getMinutes() * 60 * 1000);

  return `${msecs}`;
}

function _formatTimeSlotTime(value) {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(Number(value));

  return date.toLocaleString('en', {
    hour: 'numeric',
    hour12: true,
    minute: '2-digit'
  }).replace(' ', '').toLowerCase();
}

export const LOGIN_FORM_FIELDS = [{
  name: 'email',
  type: 'email',
  label: 'Email',
  validate: validateEmail
}, {
  name: 'password',
  type: 'password',
  label: 'Password',
  validate: validatePassword
}];

export const REGISTRATION_FORM_FIELDS = [{
  name: 'name',
  type: 'text',
  label: 'Your name',
  validate: validateUserName
}, {
  name: 'email',
  type: 'email',
  label: 'Email',
  disabled: true,
  validate: validateEmail
}, {
  name: 'password',
  type: 'password',
  label: 'Password',
  description: 'At least 8 characters long.',
  validate: validatePassword
}, {
  name: 'password2',
  type: 'password',
  label: 'Confirm password',
  description: 'Passwords must match.'
}];

export const CHANGE_PASSWORD_FORM_FIELDS = [{
  name: 'oldPassword',
  type: 'password',
  label: 'Verify Current Password',
  validate: validatePassword
}, {
  name: 'password',
  type: 'password',
  label: 'New Password',
  validate: validatePassword
}, {
  name: 'password2',
  type: 'password',
  label: 'Confirm New Password'
}];

export const CREATE_INVITE_FORM_FIELDS = [{
  name: 'email',
  type: 'email',
  label: 'Email',
  validate: validateEmail
}, {
  name: 'role',
  type: 'radio',
  label: 'Role',
  options: USER_ROLE_OPTIONS,
  validate: validateUserRole
}];

export function getCreateCampaignFormFields({folders, organisations}) {
  return [{
    name: 'name',
    type: 'text',
    label: 'Campaign name',
    validate: validateCampaignName
  }, {
    name: 'resolution',
    type: 'select',
    label: 'Resolution',
    options: RESOLUTION_OPTIONS,
    validate: validateResolution
  }, {
    folders,
    organisations,
    name: 'folderId',
    type: 'folder_select',
    label: 'Folder',
    entityType: 'campaign'
  }, {
    name: 'description',
    type: 'text',
    label: 'Description',
    optional: true,
    multiline: true,
    rows: 5,
    rowsMax: 5
  }];
}

export function getEditCampaignFormFields({folders, organisations}) {
  return [{
    name: 'name',
    type: 'text',
    label: 'Campaign name...',
    validate: validateCampaignName
  }, {
    folders,
    organisations,
    name: 'folderId',
    type: 'folder_select',
    label: 'Folder',
    entityType: 'campaign'
  }, {
    name: 'description',
    type: 'text',
    label: 'Description...',
    multiline: true,
    rows: 5,
    rowsMax: 5
  }];
}

export function getCreateScreenFormFields({folders, campaigns, organisations}) {
  return [{
    name: 'id',
    type: 'text',
    label: 'Screen ID'
  }, {
    name: 'name',
    type: 'text',
    label: 'Screen name',
    validate: validateScreenName
  }, {
    name: 'resolution',
    type: 'select',
    label: 'Resolution',
    options: RESOLUTION_OPTIONS,
    validate: validateResolution
  }, {
    folders,
    organisations,
    entityType: 'campaign',
    entities: campaigns,
    name: 'defaultCampaignId',
    type: 'entity_select',
    label: 'Default campaign'
  }, {
    folders,
    organisations,
    name: 'folderId',
    type: 'folder_select',
    label: 'Folder',
    entityType: 'screen'
  }, {
    name: 'description',
    type: 'text',
    label: 'Description',
    optional: true,
    multiline: true,
    rows: 5,
    rowsMax: 5
  }];
}

export const RESTORE_SCREEN_FORM_FIELDS = [{
  name: 'restoredScreenId',
  type: 'text',
  label: 'Restored Screen ID'
}];

export function getEditScreenFormFields({folders, campaigns, organisations}) {
  return [{
    name: 'name',
    type: 'text',
    label: 'Screen name...',
    validate: validateScreenName
  }, {
    folders,
    organisations,
    entityType: 'campaign',
    entities: campaigns,
    name: 'defaultCampaignId',
    type: 'entity_select',
    label: 'Default campaign'
  }, {
    folders,
    organisations,
    name: 'folderId',
    type: 'folder_select',
    label: 'Folder',
    entityType: 'screen'
  }, {
    name: 'description',
    type: 'text',
    label: 'Description...',
    multiline: true,
    rows: 5,
    rowsMax: 5
  }];
}

export function getCreateScheduleFormFields({folders, screens, organisations}) {
  return [{
    name: 'name',
    type: 'text',
    label: 'Schedule name...',
    validate: validateScheduleName
  }, {
    folders,
    organisations,
    name: 'folderId',
    type: 'folder_select',
    label: 'Folder',
    entityType: 'schedule'
  }, {
    folders,
    organisations,
    multi: true,
    entityType: 'screen',
    entities: screens,
    name: 'screenIds',
    type: 'entity_select',
    label: 'Screens',
    validate: validateScheduleScreenIds()
  }, {
    name: 'description',
    type: 'text',
    label: 'Description...',
    multiline: true,
    rows: 5,
    rowsMax: 5
  }];
}

export function getEditScheduleFormFields({folders, organisations, screens, schedules, defaultScreenIds}) {
  return [{
    name: 'name',
    type: 'text',
    label: 'Schedule name...',
    validate: validateScheduleName
  }, {
    folders,
    organisations,
    name: 'folderId',
    type: 'folder_select',
    label: 'Folder',
    entityType: 'schedule'
  }, {
    folders,
    organisations,
    multi: true,
    entityType: 'screen',
    entities: screens,
    name: 'screenIds',
    type: 'entity_select',
    label: 'Screens',
    validate: validateScheduleScreenIds(defaultScreenIds, schedules)
  }, {
    name: 'description',
    type: 'text',
    label: 'Description...',
    multiline: true,
    rows: 5,
    rowsMax: 5
  }];
}

export function getAddTimeSlotFormFields({folders, organisations, campaigns, firstHour, lastHour, freeTimeRanges}) {
  return [{
    folders,
    organisations,
    entityType: 'campaign',
    entities: campaigns,
    name: 'campaignId',
    type: 'entity_select',
    label: 'Campaign',
    validate: validateTimeSlotCampaignId
  }, {
    name: 'time',
    type: 'group',
    children: [{
      name: 'date',
      type: 'date',
      label: 'Date',
      placeholder: 'DD/MM/YY'
    }, {
      name: 'timeStart',
      type: 'select',
      options: getTimeSlotTimeOptions({firstHour, lastHour, freeTimeRanges}),
      label: 'Start Time',
      userEditable: true,
      format: _formatTimeSlotTime,
      parse: _parseTimeSlotTime
    }, {
      name: 'timeEnd',
      type: 'select',
      options: getTimeSlotTimeOptions({firstHour, lastHour, freeTimeRanges, isEnd: true}),
      label: 'End Time',
      userEditable: true,
      format: _formatTimeSlotTime,
      parse: _parseTimeSlotTime
    }]
  }, {
    name: 'repeat',
    type: 'radio',
    options: TIME_SLOT_REPEAT_OPTIONS,
    validate: validateTimeSlotRepeat
  }];
}

export function getEditTimeSlotFormFields({folders, organisations, campaigns, firstHour, lastHour, freeTimeRanges}) {
  return [{
    folders,
    organisations,
    entityType: 'campaign',
    entities: campaigns,
    name: 'campaignId',
    type: 'entity_select',
    label: 'Campaign',
    validate: validateTimeSlotCampaignId
  }, {
    name: 'time',
    type: 'group',
    children: [{
      name: 'date',
      type: 'date',
      label: 'Date',
      placeholder: 'DD/MM/YY'
    }, {
      name: 'timeStart',
      type: 'select',
      options: getTimeSlotTimeOptions({firstHour, lastHour, freeTimeRanges}),
      label: 'Start Time',
      userEditable: true,
      format: _formatTimeSlotTime,
      parse: _parseTimeSlotTime
    }, {
      name: 'timeEnd',
      type: 'select',
      options: getTimeSlotTimeOptions({firstHour, lastHour, freeTimeRanges, isEnd: true}),
      label: 'End Time',
      userEditable: true,
      format: _formatTimeSlotTime,
      parse: _parseTimeSlotTime
    }]
  }, {
    name: 'repeat',
    type: 'radio',
    options: TIME_SLOT_REPEAT_OPTIONS,
    validate: validateTimeSlotRepeat
  }];
}

export const PASSWORD_RESETTING_FORM_FIELDS = [{
  name: 'email',
  type: 'email',
  label: 'Email',
  validate: validateEmail
}];

export function getUpdateUserSelfFormFields({uid}, renderTeamField) {
  return [{
    name: 'photoUrl',
    type: 'upload',
    createPath: name => `users/${uid}/photos/${uuid()}-${name}`,
    label: 'Photo'
  }, {
    name: 'name',
    type: 'text',
    label: 'Name',
    validate: validateUserName
  }, {
    name: 'title',
    type: 'text',
    label: 'Role',
    optional: true
  }, {
    name: 'team',
    type: 'custom',
    label: 'Team',
    render: renderTeamField
  }, {
    name: 'email',
    type: 'email',
    label: 'Email',
    disabled: true
  }];
}

export function getCreateFolderFormFields({entityType, folders, organisations}) {
  return [{
    name: 'name',
    type: 'text',
    label: 'Folder name',
    validate: validateFolderName
  }, {
    entityType,
    folders,
    organisations,
    name: 'parentId',
    type: 'folder_select',
    label: 'Parent folder'
  }];
}
