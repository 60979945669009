import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import Icon from '../icon';
import Tooltip from '../tooltip';

import styles from './styles.less';
import {TooltipColor, TooltipPosition} from '../tooltip/component';

class IconCheckboxGroup extends React.Component {
  render() {
    const {items} = this.props;

    return (
      <div className={styles['icon-checkbox-group']}>
        {
          items.map(this._renderItem)
        }
      </div>
    );
  }

  _renderItem = ({value, icon, tooltip}) => {
    const {selected, multiSelect, withBackground} = this.props;

    const isSelected = multiSelect ? selected.includes(value) : (value === selected);

    const itemClassNames = classNames({
      [styles.item]: true,
      [styles['item-with-background']]: withBackground,
      [styles['item-selected']]: isSelected
    });

    const content = (
      <div className={styles['icon-wrapper']}>
        <Icon type={icon} active={isSelected} userClassName={styles.icon}/>
      </div>
    );

    if (tooltip) {
      return (
        <Tooltip
          key={value}
          color={TooltipColor.Light}
          position={TooltipPosition.Bottom}
          tooltip={tooltip}
          userClassName={itemClassNames}
          onClick={this._selectItem(value)}
        >
          {content}
        </Tooltip>
      );
    }

    return (
      <div key={value} className={itemClassNames} onClick={this._selectItem(value)}>
        {content}
      </div>
    );
  };

  _selectItem = value => () => {
    const {selected, multiSelect, onChange} = this.props;

    if (!onChange) {
      return;
    }

    if (!multiSelect) {
      if (selected === value) {
        return;
      }

      onChange(value);

      return;
    }

    const newSelected = new Set(selected);

    if (newSelected.has(value)) {
      newSelected.delete(value);
    } else {
      newSelected.add(value);
    }

    onChange(Array.from(newSelected));
  };
}

IconCheckboxGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(Object.values(Icon.TYPE)).isRequired,
    tooltip: PropTypes.string
  })).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  multiSelect: PropTypes.bool,
  withBackground: PropTypes.bool,
  onChange: PropTypes.func
};

IconCheckboxGroup.defaultProps = {
  multiSelect: false,
  withBackground: false,
  onChange: null
};

export default IconCheckboxGroup;
