import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import {findElement} from '../../../lib/helper';

import styles from './styles.less';

class MenuContainer extends React.Component {
  static get POSITION() {
    return {
      TOP_LEFT: 'top_left',
      TOP_RIGHT: 'top_right',
      BOTTOM_LEFT: 'bottom_left',
      BOTTOM_RIGHT: 'bottom_right'
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      isMenuVisible: false
    };
  }

  componentDidMount() {
    window.document.addEventListener('click', this._handleClickOutside);
  }

  componentWillUnmount() {
    window.document.removeEventListener('click', this._handleClickOutside);
  }

  render() {
    const {renderButton, renderMenu, position, userClassName, userStyle, menuWrapperUserClassName} = this.props;
    const {isMenuVisible} = this.state;

    const menuContainerClassNames = classNames({
      [styles['menu-container']]: true,
      [styles['menu-container-top-left']]: position === MenuContainer.POSITION.TOP_LEFT,
      [styles['menu-container-top-right']]: position === MenuContainer.POSITION.TOP_RIGHT,
      [styles['menu-container-bottom-left']]: position === MenuContainer.POSITION.BOTTOM_LEFT,
      [styles['menu-container-bottom-right']]: position === MenuContainer.POSITION.BOTTOM_RIGHT,
      [userClassName]: Boolean(userClassName)
    });

    const menuWrapperClassNames = classNames({
      [styles['menu-wrapper']]: true,
      [menuWrapperUserClassName]: Boolean(menuWrapperUserClassName)
    });

    return (
      <div ref={this._setWrapperRef} className={menuContainerClassNames} style={userStyle}>
        {
          renderButton(this._toggleMenu, this._setVisible, isMenuVisible)
        }
        {
          isMenuVisible && (
            <div className={menuWrapperClassNames}>
              {
                renderMenu(this._setVisible, isMenuVisible)
              }
            </div>
          )
        }
      </div>
    );
  }

  _toggleMenu = callback => {
    const {onVisibilityChange} = this.props;

    this.setState(state => ({isMenuVisible: !state.isMenuVisible}), () => {
      if (onVisibilityChange) {
        onVisibilityChange(this.state.isMenuVisible);
      }

      if (typeof callback === 'function') {
        callback();
      }
    });
  };

  _setVisible = (visible, callback) => {
    const {onVisibilityChange} = this.props;

    this.setState({isMenuVisible: Boolean(visible)}, () => {
      if (onVisibilityChange) {
        onVisibilityChange(this.state.isMenuVisible);
      }

      if (typeof callback === 'function') {
        callback();
      }
    });
  };

  _handleClickOutside = e => {
    const {onVisibilityChange} = this.props;

    if (findElement(e.target, this._wrapperRef)) {
      return;
    }

    this.setState({isMenuVisible: false}, () => {
      if (onVisibilityChange) {
        onVisibilityChange(this.state.isMenuVisible);
      }
    });
  };

  _setWrapperRef = el => {
    this._wrapperRef = el;
  };
}

MenuContainer.propTypes = {
  renderButton: PropTypes.func.isRequired,
  renderMenu: PropTypes.func.isRequired,
  position: PropTypes.oneOf(Object.values(MenuContainer.POSITION)),
  onVisibilityChange: PropTypes.func,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  menuWrapperUserClassName: PropTypes.string
};

MenuContainer.defaultProps = {
  position: MenuContainer.POSITION.BOTTOM_LEFT,
  onVisibilityChange: null,
  userClassName: null,
  userStyle: null,
  menuWrapperUserClassName: null
};

export default MenuContainer;
