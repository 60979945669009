import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.less';

class Textarea extends React.Component {
  render() {
    const {name, value, disabled, placeholder, rows, rowsMax, userClassName, userStyle, onFocus, onBlur} = this.props;

    const textareaClassNames = classNames(styles.textarea, userClassName);

    const textareaStyle = {
      minHeight: `${(rows * 24) + 22.5}px`,
      maxHeight: `${(Math.max(rows, rowsMax) * 24) + 22.5}px`
    };

    Object.assign(textareaStyle, userStyle);

    return (
      <textarea
        name={name}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        rows={rows}
        className={textareaClassNames}
        style={textareaStyle}
        onChange={this._handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  }

  _handleChange = e => {
    const {onChange} = this.props;

    if (onChange) {
      onChange(e.target.value);
    }
  };
}

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

Textarea.defaultProps = {
  placeholder: null,
  disabled: false,
  rows: 4,
  rowsMax: 4,
  userClassName: null,
  userStyle: null,
  onChange: null,
  onFocus: null,
  onBlur: null
};

export default Textarea;
