import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import {Form as ReactForm} from 'react-form';

import Button from '../button';

import FormField from './form-field';

import styles from './styles.less';

class Form extends React.Component {
  render() {
    const {onChange, onSubmit, onSubmitFailure, defaultValues} = this.props;

    return (
      <ReactForm
        validateOnMount
        defaultValues={defaultValues}
        validate={this._validate}
        onChange={onChange}
        onSubmit={onSubmit}
        onSubmitFailure={onSubmitFailure}
      >
        {
          this._renderForm
        }
      </ReactForm>
    );
  }

  _renderForm = formApi => {
    const {errors, submitForm} = formApi;
    const {formApiRef, userClassName, userStyle} = this.props;

    if (formApiRef) {
      formApiRef(formApi);
    }

    const formClassNames = classNames({
      [styles.form]: true,
      [userClassName]: Boolean(userClassName)
    });

    return (
      <form className={formClassNames} style={userStyle} onSubmit={submitForm}>
        <fieldset className={styles['form-fields']}>
          {
            this._renderTitle()
          }
          {
            this._renderError(errors)
          }
          {
            this._renderChildren()
          }
          {
            this._renderFields()
          }
          {
            this._renderActions(Boolean(errors))
          }
        </fieldset>
        <button ref={this._setSubmitCallbackRef} type="submit" style={{display: 'none'}}/>
      </form>
    );
  };

  _renderTitle() {
    const {title} = this.props;

    if (!title) {
      return null;
    }

    return (
      <legend className={styles.legend}>
        {title}
      </legend>
    );
  }

  _renderFields() {
    const {fields} = this.props;

    if (!fields || (fields.length < 1)) {
      return null;
    }

    return (
      <fieldset className={styles.fields}>
        {
          fields.map(this._renderField)
        }
      </fieldset>
    );
  }

  _renderField = field => {
    if (field.type === 'group') {
      return this._renderGroup(field);
    }

    return (
      <div key={`field-${field.name}`} className={styles['field-container']}>
        {
          ((field.type === 'custom') && field.fullCustom) ? field.render() : (
            <FormField {...field} disabled={this.props.disabled || field.disabled}/>
          )
        }
      </div>
    );
  };

  _renderGroup({name, children}) {
    return (
      <div key={`group-${name}`} className={styles.group}>
        {
          children.map(this._renderField)
        }
      </div>
    );
  }

  _renderChildren() {
    const {children} = this.props;

    if (!children) {
      return null;
    }

    return (
      <div className={styles['children-container']}>
        {children}
      </div>
    );
  }

  _renderError(errors = {}) {
    const {error} = this.props;

    if (!error && !errors._error) {
      return null;
    }

    return (
      <div className={styles['error-container']}>
        {error || errors._error}
      </div>
    );
  }

  _renderActions(hasErrors) {
    const {actionText, disabled} = this.props;

    if (!actionText) {
      return null;
    }

    return (
      <div className={styles['button-container']}>
        {
          actionText && (
            <div className={styles['button-wrapper']}>
              <Button
                disabled={disabled || hasErrors}
                size={Button.SIZE.EXTRA_LARGE}
                text={actionText}
                onClick={this._submit}
              />
            </div>
          )
        }
      </div>
    );
  }

  _submit = () => {
    this._submitButton.click();
  };

  _setSubmitCallbackRef = el => {
    const {submitCallbackRef} = this.props;

    this._submitButton = el;

    if (!submitCallbackRef) {
      return;
    }

    submitCallbackRef(() => {
      el.click();
    });
  };

  _validate = values => {
    // Required to reset global form error

    const {validate} = this.props;

    const result = validate ? validate(values) : {};

    return {_error: null, ...result};
  };
}

Form.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  fields: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  validate: PropTypes.func,
  actionText: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitFailure: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  submitCallbackRef: PropTypes.func,
  formApiRef: PropTypes.func,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object
};

Form.defaultProps = {
  title: null,
  children: null,
  fields: null,
  defaultValues: null,
  validate: null,
  actionText: null,
  onChange: null,
  onSubmit: null,
  onSubmitFailure: null,
  disabled: false,
  error: null,
  submitCallbackRef: null,
  formApiRef: null,
  userClassName: null,
  userStyle: null
};

export default Form;
