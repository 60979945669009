import {createReducer} from '@ololoepepe/redux-tools';

import {API} from '../../action-types';

interface IState {
  error: any;
  isRegistering: boolean;
}

const INITIAL_STATE: IState = {
  error: null,
  isRegistering: false
};

function pending(state: IState): IState {
  return {
    ...state,
    isRegistering: true
  };
}

function success(state: IState): IState {
  return {
    ...state,
    error: null,
    isRegistering: false
  };
}

function error(state: IState, {payload}): IState {
  return {
    ...state,
    error: payload.error,
    isRegistering: false
  };
}

export default createReducer(INITIAL_STATE, {
  [API.AUTH.REGISTER.PENDING]: pending,
  [API.AUTH.REGISTER.SUCCESS]: success,
  [API.AUTH.REGISTER.ERROR]: error
});
