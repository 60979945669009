import axios from 'axios';
import * as firebase from 'firebase/app';

import {createApiAction} from '../../../lib/api';
import {stringifyQuery} from '../../../lib/helper';

import IResolution from '../../../models/resolution';

import {API} from '../../action-types';

interface ICreateSingleData {
  organisationId: string;
  name: string;
  id?: string;
  description: string;
  folderId: string | null;
  resolution: IResolution;
}

interface IDeleteSingleData {
  id: string;
}

interface IUpdateSingleData extends Partial<IDeleteSingleData>, ICreateSingleData {}

export const createSingle = createApiAction(
  API.SCREENS.CREATE_SINGLE.ACTION,
  async ({data}: {data: ICreateSingleData}) => {
    const {currentUser} = firebase.auth();

    if (!currentUser) {
      throw new Error('currentUser is null');
    }

    const token = await currentUser.getIdToken(true);

    const query = stringifyQuery({data: JSON.stringify(data)});

    const response = await axios.request({
      method: 'post',
      url: `https://us-central1-voyr-6753d.cloudfunctions.net/express/createScreen?${query}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return {
      item: {
        ...response.data.screen
      }
    };
  }
);

export const deleteSingle = createApiAction(
  API.SCREENS.DELETE_SINGLE.ACTION,
  async ({data: {id}}: {data: IDeleteSingleData}) => {
    const screens = firebase.firestore().collection('screens');

    await screens.doc(id).delete();
  }
);

export const updateSingle = createApiAction(
  API.SCREENS.UPDATE_SINGLE.ACTION,
  async ({data: {id, ...rest}}: {data: IUpdateSingleData}) => {
    const screens = firebase.firestore().collection('screens');

    await screens
      .doc(id)
      .update({...rest});
  }
);
