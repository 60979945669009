import classNames from 'classnames';
import React, {useState} from 'react';
import PropTypes from 'prop-types';

import styles from './styles.less';

export const TooltipColor = {
  Dark: 'dark',
  Light: 'light'
};

export const TooltipPosition = {
  Bottom: 'bottom',
  Top: 'top'
};

const Tooltip = ({children, color, position, tooltip, userClassName, onClick}) => {
  const [isHovered, setHovered] = useState(false);

  const componentClassNames = classNames({
    [styles.component]: true,
    [userClassName]: userClassName
  });

  const tooltipClassNames = classNames({
    [styles.tooltip]: true,
    [styles['tooltip-color-dark']]: color === TooltipColor.Dark,
    [styles['tooltip-color-light']]: color === TooltipColor.Light,
    [styles['tooltip-position-bottom']]: position === TooltipPosition.Bottom,
    [styles['tooltip-position-top']]: position === TooltipPosition.Top
  });

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  return (
    <div
      className={componentClassNames}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {
        isHovered && (
          <div className={tooltipClassNames}>
            {tooltip}
          </div>
        )
      }
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(Object.values(TooltipColor)),
  position: PropTypes.oneOf(Object.values(TooltipPosition)),
  tooltip: PropTypes.string.isRequired,
  userClassName: PropTypes.string,
  onClick: PropTypes.func
};

Tooltip.defaultProps = {
  color: TooltipColor.Dark,
  position: TooltipPosition.Top,
  userClassName: null,
  onClick: null
};

export default Tooltip;
