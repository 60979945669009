import {createReducer, getSourceAction} from '@ololoepepe/redux-tools';

import IUser from '../../models/user';

import {API} from '../action-types';

interface IState {
  error: any;
  isFetching: boolean;
  items: IUser[];
}

const INITIAL_STATE: IState = {
  error: null,
  isFetching: false,
  items: []
};

function pending(state: IState): IState {
  return {
    ...state,
    isFetching: true,
    items: []
  };
}

function success(state: IState, {payload}): IState {
  return {
    ...state,
    error: null,
    isFetching: false,
    items: [...payload.response.items]
  };
}

function error(state: IState, {payload}): IState {
  return {
    ...state,
    error: payload.error,
    isFetching: false
  };
}

function updateSingleSuccess(state: IState, {payload}): IState {
  const {uid, ...rest} = getSourceAction(payload).data;

  const index = state.items.findIndex(item => item.uid === uid);

  if (index < 0) {
    return state;
  }

  const newItems = [...state.items];
  newItems[index] = {
    ...newItems[index],
    ...rest
  };

  return {
    ...state,
    items: newItems
  };
}

function deleteSingleSuccess(state: IState, {payload}): IState {
  const {uid} = getSourceAction(payload).data;

  const index = state.items.findIndex(item => item.uid === uid);

  if (index < 0) {
    return state;
  }

  const newItems = [...state.items];
  newItems.splice(index, 1);

  return {
    ...state,
    items: newItems
  };
}

export default createReducer(INITIAL_STATE, {
  [API.USERS.GET_LIST.PENDING]: pending,
  [API.USERS.GET_LIST.SUCCESS]: success,
  [API.USERS.GET_LIST.ERROR]: error,
  [API.USERS.UPDATE_SINGLE.SUCCESS]: updateSingleSuccess,
  [API.USERS.DELETE_SINGLE.SUCCESS]: deleteSingleSuccess
});
