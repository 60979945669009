import {combineReducers} from 'redux';

import auth from './auth';
import campaigns from './campaigns';
import folders from './folders';
import organisation from './organisation';
import organisations from './organisations';
import schedules from './schedules';
import screens from './screens';
import user from './user';
import users from './users';

export default combineReducers({
  auth,
  campaigns,
  folders,
  organisation,
  organisations,
  schedules,
  screens,
  user,
  users
});
