import React from 'react';

import DocumentTitle from 'react-document-title';
import {Redirect, Router, Route, Switch} from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

import history from '../../lib/history';

import AccountPage from '../pages/account';
import CampaignsPage from '../pages/campaigns';
import CampaignPreviewPage from '../pages/campaign-preview';
import LoginPage from '../pages/login';
import NotFoundPage from '../pages/not-found';
import PasswordResettingPage from '../pages/password-resetting';
import RegistrationPage from '../pages/registration';
import SchedulesPage from '../pages/schedules';
import ScreensPage from '../pages/screens';
import ScreenPreviewPage from '../pages/screen-preview';

import MUI_THEME from './theme';

const muiTheme = createMuiTheme(MUI_THEME);

function _renderRedirectDashboard() {
  return (
    <Redirect to="/screens"/>
  );
}

class App extends React.Component {
  render() {
    return (
      <DocumentTitle title="Voyr">
        <Router history={history}>
          <MuiThemeProvider theme={muiTheme}>
            <CssBaseline/>
            <Switch>
              <Route exact path="/" render={_renderRedirectDashboard}/>
              <Route exact path="/account" component={AccountPage}/>
              <Route exact path="/campaigns/:campaignId/preview" component={CampaignPreviewPage}/>
              <Route exact path="/campaigns" component={CampaignsPage}/>
              <Route exact path="/login" component={LoginPage}/>
              <Route exact path="/reset-password" component={PasswordResettingPage}/>
              <Route exact path="/registration" component={RegistrationPage}/>
              <Route exact path="/schedules" component={SchedulesPage}/>
              <Route exact path="/screens/:screenId/preview" component={ScreenPreviewPage}/>
              <Route exact path="/screens" component={ScreensPage}/>
              <Route component={NotFoundPage}/>
            </Switch>
          </MuiThemeProvider>
        </Router>
      </DocumentTitle>
    );
  }
}

export default App;
