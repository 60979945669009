import * as ReactDOM from 'react-dom';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;

function _gcd(a, b) {
  return b ? _gcd(b, a % b) : a;
}

export function createWindow(url, open = false) {
  const newWindow = window.open(url);

  if (open && newWindow) {
    newWindow.focus();
  }
}

export function capitalizeFirstLetter(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function ellipsize(what, when) {
  return (what.length <= (when + 1)) ? what : `${what.substr(0, when)}…`;
}

export function ellipsizeMiddle(what, when) {
  const middle = Math.floor(when / 2);

  return (what.length <= (when + 1)) ? what : `${what.substr(0, middle)}…${what.substr(-middle)}`;
}

export function findElement(target, element) {
  let el = target;

  while (el) {
    if (el === element) {
      return true;
    }

    el = el.parentNode;
  }

  return false;
}

export function hasOwnProperty(obj, propName) {
  return Object.prototype.hasOwnProperty.call(obj, propName);
}

export function exposeFields(obj, ...fields) {
  return fields.filter(propName => hasOwnProperty(obj, propName)).reduce((acc, propName) => {
    acc[propName] = obj[propName];

    return acc;
  }, {});
}

export function diff(oldObj, newObj) {
  return Object.keys(newObj).reduce((acc, key) => {
    const newValue = newObj[key];

    if (hasOwnProperty(oldObj, key)) {
      const oldValue = oldObj[key];

      const newType = typeof newValue;
      const oldType = typeof oldValue;

      if (newType !== oldType) {
        acc[key] = newValue;
      } else if (newType && (newType === 'object')) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          acc[key] = newValue;
        }
      } else if (newValue !== oldValue) {
        acc[key] = newValue;
      }
    } else {
      acc[key] = newValue;
    }

    return acc;
  }, {});
}

export function getRelativeCoordinates(element, {x, y, pageX, pageY}) {
  const position = {
    x: x || pageX,
    y: y || pageY
  };
  const offset = {
    left: element.offsetLeft - (element.scrollLeft || 0),
    top: element.offsetTop - (element.scrollTop || 0)
  };

  let reference = element.offsetParent;

  while (reference) {
    offset.left += reference.offsetLeft;
    offset.top += reference.offsetTop;

    reference = reference.offsetParent;
  }

  let parent = element.parentNode;

  while (parent) {
    offset.left -= (parent.scrollLeft || 0);
    offset.top -= (parent.scrollTop || 0);

    parent = parent.parentNode;
  }

  return {
    x: position.x - offset.left,
    y: position.y - offset.top
  };
}

export function getTimeString(msecs) {
  const hours = Math.floor(msecs / HOUR);
  msecs %= HOUR;

  const minutes = Math.floor(msecs / MINUTE);
  msecs %= MINUTE;

  const seconds = Math.floor(msecs / SECOND);

  return [hours, minutes, seconds]
    .map(part => part.toString().padStart(2, '0'))
    .join(':');
}

export function getMsecsFromTimeString(s) {
  const [hours, minutes, seconds] = s.split(':');

  return [Number(hours) * HOUR, Number(minutes) * MINUTE, Number(seconds) * SECOND]
    .filter(msecs => !isNaN(msecs))
    .reduce((acc, msecs) => acc + msecs, 0);
}

export function getMsecsFromDate(date) {
  return (date.getHours() * 60 * 60 * 1000) +
    (date.getMinutes() * 60 * 1000) +
    (date.getSeconds() * 1000) +
    date.getMilliseconds();
}

export function datesEqual(d1, d2) {
  return (d1.getFullYear() === d2.getFullYear()) &&
    (d1.getMonth() === d2.getMonth()) &&
    (d1.getDate() === d2.getDate());
}

export function getDateString(date, separator = '/') {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().substring(2);

  return `${day}${separator}${month}${separator}${year}`;
}

export function getScreenActiveCampaignId(screenId, schedules, currentDate) {
  const activeTimeSlot = schedules
    .filter(scd => scd.screenIds.includes(screenId))
    .reduce((acc, scd) => {
      return acc.concat(scd.timeSlots);
    }, [])
    .find(ts => {
      if ((ts.repeat === 'once') && !datesEqual(currentDate, ts.date)) {
        return false;
      }

      if ((ts.repeat === 'weekly') && (currentDate.getDay() !== ts.dayOfWeek)) {
        return false;
      }

      const currentMilliseconds = getMsecsFromDate(currentDate);

      return (currentMilliseconds >= ts.timeStart) && (currentMilliseconds <= ts.timeEnd);
    });

  if (!activeTimeSlot) {
    return null;
  }

  return activeTimeSlot ? activeTimeSlot.campaignId : null;
}

export function isCampaignActive(campaignId, schedules, currentDate) {
  const activeTimeSlot = schedules
    .reduce((acc, scd) => {
      return acc.concat(scd.timeSlots);
    }, [])
    .find(ts => {
      if (ts.campaignId !== campaignId) {
        return false;
      }

      if ((ts.repeat === 'once') && !datesEqual(currentDate, ts.date)) {
        return false;
      }

      if ((ts.repeat === 'weekly') && (currentDate.getDay() !== ts.dayOfWeek)) {
        return false;
      }

      const currentMilliseconds = getMsecsFromDate(currentDate);

      return (currentMilliseconds >= ts.timeStart) && (currentMilliseconds <= ts.timeEnd);
    });

  return Boolean(activeTimeSlot);
}

export function isScheduleActive(scheduleId, schedules, currentDate) {
  const activeTimeSlot = schedules
    .filter(scd => scd.id === scheduleId)
    .reduce((acc, scd) => {
      return acc.concat(scd.timeSlots);
    }, [])
    .find(ts => {
      if ((ts.repeat === 'once') && !datesEqual(currentDate, ts.date)) {
        return false;
      }

      if ((ts.repeat === 'weekly') && (currentDate.getDay() !== ts.dayOfWeek)) {
        return false;
      }

      const currentMilliseconds = getMsecsFromDate(currentDate);

      return (currentMilliseconds >= ts.timeStart) && (currentMilliseconds <= ts.timeEnd);
    });

  return Boolean(activeTimeSlot);
}

export function numberFromString(s) {
  if (s === '') {
    return NaN;
  }

  return Number(s);
}

export function isExternalUrl(href) {
  return /^(https?:)?\/\//.test(href);
}

export function measureElement(element) {
  // eslint-disable-next-line react/no-find-dom-node
  const node = ReactDOM.findDOMNode(element);

  return {
    width: node.offsetWidth,
    height: node.offsetHeight
  };
}

export function reduceFraction(numerator, denominator) {
  const gcd = _gcd(numerator, denominator);

  return [numerator / gcd, denominator / gcd];
}

export function stringifyQuery(query) {
  return Object.keys(query).map(key => `${key}=${encodeURIComponent(query[key])}`).join('&');
}

export function stringifyUrl({protocol, slashes, auth, host, pathname, query, hash}) {
  const newQuery = (typeof query === 'string') ? query : stringifyQuery(query);
  const qs = newQuery ? `?${newQuery}` : '';

  return `${protocol || ''}${slashes ? '//' : ''}${auth || ''}${host || ''}${pathname || ''}${qs}${hash || ''}`;
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function copyTextToClipboard(text) {
  const textarea = document.createElement('textarea');
  textarea.style.position = 'fixed';
  textarea.style.top = '0';
  textarea.style.left = '0';
  textarea.style.opacity = '0';
  textarea.innerText = text;

  const parentElement = document.getElementsByTagName('body')[0];
  parentElement.appendChild(textarea);

  textarea.select();
  document.execCommand('copy');

  parentElement.removeChild(textarea);
}

export function insertBetweenElements(array, element) {
  const newArray = array.slice(0);

  if (newArray.length < 2) {
    return newArray;
  }

  for (let i = newArray.length - 1; i > 0; --i) {
    newArray.splice(i, 0, element);
  }

  return newArray;
}

export function simpleHash(s) {
  let a = 1;
  let c = 0;
  let h;
  let o;

  if (s) {
    a = 0;

    for (h = s.length - 1; h >= 0; h--) {
      o = s.charCodeAt(h);
      a = ((a << 6) & 268435455) + o + (o << 14);
      c = a & 266338304;
      a = (c === 0) ? a : (a ^ (c >> 21));
    }
  }

  return String(a);
}
