import {connect} from 'react-redux';

import {parseHref} from '../../../lib/history';

import createComponent from './component.jsx';

function mapStateToProps(state, ownProps) {
  const _isLoggedIn = Boolean(state.user.user);

  const _redirect = parseHref().query.redirect || '/';

  return {_isLoggedIn, _redirect, ...ownProps};
}

export default WrappedComponent => {
  return connect(
    mapStateToProps
  )(createComponent(WrappedComponent));
};
