import React from 'react';
import * as PropTypes from 'prop-types';

import {getScreenDefaultValues} from '../../../lib/form/default-values';
import {getCreateScreenErrors, getEditScreenErrors, getRestoreScreenErrors} from '../../../lib/form/error-getters';
import {RESTORE_SCREEN_FORM_FIELDS, getCreateScreenFormFields, getEditScreenFormFields} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';
import {createWindow} from '../../../lib/helper';
import history from '../../../lib/history';

import EntityInfo from '../../common/entity-info';
import EntityPlaceholder from '../../common/entity-placeholder';
import FolderView from '../../common/folder-view';
import Form from '../../common/form';
import Page from '../../common/page';
import ScreenPreview from '../../common/screen-preview';
import TreeView from '../../common/tree-view';
import withDialog, {DIALOG_SHAPE} from '../../common/with-dialog';

import styles from './styles.less';

@withDialog({
  propName: 'createScreenDialog',
  submitButtonText: 'Create Screen'
})
@withDialog({
  propName: 'restoreScreenDialog',
  submitButtonText: 'Restore Screen'
})
@withDialog({
  propName: 'updateScreenDialog',
  submitButtonText: 'Update Screen'
})
class ScreensPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedScreenId: null,
      createScreenFormHasErrors: false,
      restoreScreenFormHasErrors: false,
      updateScreenFormHasErrors: false,
      isCreatingScreen: false,
      isUpdatingScreen: false
    };
  }

  render() {
    const {createScreenDialog, restoreScreenDialog, updateScreenDialog, isFetching} = this.props;
    const {selectedScreenId} = this.state;

    return (
      <Page title="Screens" isLoading={isFetching}>
        <div className={styles.content}>
          <FolderView
            badgesDisabled
            alternateLiveEntityColor
            entityType={TreeView.ENTITY_TYPE.SCREEN}
            createEntityText="Create a Screen"
            selectedEntityId={selectedScreenId}
            userClassName={styles.sidebar}
            onEntitySelect={this._handleEntitySelect}
            onEntityCreateRequested={this._handleEntityCreateRequested}
          />
          {
            selectedScreenId ? this._renderScreen() : (
              <EntityPlaceholder text="Select a screen to view"/>
            )
          }
          {
            createScreenDialog.isDialogOpen && this._renderCreateScreenDialog()
          }
          {
            restoreScreenDialog.isDialogOpen && this._renderRestoreScreenDialog()
          }
          {
            updateScreenDialog.isDialogOpen && this._renderUpdateScreenDialog()
          }
        </div>
      </Page>
    );
  }

  _renderScreen() {
    const {restoreScreenDialog, updateScreenDialog, screens, folders, role} = this.props;
    const {selectedScreenId} = this.state;

    const screen = screens.find(item => item.id === selectedScreenId);

    const menuItems = [{
      title: 'Edit Details',
      onClick: updateScreenDialog.openDialog
    }, {
      title: 'Restore screen',
      onClick: restoreScreenDialog.openDialog
    }, 'separator', {
      title: 'Delete Screen',
      secondary: true,
      onClick: this._deleteScreen
    }];

    const badges = [];

    if (screen.folderId) {
      badges.push({
        type: 'folder',
        text: folders.find(fld => fld.id === screen.folderId).name
      });
    }

    return (
      <div className={styles.screen}>
        <div className={styles['screen-info']}>
          <EntityInfo
            disabled={role === 'user'}
            name={screen.name}
            description={screen.description}
            menuItems={menuItems}
            badges={badges}
          />
        </div>
        <div className={styles['screen-preview']}>
          <div className={styles['screen-preview-header']}>Preview</div>
          <div className={styles['screen-preview-body']} onClick={this._openScreenPreview}>
            <ScreenPreview screen={screen} userClassName={styles['screen-preview-wrapper']}/>
          </div>
        </div>
      </div>
    );
  }

  _renderCreateScreenDialog() {
    const {createScreenDialog} = this.props;
    const {isCreatingScreen} = this.state;

    return createScreenDialog.renderDialog({
      renderTitle: () => 'Create Screen',
      renderContent: this._renderCreateScreenDialogContent,
      submitCallback: this._submitScreenForm,
      closeDisabledCallback: () => isCreatingScreen,
      submitDisabledCallback: () => {
        const {isCreatingScreen, createScreenFormHasErrors} = this.state;

        return isCreatingScreen || createScreenFormHasErrors;
      }
    });
  }

  _renderCreateScreenDialogContent = () => {
    const {createScreenDialog, folders, campaigns, organisations} = this.props;
    const {isCreatingScreen} = this.state;

    const {folderId} = createScreenDialog.dialogData;

    const defaultValues = {
      ...getScreenDefaultValues(),
      folderId
    };

    return (
      <Form
        disabled={isCreatingScreen}
        fields={getCreateScreenFormFields({folders, campaigns, organisations})}
        defaultValues={defaultValues}
        userClassName={styles['screen-dialog-form']}
        submitCallbackRef={this._setSubmitCallbackRef}
        onChange={this._handleCreateScreenFormChange}
        onSubmit={this._createScreen}
        onSubmitFailure={handleSubmitFailure(getCreateScreenErrors)}
      />
    );
  };

  _renderRestoreScreenDialog() {
    const {restoreScreenDialog} = this.props;
    const {isUpdatingScreen} = this.state;

    return restoreScreenDialog.renderDialog({
      renderTitle: () => 'Restore Screen',
      renderContent: this._renderRestoreScreenDialogContent,
      submitCallback: this._submitScreenForm,
      closeDisabledCallback: () => isUpdatingScreen,
      submitDisabledCallback: () => {
        const {isUpdatingScreen, restoreScreenFormHasErrors} = this.state;

        return isUpdatingScreen || restoreScreenFormHasErrors;
      }
    });
  }

  _renderRestoreScreenDialogContent = () => {
    const {isUpdatingScreen} = this.state;

    return (
      <Form
        disabled={isUpdatingScreen}
        fields={RESTORE_SCREEN_FORM_FIELDS}
        userClassName={styles['screen-dialog-form']}
        submitCallbackRef={this._setSubmitCallbackRef}
        onChange={this._handleRestoreScreenFormChange}
        onSubmit={this._restoreScreen}
        onSubmitFailure={handleSubmitFailure(getRestoreScreenErrors)}
      />
    );
  };

  _renderUpdateScreenDialog() {
    const {updateScreenDialog} = this.props;
    const {isUpdatingScreen} = this.state;

    return updateScreenDialog.renderDialog({
      renderTitle: () => 'Update Screen',
      renderContent: this._renderUpdateScreenDialogContent,
      submitCallback: this._submitScreenForm,
      closeDisabledCallback: () => isUpdatingScreen,
      submitDisabledCallback: () => {
        const {isUpdatingScreen, updateScreenFormHasErrors} = this.state;

        return isUpdatingScreen || updateScreenFormHasErrors;
      }
    });
  }

  _renderUpdateScreenDialogContent = () => {
    const {screens, folders, campaigns, organisations} = this.props;
    const {selectedScreenId, isUpdatingScreen} = this.state;

    const screen = screens.find(item => item.id === selectedScreenId);

    return (
      <Form
        disabled={isUpdatingScreen}
        fields={getEditScreenFormFields({folders, campaigns, organisations})}
        defaultValues={getScreenDefaultValues(screen)}
        userClassName={styles['screen-dialog-form']}
        submitCallbackRef={this._setSubmitCallbackRef}
        onChange={this._handleUpdateScreenFormChange}
        onSubmit={this._updateScreen}
        onSubmitFailure={handleSubmitFailure(getEditScreenErrors)}
      />
    );
  };

  _handleEntitySelect = id => {
    this.setState({selectedScreenId: id});
  };

  _handleEntityCreateRequested = folderId => {
    const {createScreenDialog, role, subscriptionType, isStripeSubscriptionActive, screens} = this.props;

    if ((role === 'admin') && (subscriptionType <= screens.length)) {
      const text = subscriptionType ?
        'You have reached screen limit for your plan. Upgrade now?' :
        'You can only create screens on a payed plan. Select a plan now?';

      // eslint-disable-next-line no-alert
      const ok = confirm(text);

      if (ok) {
        history.push('/account?tab=subscription');
      }

      return;
    }

    if ((role === 'admin') && !isStripeSubscriptionActive) {
      // eslint-disable-next-line no-alert
      const ok = confirm('Your plan is not activated. Check your plan now?');

      if (ok) {
        history.push('/account?tab=subscription');
      }

      return;
    }

    return new Promise((resolve, reject) => {
      createScreenDialog.openDialog({resolve, reject, folderId});
    });
  };

  _openScreenPreview = () => {
    const {selectedScreenId} = this.state;

    createWindow(`/screens/${selectedScreenId}/preview`);
  };

  _deleteScreen = () => {
    const {schedules, deleteScreen} = this.props;
    const {selectedScreenId} = this.state;

    const isUsed = schedules.some(scd => scd.screenIds.includes(selectedScreenId));

    if (isUsed) {
      // eslint-disable-next-line no-alert
      alert('This screen is used in schedule(s) and can\'t be deleted');

      return;
    }

    // eslint-disable-next-line no-alert
    if (!confirm('Are you sure?')) {
      return;
    }

    this.setState({selectedScreenId: null}, async () => {
      try {
        await deleteScreen(selectedScreenId);
      } catch (err) {
        console.error(err);

        this.setState({selectedScreenId});
      }
    });
  };

  _createScreen = ({id, name, description, resolution, defaultCampaignId, folderId}) => {
    const {createScreenDialog, createScreen} = this.props;

    const {dialogData} = createScreenDialog;

    const [width, height] = resolution.split('x').map(value => Number(value));

    return new Promise((resolve, reject) => {
      this.setState({isCreatingScreen: true}, async () => {
        try {
          const organisationSpecified = Boolean(folderId) && folderId.startsWith('organisation-');

          const data = {
            id,
            name,
            description,
            defaultCampaignId,
            folderId: organisationSpecified ? null : folderId,
            resolution: {width, height}
          };

          if (organisationSpecified) {
            data.organisationId = folderId.match(/^organisation-(.+)/)[1];
          }

          const {item} = await createScreen(data);

          createScreenDialog.closeDialog();

          setTimeout(() => {
            dialogData.resolve(item);

            this.setState({
              isCreatingScreen: false,
              selectedScreenId: item.id
            }, resolve);
          }, 0);
        } catch (err) {
          dialogData.reject(err);

          this.setState({isCreatingScreen: false}, () => reject(err));
        }
      });
    });
  };

  _restoreScreen = data => {
    const {restoreScreenDialog, updateScreen} = this.props;
    const {selectedScreenId} = this.state;

    return new Promise((resolve, reject) => {
      this.setState({isUpdatingScreen: true}, async () => {
        try {
          await updateScreen(selectedScreenId, data);

          restoreScreenDialog.closeDialog();

          this.setState({isUpdatingScreen: false}, resolve);
        } catch (err) {
          this.setState({isUpdatingScreen: false}, reject);
        }
      });
    });
  };

  _updateScreen = ({folderId, ...rest}) => {
    const {updateScreenDialog, updateScreen} = this.props;
    const {selectedScreenId} = this.state;

    return new Promise((resolve, reject) => {
      this.setState({isUpdatingScreen: true}, async () => {
        try {
          const data = {
            ...rest,
            folderId: (folderId && folderId.startsWith('organisation-')) ? null : folderId
          };

          await updateScreen(selectedScreenId, data);

          updateScreenDialog.closeDialog();

          this.setState({isUpdatingScreen: false}, resolve);
        } catch (err) {
          this.setState({isUpdatingScreen: false}, reject);
        }
      });
    });
  };

  _handleCreateScreenFormChange = ({errors}) => {
    this.setState({createScreenFormHasErrors: Boolean(errors)});
  };

  _handleRestoreScreenFormChange = ({errors}) => {
    this.setState({restoreScreenFormHasErrors: Boolean(errors)});
  };

  _handleUpdateScreenFormChange = ({errors}) => {
    this.setState({updateScreenFormHasErrors: Boolean(errors)});
  };

  _submitScreenForm = () => {
    if (!this.submitCallback) {
      return;
    }

    this.submitCallback();
  };

  _setSubmitCallbackRef = callback => {
    this.submitCallback = callback;
  };
}

ScreensPage.WrappedComponent.WrappedComponent.WrappedComponent.propTypes = {
  createScreenDialog: PropTypes.shape(DIALOG_SHAPE).isRequired,
  restoreScreenDialog: PropTypes.shape(DIALOG_SHAPE).isRequired,
  updateScreenDialog: PropTypes.shape(DIALOG_SHAPE).isRequired
};

ScreensPage.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  subscriptionType: PropTypes.number,
  isStripeSubscriptionActive: PropTypes.bool,
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
  screens: PropTypes.arrayOf(PropTypes.object).isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  organisations: PropTypes.arrayOf(PropTypes.object),
  schedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  createScreen: PropTypes.func.isRequired,
  deleteScreen: PropTypes.func.isRequired,
  updateScreen: PropTypes.func.isRequired
};

ScreensPage.defaultProps = {
  organisations: null,
  subscriptionType: null,
  isStripeSubscriptionActive: false
};

export default ScreensPage;
