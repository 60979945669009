exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3dqs42wu{height:88px;-js-display:flex;display:flex}._2lN-_iNA{width:88px;height:88px;border-radius:50%;overflow:hidden}._2l0Mr7Jv{object-fit:cover}._2l0Mr7Jv,._1pUWqskg{width:100%;height:100%}.PpGZtwo2{margin-left:24px;height:100%;-js-display:flex;display:flex;align-items:center}._20z-ehIi{margin-left:12px}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,WACE,WAAY,CACZ,gBAAa,CAAb,YACF,CACA,WACE,UAAW,CACX,WAAY,CACZ,iBAAkB,CAClB,eACF,CACA,WACE,gBAGF,CACA,sBAHE,UAAW,CACX,WAKF,CACA,UACE,gBAAiB,CACjB,WAAY,CACZ,gBAAa,CAAb,YAAa,CACb,kBACF,CACA,WACE,gBACF","file":"styles.less","sourcesContent":[".upload-form-field {\n  height: 88px;\n  display: flex;\n}\n.preview-wrapper {\n  width: 88px;\n  height: 88px;\n  border-radius: 50%;\n  overflow: hidden;\n}\n.preview {\n  object-fit: cover;\n  width: 100%;\n  height: 100%;\n}\n.preview-placeholder {\n  width: 100%;\n  height: 100%;\n}\n.action-container {\n  margin-left: 24px;\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n.delete-button {\n  margin-left: 12px;\n}\n"]}]);
// Exports
exports.locals = {
	"upload-form-field": "_3dqs42wu",
	"preview-wrapper": "_2lN-_iNA",
	"preview": "_2l0Mr7Jv",
	"preview-placeholder": "_1pUWqskg",
	"action-container": "PpGZtwo2",
	"delete-button": "_20z-ehIi"
};