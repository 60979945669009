import {connect} from 'react-redux';

import Component from './component.jsx';

function mapStateToProps(state) {
  const {role} = state.user.user;

  return {role};
}

export default connect(
  mapStateToProps
)(Component);
