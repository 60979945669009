import {createAction} from 'redux-actions';

import {CAMPAIGNS} from '../action-types';

export const addSingle = createAction(CAMPAIGNS.ADD_SINGLE.ACTION);
export const addList = createAction(CAMPAIGNS.ADD_LIST.ACTION);
export const clear = createAction(CAMPAIGNS.CLEAR.ACTION);
export const deleteSingle = createAction(CAMPAIGNS.DELETE_SINGLE.ACTION);
export const setFetched = createAction(CAMPAIGNS.SET_FETCHED.ACTION);
export const updateSingle = createAction(CAMPAIGNS.UPDATE_SINGLE.ACTION);
