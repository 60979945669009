import axios from 'axios';
import * as firebase from 'firebase/app';

import {createApiAction} from '../../../lib/api';
import {stringifyQuery} from '../../../lib/helper';

import {API} from '../../action-types';

interface IGetSelfData {
  uid: string;
}

interface IUpdateSelfData extends IGetSelfData {
  photoUrl: string;
  name: string;
  title: string;
}

interface ISetSubscriptionData extends IGetSelfData {
  type: number;
  card?: string;
}

export const getSelf = createApiAction(
  API.USER.GET_SELF.ACTION,
  async ({data: {uid}}: {data: IUpdateSelfData}) => {
    const users = firebase.firestore().collection('users');

    const userDoc = await users
      .doc(uid)
      .get();

    const user = userDoc.data();

    return {
      item: {
        ...user,
        uid: userDoc.id
      }
    };
  }
);

export const setSubscription = createApiAction(
  API.USER.SET_SUBSCRIPTION.ACTION,
  async ({data}: {data: ISetSubscriptionData}) => {
    const {currentUser} = firebase.auth();

    if (!currentUser) {
      throw new Error('currentUser is null');
    }

    const token = await currentUser.getIdToken(true);

    const query = stringifyQuery(data);

    const response = await axios.request({
      method: 'post',
      url: `https://us-central1-voyr-6753d.cloudfunctions.net/express/setSubscription?${query}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return {
      item: {
        ...response.data.user
      }
    };
  }
);

export const updateSelf = createApiAction(
  API.USER.UPDATE_SELF.ACTION,
  async ({data: {uid, ...rest}}: {data: IUpdateSelfData}) => {
    const users = firebase.firestore().collection('users');

    await users
      .doc(uid)
      .update({...rest});
  }
);
