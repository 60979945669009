import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {createDispatchPromise} from '../../../lib/api';

import {api} from '../../../redux/actions';

import Component from './component.jsx';

function mapStateToProps(state) {
  const {items: campaigns, isFetching: isFetchingCampaigns} = state.campaigns;
  const {items: schedules, isFetching: isFetchingSchedules} = state.schedules;
  const {items: screens} = state.screens;
  const {role, organisationId} = state.user.user;
  const {items: folders} = state.folders;
  const {items: organisations} = state.organisations;

  return {
    folders,
    role,
    organisationId,
    campaigns,
    schedules,
    screens,
    organisations: (role === 'root') ? organisations : null,
    isFetching: isFetchingCampaigns || isFetchingSchedules
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createSchedule: api.schedules.createSingle,
    deleteSchedule: api.schedules.deleteSingle,
    updateSchedule: api.schedules.updateSingle
  }, dispatch);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {organisationId} = stateProps;

  return {
    ...ownProps,
    ...stateProps,
    createSchedule: data => {
      return createDispatchPromise(dispatchProps.createSchedule, ({data: {...data, organisationId}}));
    },
    deleteSchedule: id => {
      return createDispatchPromise(dispatchProps.deleteSchedule, ({data: {id}}));
    },
    updateSchedule: (id, data) => {
      return createDispatchPromise(dispatchProps.updateSchedule, ({data: {id, ...data}}));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);
