import React from 'react';
import * as PropTypes from 'prop-types';

import ScreenPreview from '../../common/screen-preview';

import styles from './styles.less';

class ScreenPreviewPage extends React.Component {
  render() {
    const {screen} = this.props;

    return (
      <div className={styles['page-content']}>
        {
          screen ? (
            <ScreenPreview screen={screen} userClassName={styles['screen-preview']}/>
          ) : (
            <div>Loading...</div>
          )
        }
      </div>
    );
  }
}

ScreenPreviewPage.propTypes = {
  screen: PropTypes.object
};

ScreenPreviewPage.defaultProps = {
  screen: null
};

export default ScreenPreviewPage;
