import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import MenuContainer from '../menu-container';

import styles from './styles.less';

function _getValue(value, options, format) {
  const option = options.find(opt => opt.value === value);

  if (option) {
    const hint = option.hint ? ` (${option.hint})` : '';

    return `${option.label || option.value}${hint}`;
  }

  if (format) {
    return format(value);
  }

  return value;
}

class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      text: null
    };
  }

  render() {
    const {userClassName, userStyle} = this.props;

    const wrapperClassNames = classNames(styles['select-wrapper'], userClassName);

    return (
      <MenuContainer
        renderButton={this._renderButton}
        renderMenu={this._renderMenu}
        userClassName={wrapperClassNames}
        userStyle={userStyle}
      />
    );
  }

  _renderButton = (toggle, setVisible) => {
    const {options, value, name, placeholder, userEditable, format, onFocus} = this.props;
    const {isEditing, text} = this.state;

    this._setVisible = setVisible;

    return (
      <input
        ref={this._setInputRef}
        readOnly={!userEditable || !isEditing}
        name={name}
        value={(userEditable && isEditing) ? text : _getValue(value, options, format)}
        placeholder={placeholder}
        className={styles.select}
        onChange={userEditable ? this._handleChange : null}
        onClick={toggle}
        onKeyPress={userEditable ? this._handleKeyPress : null}
        onFocus={onFocus}
        onBlur={this._handleBlur}
      />
    );
  };

  _renderMenu = () => {
    const {options} = this.props;

    return (
      <div className={styles.menu}>
        {
          options.map(this._renderOption)
        }
      </div>
    );
  };

  _renderOption = ({value, label, hint}) => {
    return (
      <div key={value} className={styles.option} onClick={this._selectValue(value)}>
        <span>
          <span>{label || value}</span>
          {
            hint && (
              <span className={styles.hint}>{` ${hint}`}</span>
            )
          }
        </span>
      </div>
    );
  };

  _selectValue = value => () => {
    const {userEditable, format, onChange} = this.props;

    this._setVisible(false, () => {
      if (onChange) {
        onChange(value);
      }

      if (userEditable) {
        this.setState({
          isEditing: true,
          text: format ? format(value) : value
        }, () => {
          this._inputRef.focus();
        });
      } else {
        this._inputRef.focus();
      }
    });
  };

  _handleChange = e => {
    this.setState({text: e.target.value});
  };

  _handleKeyPress = e => {
    const {parse, onChange} = this.props;
    const {text} = this.state;

    if (e.key !== 'Enter') {
      return;
    }

    this.setState({
      isEditing: false,
      text: null
    }, () => {
      if (onChange) {
        onChange(parse ? parse(text) : text);
      }
    });
  };

  _handleBlur = () => {
    const {userEditable, parse, onChange, onBlur} = this.props;
    const {isEditing, text} = this.state;

    if (userEditable && isEditing) {
      this.setState({
        isEditing: false,
        text: null
      }, () => {
        if (onChange) {
          onChange(parse ? parse(text) : text);
        }

        if (onBlur) {
          onBlur();
        }
      });
    } else if (onBlur) {
      onBlur();
    }
  };

  _setInputRef = el => {
    this._inputRef = el;
  }
}

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    hint: PropTypes.string
  })).isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  userEditable: PropTypes.bool,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  format: PropTypes.func,
  parse: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

Select.defaultProps = {
  name: null,
  placeholder: null,
  userEditable: false,
  userClassName: null,
  userStyle: null,
  format: null,
  parse: null,
  onChange: null,
  onFocus: null,
  onBlur: null
};

export default Select;
