import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import Icon from '../icon';

import styles from './styles.less';

class Button extends React.Component {
  static get VARIANT() {
    return {
      CONTAINED: 'contained',
      OUTLINED: 'outlined'
    };
  }

  static get COLOR() {
    return {
      NONE: 'none',
      PRIMARY: 'primary',
      SECONDARY: 'secondary',
      PRIMARY_ALTERNATE: 'primary_alternate',
      SECONDARY_ALTERNATE: 'secondary_alternate'
    };
  }

  static get SIZE() {
    return {
      NORMAL: 'normal',
      COMPACT: 'compact',
      LARGE: 'large',
      EXTRA_LARGE: 'extra_large'
    };
  }

  render() {
    const {variant, color, size, text, icon, disabled, userClassName, userStyle, onClick} = this.props;

    const buttonClassNames = classNames({
      [styles.button]: true,
      [styles['variant-contained']]: variant === Button.VARIANT.CONTAINED,
      [styles['variant-outlined']]: variant === Button.VARIANT.OUTLINED,
      [styles['size-normal']]: size === Button.SIZE.NORMAL,
      [styles['size-compact']]: size === Button.SIZE.COMPACT,
      [styles['size-large']]: size === Button.SIZE.LARGE,
      [styles['size-extra-large']]: size === Button.SIZE.EXTRA_LARGE,
      [styles['color-none']]: color === Button.COLOR.NONE,
      [styles['color-primary']]: color === Button.COLOR.PRIMARY,
      [styles['color-secondary']]: color === Button.COLOR.SECONDARY,
      [styles['color-primary-alternate']]: color === Button.COLOR.PRIMARY_ALTERNATE,
      [styles['color-secondary-alternate']]: color === Button.COLOR.SECONDARY_ALTERNATE,
      [styles['with-icon']]: Boolean(icon),
      [styles.disabled]: disabled,
      [userClassName]: Boolean(userClassName)
    });

    return (
      <div className={buttonClassNames} style={userStyle} onClick={disabled ? null : onClick}>
        {
          icon ? (
            <Icon type={icon} disabled={disabled} userClassName={styles.icon}/>
          ) : (
            <div className={styles.text}>{text}</div>
          )
        }
      </div>
    );
  }
}

Button.propTypes = {
  variant: PropTypes.oneOf(Object.values(Button.VARIANT)),
  color: PropTypes.oneOf(Object.values(Button.COLOR)),
  size: PropTypes.oneOf(Object.values(Button.SIZE)),
  text: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(Icon.TYPE)),
  disabled: PropTypes.bool,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onClick: PropTypes.func
};

Button.defaultProps = {
  variant: Button.VARIANT.CONTAINED,
  color: Button.COLOR.PRIMARY,
  size: Button.SIZE.NORMAL,
  text: '',
  icon: null,
  disabled: false,
  userClassName: null,
  userStyle: null,
  onClick: null
};

export default Button;
