import React from 'react';
import PropTypes from 'prop-types';

import {withStyles} from '@material-ui/core/styles';

export default styles => (WrappedComponent, propName = 'classes', staticProps = []) => {
  class ComponentWithStyles extends React.Component {
    render() {
      const {classes, ...rest} = this.props;

      const props = {
        ...rest,
        [propName]: classes
      };

      return (
        <WrappedComponent {...props}/>
      );
    }
  }

  ComponentWithStyles.propTypes = {
    classes: PropTypes.object.isRequired
  };

  staticProps.forEach(propName => {
    ComponentWithStyles[propName] = WrappedComponent[propName];
  });

  return withStyles(styles)(ComponentWithStyles);
};
