import {all} from 'redux-saga/effects';

import api from './api';
import commonData from './common-data';
import login from './login';
import logout from './logout';
import realtimeUpdates from './realtime-updates';
import registration from './registration';

export default function * root() {
  yield all([
    api(),
    commonData(),
    login(),
    logout(),
    realtimeUpdates(),
    registration()
  ]);
}
