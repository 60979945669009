import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import DocumentTitle from 'react-document-title';

import Navbar from './navbar';

import styles from './styles.less';

class Page extends React.Component {
  render() {
    const {title} = this.props;

    const page = this._renderPage();

    if (!title) {
      return page;
    }

    return (
      <DocumentTitle title={title}>
        {page}
      </DocumentTitle>
    );
  }

  _renderPage() {
    const {children, isLoading} = this.props;

    const contentWrapperClassNames = classNames({
      [styles['content-wrapper']]: true,
      [styles.loading]: isLoading
    });

    return (
      <div className={styles.page}>
        <Navbar/>
        <main className={contentWrapperClassNames}>
          {
            isLoading ? (
              <div>Loading...</div>
            ) : children
          }
        </main>
      </div>
    );
  }
}

Page.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  title: PropTypes.string
};

Page.defaultProps = {
  children: null,
  isLoading: false,
  title: null
};

export default Page;
