import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {createDispatchPromise} from '../../../../../lib/api';

import {api} from '../../../../../redux/actions';

import Component from './component.jsx';

function mapStateToProps(state) {
  const {user} = state.user;
  const {item} = state.organisation;

  return {user, organisation: item};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    logout: api.auth.logout
  }, dispatch);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...ownProps,
    ...stateProps,
    logout: () => {
      return createDispatchPromise(dispatchProps.logout);
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);
