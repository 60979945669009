import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {api} from '../../../redux/actions';

import Component from './component.jsx';
import {createDispatchPromise} from '../../../lib/api';

function mapStateToProps(state) {
  const {isLoggingIn} = state.auth.login;

  return {isLoggingIn};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    login: api.auth.login
  }, dispatch);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...ownProps,
    ...stateProps,
    login: data => {
      return createDispatchPromise(dispatchProps.login, {data});
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);
