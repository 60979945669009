import {put, takeLatest} from 'redux-saga/effects';

import history from '../../lib/history';

import {API} from '../action-types';

import {
  api as apiActions
} from '../actions';

function * redirectToDefaultPage(action) {
  history.push('/screens');

  const id = action.payload.response.organisationId;

  yield put(apiActions.organisation.getSingle({data: {id}}));
}

export default function * registration() {
  yield takeLatest(API.AUTH.REGISTER.SUCCESS, redirectToDefaultPage);
}
