import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.less';

class Input extends React.Component {
  static get TYPE() {
    return {
      EMAIL: 'email',
      PASSWORD: 'password',
      TEXT: 'text'
    };
  }

  componentDidMount() {
    if (this.props.focusOnMount) {
      this._inputRef.focus();
    }
  }

  render() {
    const {
      name,
      type,
      value,
      disabled,
      placeholder,
      userClassName,
      userStyle,
      onKeyPress,
      onFocus,
      onBlur
    } = this.props;

    const inputClassNames = classNames(styles.input, userClassName);

    return (
      <input
        ref={this._setInputRef}
        name={name}
        type={type}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        className={inputClassNames}
        style={userStyle}
        onChange={this._handleChange}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  }

  _handleChange = e => {
    const {onChange} = this.props;

    if (onChange) {
      onChange(e.target.value);
    }
  };

  _setInputRef = el => {
    this._inputRef = el;
  };
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(Input.TYPE)),
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  focusOnMount: PropTypes.bool,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

Input.defaultProps = {
  type: Input.TYPE.TEXT,
  placeholder: null,
  disabled: false,
  focusOnMount: false,
  userClassName: null,
  userStyle: null,
  onChange: null,
  onKeyPress: null,
  onFocus: null,
  onBlur: null
};

export default Input;
