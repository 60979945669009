exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1hFTpw12{position:relative}._1hFTpw12 ._6KlDajQy{position:absolute;background-color:#fff;z-index:1}._1hFTpw12.BxzYia_1 ._6KlDajQy{left:0;top:5px}._1hFTpw12._3LpnMNVn ._6KlDajQy{right:0;top:5px}._1hFTpw12._2UD51wpk ._6KlDajQy{left:0;top:calc(100% + 5px)}._1hFTpw12._3hGGom1H ._6KlDajQy{right:0;top:calc(100% + 5px)}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,WACE,iBACF,CACA,sBACE,iBAAkB,CAClB,qBAAsB,CACtB,SACF,CACA,+BACE,MAAO,CACP,OACF,CACA,gCACE,OAAQ,CACR,OACF,CACA,gCACE,MAAO,CACP,oBACF,CACA,gCACE,OAAQ,CACR,oBACF","file":"styles.less","sourcesContent":[".menu-container {\n  position: relative;\n}\n.menu-container .menu-wrapper {\n  position: absolute;\n  background-color: #fff;\n  z-index: 1;\n}\n.menu-container.menu-container-top-left .menu-wrapper {\n  left: 0;\n  top: 5px;\n}\n.menu-container.menu-container-top-right .menu-wrapper {\n  right: 0;\n  top: 5px;\n}\n.menu-container.menu-container-bottom-left .menu-wrapper {\n  left: 0;\n  top: calc(100% + 5px);\n}\n.menu-container.menu-container-bottom-right .menu-wrapper {\n  right: 0;\n  top: calc(100% + 5px);\n}\n"]}]);
// Exports
exports.locals = {
	"menu-container": "_1hFTpw12",
	"menu-wrapper": "_6KlDajQy",
	"menu-container-top-left": "BxzYia_1",
	"menu-container-top-right": "_3LpnMNVn",
	"menu-container-bottom-left": "_2UD51wpk",
	"menu-container-bottom-right": "_3hGGom1H"
};