const SERVER_SIDE_ERROR = 'Problems on the server side. Please, repeat the operation later.';
const UNEXPECTED_ERROR =
  'An unexpected error has occurred. Please, repeat the operation later, or contact the developers.';

function _getFiledPath(response) {
  const {extraData} = response.data.originalError;

  if (extraData && extraData.path) {
    return extraData.path.replace(/^_root\./, '');
  }

  return 'unknown';
}

function _getBadRequestError(response) {
  if (!response.data || !response.data.originalError) {
    return {_error: 'Please, check that all fields are valid'};
  }

  switch (response.data.originalError.code) {
  case 'MISSING_FIELD':
    return {_error: `A field is missing (${_getFiledPath(response)})`};
  case 'EXTRA_FIELDS':
    return {_error: `Extra field provided (${_getFiledPath(response)})`};
  case 'TYPE_MISMATCH':
    return {_error: `A field has wrong type (${_getFiledPath(response)})`};
  default:
    return {_error: 'Some fields are invalid or missing.'};
  }
}

export function getLoginByEmailErrors(err) {
  const {code, response} = err;

  switch (code) {
  case 'auth/user-not-found':
  case 'auth/wrong-password':
    return {_error: 'Invalid email or password'};
  case 'auth/too-many-requests':
    return {_error: 'You are trying to log in too frequently. Please, wait a bit'};
  default:
    break;
  }

  if (!response) {
    return {_error: UNEXPECTED_ERROR};
  }

  if (response.status === 403) {
    return {_error: 'Not enough rights'};
  }

  if (response.status === 404) {
    return {email: 'Account does not exist'};
  }

  if (response.status === 400) {
    switch (response.data.reason) {
    case 'user_not_active':
      return {email: 'Account is not activated'};
    case 'invalid_password':
      return {password: 'Invalid password'};
    default:
      return _getBadRequestError(response);
    }
  }

  return {_error: SERVER_SIDE_ERROR};
}

export function getRegisterErrors(err) {
  const {code} = err;

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}

export function getChangePasswordErrors(err) {
  const {code} = err;

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}

export function getCreateInviteErrors(err) {
  const {code} = err;

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}

export function getUpdateUserErrors(err) {
  const {code} = err;

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}

export function getResetPasswordErrors(err) {
  const {code} = err;

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}

export function getCreateCampaignErrors(err) {
  const {code} = err;

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}

export function getEditCampaignErrors(err) {
  const {code} = err;

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}

export function getCreateScheduleErrors(err) {
  const {code} = err;

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}

export function getEditScheduleErrors(err) {
  const {code} = err;

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}

export function getAddTimeSlotErrors(err) {
  const {code} = err;

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}

export function getEditTimeSlotErrors(err) {
  const {code} = err;

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}

export function getUpdateUserSelfErrors(err) {
  const {code} = err;

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}

export function getCreateFolderErrors(err) {
  const {code} = err;

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}

export function getCreateScreenErrors(err) {
  const {code} = err;

  if (err.message === 'Request failed with status code 409') {
    return {_error: 'ID already in use'};
  }

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}

export function getEditScreenErrors(err) {
  const {code} = err;

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}

export function getRestoreScreenErrors(err) {
  const {code} = err;

  if (!code) {
    return {_error: UNEXPECTED_ERROR};
  }

  switch (code) {
  default:
    return {_error: UNEXPECTED_ERROR};
  }
}
