import axios from 'axios';
import * as firebase from 'firebase/app';

import {createApiAction} from '../../../lib/api';

import {API} from '../../action-types';

interface IDeleteSingleData {
  uid: string;
}

interface IGetListData {
  role: string;
  organisationId: string;
}

interface IUpdateSingleData {
  uid: string;
  role: string;
}

export const deleteSingle = createApiAction(
  API.USERS.DELETE_SINGLE.ACTION,
  async ({data: {uid}}: {data: IDeleteSingleData}) => {
    const {currentUser} = firebase.auth();

    if (!currentUser) {
      throw new Error('currentUser is null');
    }

    const token = await currentUser.getIdToken(true);

    await axios.request({
      method: 'delete',
      url: `https://us-central1-voyr-6753d.cloudfunctions.net/express/deleteUser?uid=${uid}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
);

export const getList = createApiAction(
  API.USERS.GET_LIST.ACTION,
  async ({data: {role, organisationId}}: {data: IGetListData}) => {
    let query: any = firebase.firestore().collection('users');

    if (role !== 'root') {
      query = query.where('organisationId', '==', organisationId);
    }

    const userRef = await query.get();
    const items = userRef.docs.map(item => ({
      ...item.data(),
      uid: item.id
    }));

    return {items};
  }
);

export const updateSingle = createApiAction(
  API.USERS.UPDATE_SINGLE.ACTION,
  async ({data: {uid, ...rest}}: {data: IUpdateSingleData}) => {
    const organisations = firebase.firestore().collection('users');

    await organisations
      .doc(uid)
      .update({...rest});
  }
);
