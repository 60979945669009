import * as firebase from 'firebase/app';

import {createApiAction} from '../../../lib/api';

import {API} from '../../action-types';

interface IGetSingleData {
  id: string;
}

export const getSingle = createApiAction(
  API.ORGANISATION.GET_SINGLE.ACTION,
  async ({data: {id}}: {data: IGetSingleData}) => {
    const organisations = firebase.firestore().collection('organisations');

    const organisationDoc = await organisations
      .doc(id)
      .get();

    const organisation = organisationDoc.data();

    return {
      item: {
        ...organisation,
        id: organisationDoc.id
      }
    };
  }
);
