import {createAction} from 'redux-actions';

import {SCREENS} from '../action-types';

export const addSingle = createAction(SCREENS.ADD_SINGLE.ACTION);
export const addList = createAction(SCREENS.ADD_LIST.ACTION);
export const clear = createAction(SCREENS.CLEAR.ACTION);
export const deleteSingle = createAction(SCREENS.DELETE_SINGLE.ACTION);
export const setFetched = createAction(SCREENS.SET_FETCHED.ACTION);
export const updateSingle = createAction(SCREENS.UPDATE_SINGLE.ACTION);
