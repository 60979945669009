import React from 'react';
import * as PropTypes from 'prop-types';

import styles from './styles.less';

class EntityPlaceholder extends React.Component {
  render() {
    const {text} = this.props;

    return (
      <div className={styles['entity-placeholder']}>
        <div className={styles.container}>
          <img src={require('../../../assets/img/empty-state-illustration.png')} className={styles.image}/>
          <div className={styles.text}>{text}</div>
        </div>
      </div>
    );
  }
}

EntityPlaceholder.propTypes = {
  text: PropTypes.string.isRequired
};

export default EntityPlaceholder;
