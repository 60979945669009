import {defineAction} from 'redux-define';

const screens = defineAction('screens');

export const ADD_SINGLE = defineAction('ADD_SINGLE', screens);
export const ADD_LIST = defineAction('ADD_LIST', screens);
export const CLEAR = defineAction('CLEAR', screens);
export const DELETE_SINGLE = defineAction('DELETE_SINGLE', screens);
export const SET_FETCHED = defineAction('SET_FETCHED', screens);
export const UPDATE_SINGLE = defineAction('UPDATE_SINGLE', screens);
