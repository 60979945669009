import axios from 'axios';
import * as firebase from 'firebase/app';

import {createApiAction} from '../../../lib/api';
import {stringifyQuery} from '../../../lib/helper';

import {API} from '../../action-types';

interface ILoginData {
  email: string;
  name: string;
  password: string;
}

interface IRegisterData extends ILoginData {
  token: string;
}

export const login = createApiAction(API.AUTH.LOGIN.ACTION, async ({data: {email, password}}: {data: ILoginData}) => {
  const {user} = await firebase.auth().signInWithEmailAndPassword(email, password);

  const users = firebase.firestore().collection('users');

  const userDoc = await users
    .doc(user.uid)
    .get();

  if (!userDoc.exists) {
    throw new Error('Something went wrong');
  }

  return {
    ...userDoc.data(),
    uid: userDoc.id
  };
});

export const logout = createApiAction(API.AUTH.LOGOUT.ACTION, async (): Promise<void> => {
  await firebase.auth().signOut();
});

export const register = createApiAction(
  API.AUTH.REGISTER.ACTION,
  async ({data: {email, name, password, token}}: {data: IRegisterData}) => {
    const query = stringifyQuery({email, name, password, token});

    const response = await axios.post(`https://us-central1-voyr-6753d.cloudfunctions.net/express/register?${query}`);

    await firebase.auth().signInWithEmailAndPassword(email, password);

    return response;
  }
);
