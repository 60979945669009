import React from 'react';
import * as PropTypes from 'prop-types';

import {Field} from 'react-form';

import classNames from 'classnames';

import DateInput from '../../date-input';
import EntitySelect from '../../entity-select';
import FolderSelect from '../../folder-select';
import Input from '../../input';
import RadioGroup from '../../radio-group';
import Select from '../../select';
import Textarea from '../../textarea';
import TreeView from '../../tree-view';

import UploadFormField from './upload';

import styles from './styles.less';

class FormField extends React.Component {
  static get TYPE() {
    return {
      CUSTOM: 'custom',
      DATE: 'date',
      EMAIL: 'email',
      ENTITY_SELECT: 'entity_select',
      FOLDER_SELECT: 'folder_select',
      HIDDEN: 'hidden',
      PASSWORD: 'password',
      RADIO: 'radio',
      SELECT: 'select',
      TEXT: 'text',
      UPLOAD: 'upload'
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      isActive: false
    };
  }

  render() {
    const {name, label, description, optional, preValidate, validate, asyncValidate} = this.props;
    const {isActive} = this.state;

    const formFieldClassNames = classNames(styles['form-field'], {
      [styles['form-field-active']]: isActive
    });

    return (
      <div className={formFieldClassNames}>
        {
          label && (
            <div className={styles.label}>
              {label}
              {
                optional && (
                  <span className={styles['label-optional-flag']}> (optional)</span>
                )
              }
            </div>
          )
        }
        <Field field={name} preValidate={preValidate} validate={validate} asyncValidate={asyncValidate}>
          {
            this._getRenderMethod()
          }
        </Field>
        {
          description && (
            <div className={styles.description}>
              {description}
            </div>
          )
        }
      </div>
    );
  }

  _getRenderMethod() {
    const {type} = this.props;

    switch (type) {
    case FormField.TYPE.CUSTOM:
      return this._renderCustom;
    case FormField.TYPE.DATE:
      return this._renderDateInput;
    case FormField.TYPE.ENTITY_SELECT:
      return this._renderEntitySelect;
    case FormField.TYPE.FOLDER_SELECT:
      return this._renderFolderSelect;
    case FormField.TYPE.HIDDEN:
      return this._renderHidden;
    case FormField.TYPE.RADIO:
      return this._renderRadio;
    case FormField.TYPE.SELECT:
      return this._renderSelect;
    case FormField.TYPE.TEXT:
      return this._renderText;
    case FormField.TYPE.UPLOAD:
      return this._renderUpload;
    default:
      return this._renderInput;
    }
  }

  _renderCustom = formApi => {
    const {render, ...rest} = this.props;

    return render({...rest}, formApi);
  };

  _renderDateInput = ({value, setValue}) => {
    const {name, placeholder, disabled} = this.props;

    return (
      <DateInput
        name={name}
        value={value || null}
        disabled={disabled}
        placeholder={placeholder}
        userClassName={styles.input}
        onChange={setValue}
      />
    );
  };

  _renderRadio = ({value, setValue}) => {
    const {name, options, disabled} = this.props;

    return (
      <RadioGroup
        name={name}
        options={options || []}
        value={value || ''}
        disabled={disabled}
        userClassName={styles['radio-group']}
        onChange={setValue}
        onFocus={this._setActive}
        onBlur={this._setInactive}
      />
    );
  };

  _renderEntitySelect = ({value, setValue}) => {
    const {entityType, multi, folders, entities, organisations, placeholder} = this.props;

    return (
      <EntitySelect
        entityType={entityType}
        multi={multi}
        folders={folders}
        entities={entities}
        placeholder={placeholder}
        organisations={organisations}
        value={value}
        userClassName={multi ? styles['entity-select-multi'] : styles['folder-select']}
        onChange={setValue}
      />
    );
  };

  _renderFolderSelect = ({value, setValue}) => {
    const {entityType, folders, organisations, placeholder} = this.props;

    return (
      <FolderSelect
        entityType={entityType}
        folders={folders}
        placeholder={placeholder}
        organisations={organisations}
        value={value}
        userClassName={styles['folder-select']}
        onChange={setValue}
      />
    );
  };

  _renderHidden = ({value}) => {
    const {name} = this.props;

    return (
      <input name={name} type="hidden" value={value}/>
    );
  };

  _renderInput = ({value, setValue}) => {
    const {name, type, placeholder, disabled} = this.props;

    return (
      <Input
        name={name}
        type={type}
        value={value || ''}
        disabled={disabled}
        placeholder={placeholder}
        userClassName={styles.input}
        onChange={setValue}
        onFocus={this._setActive}
        onBlur={this._setInactive}
      />
    );
  };

  _renderSelect = ({value, setValue}) => {
    const {name, placeholder, disabled, options, userEditable, format, parse} = this.props;

    return (
      <Select
        name={name}
        options={options || []}
        value={value || ''}
        disabled={disabled}
        placeholder={placeholder}
        userEditable={userEditable}
        userClassName={styles.input}
        format={format}
        parse={parse}
        onChange={setValue}
        onFocus={this._setActive}
        onBlur={this._setInactive}
      />
    );
  };

  _renderText = params => {
    const {name, placeholder, disabled, multiline, rows, rowsMax} = this.props;

    if (!multiline) {
      return this._renderInput(params);
    }

    const {value, setValue} = params;

    return (
      <Textarea
        name={name}
        value={value || ''}
        placeholder={placeholder}
        disabled={disabled}
        rows={rows}
        rowsMax={rowsMax}
        userClassName={styles.textarea}
        onChange={setValue}
        onFocus={this._setActive}
        onBlur={this._setInactive}
      />
    );
  };

  _renderUpload = ({value, setValue}) => {
    const {accept, createPath, disabled} = this.props;

    return (
      <UploadFormField
        value={value}
        createPath={createPath}
        accept={accept}
        disabled={disabled}
        userClassName={styles.upload}
        onChange={setValue}
      />
    );
  };

  _setActive = () => {
    this.setState({isActive: true});
  };

  _setInactive = () => {
    this.setState({isActive: false});
  };
}

FormField.propTypes = {
  type: PropTypes.oneOf(Object.values(FormField.TYPE)).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    hint: PropTypes.string
  })),
  userEditable: PropTypes.bool,
  accept: PropTypes.string,
  render: PropTypes.func,
  createPath: PropTypes.func,
  entityType: PropTypes.oneOf(Object.values(TreeView.ENTITY_TYPE)),
  multi: PropTypes.bool,
  folders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    organisationId: PropTypes.string.isRequired,
    parentId: PropTypes.string
  })),
  entities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    organisationId: PropTypes.string.isRequired,
    folderId: PropTypes.string
  })),
  organisations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  disabled: PropTypes.bool,
  optional: PropTypes.bool,
  format: PropTypes.func,
  parse: PropTypes.func,
  preValidate: PropTypes.func,
  validate: PropTypes.func,
  asyncValidate: PropTypes.func
};

FormField.defaultProps = {
  label: '',
  placeholder: null,
  description: null,
  multiline: false,
  rows: 4,
  rowsMax: 4,
  options: null,
  userEditable: false,
  accept: 'image/png, image/jpeg',
  render: null,
  createPath: null,
  entityType: null,
  multi: false,
  folders: null,
  entities: null,
  organisations: null,
  disabled: false,
  optional: false,
  format: null,
  parse: null,
  preValidate: null,
  validate: null,
  asyncValidate: null
};

export default FormField;
