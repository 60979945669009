import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import Button from '../../../button';
import FileUpload from '../../../file-upload';
import Icon from '../../../icon';

import styles from './styles.less';

class UploadFormField extends React.Component {
  render() {
    const {value, createPath, accept, disabled, userClassName, userStyle} = this.props;

    const uploadFormFieldClassNames = classNames(styles['upload-form-field'], userClassName);

    return (
      <div className={uploadFormFieldClassNames} style={userStyle}>
        <div className={styles['preview-wrapper']}>
          {
            value ? (
              <img src={value} className={styles.preview}/>
            ) : (
              <Icon type={Icon.TYPE.CAMERA} userClassName={styles['preview-placeholder']}/>
            )
          }
        </div>
        <div className={styles['action-container']}>
          <FileUpload
            createPath={createPath}
            accept={accept}
            disabled={disabled}
            onUpload={this._handleUpload}
            onError={this._handleError}
          >
            <Button disabled={disabled} color={Button.COLOR.PRIMARY_ALTERNATE} text="Upload New Photo"/>
          </FileUpload>
          <Button
            disabled={disabled}
            color={Button.COLOR.SECONDARY}
            text="Delete"
            userClassName={styles['delete-button']}
            onClick={this._clear}
          />
        </div>
      </div>
    );
  }

  _handleUpload = async ref => {
    const {onChange} = this.props;

    if (onChange) {
      const url = await ref.getDownloadURL();

      onChange(url);
    }
  };

  _handleError = err => {
    // eslint-disable-next-line no-alert
    alert(err);
  };

  _clear = () => {
    const {onChange} = this.props;

    if (onChange) {
      onChange(null);
    }
  };
}

UploadFormField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null])
  ]),
  createPath: PropTypes.func.isRequired,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onChange: PropTypes.func
};

UploadFormField.defaultProps = {
  value: null,
  accept: 'image/png, image/jpeg',
  disabled: false,
  userClassName: null,
  userStyle: null,
  onChange: null
};

export default UploadFormField;
