exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1QhrwiSn ._1uAb51oa{margin-bottom:14.5px;font-size:14px;font-weight:600;color:#72536a}._1QhrwiSn ._2ZMMNmdg{color:#d9c8d5}._1QhrwiSn ._3G8QpfZN{margin-top:14.5px;font-size:14px;color:#72536a}._1QhrwiSn.zcNbP7lj ._1uAb51oa{color:#ff2d17}._1QhrwiSn.zcNbP7lj ._2ZMMNmdg{color:#ff9b90}._2t6s6Fmh{width:100%;height:64px}._1KL6JtGK{width:100%;min-height:64px}.xOjknPbt{width:100%;height:64px}._1E45CYdr{width:100%;min-height:64px}._1zIbzEnN{resize:vertical}._1zIbzEnN,._28xq4_yD{width:100%}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,sBACE,oBAAqB,CACrB,cAAe,CACf,eAAgB,CAChB,aACF,CACA,sBACE,aACF,CACA,sBACE,iBAAkB,CAClB,cAAe,CACf,aACF,CACA,+BACE,aACF,CACA,+BACE,aACF,CACA,WACE,UAAW,CACX,WACF,CACA,WACE,UAAW,CACX,eACF,CACA,UACE,UAAW,CACX,WACF,CACA,WACE,UAAW,CACX,eACF,CACA,WAEE,eACF,CACA,sBAHE,UAKF","file":"styles.less","sourcesContent":[".form-field .label {\n  margin-bottom: 14.5px;\n  font-size: 14px;\n  font-weight: 600;\n  color: #72536a;\n}\n.form-field .label-optional-flag {\n  color: #d9c8d5;\n}\n.form-field .description {\n  margin-top: 14.5px;\n  font-size: 14px;\n  color: #72536a;\n}\n.form-field.form-field-active .label {\n  color: #ff2d17;\n}\n.form-field.form-field-active .label-optional-flag {\n  color: #ff9b90;\n}\n.folder-select {\n  width: 100%;\n  height: 64px;\n}\n.entity-select-multi {\n  width: 100%;\n  min-height: 64px;\n}\n.input {\n  width: 100%;\n  height: 64px;\n}\n.radio-group {\n  width: 100%;\n  min-height: 64px;\n}\n.textarea {\n  width: 100%;\n  resize: vertical;\n}\n.upload {\n  width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"form-field": "_1QhrwiSn",
	"label": "_1uAb51oa",
	"label-optional-flag": "_2ZMMNmdg",
	"description": "_3G8QpfZN",
	"form-field-active": "zcNbP7lj",
	"folder-select": "_2t6s6Fmh",
	"entity-select-multi": "_1KL6JtGK",
	"input": "xOjknPbt",
	"radio-group": "_1E45CYdr",
	"textarea": "_1zIbzEnN",
	"upload": "_28xq4_yD"
};