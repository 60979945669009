const HOUR = 60 * 60 * 1000;
const STEP = 5 * 60 * 1000;

export const USER_ROLE_OPTIONS = [{
  value: 'user',
  label: 'User'
}, {
  value: 'admin',
  label: 'Admin'
}];

export const RESOLUTION_OPTIONS = [{
  value: '1920x1080',
  label: 'Landscape (1920 x 1080 16:9)'
}, {
  value: '1080x1920',
  label: 'Portrait (1080 x 1920 9:16)'
}, {
  value: '800x1200',
  label: 'Portrait (800 x 1200 2:3)'
}];

export const TIME_SLOT_REPEAT_OPTIONS = [{
  value: 'once',
  label: 'Don\'t repeat'
}, {
  value: 'daily',
  label: 'Repeat daily'
}, {
  value: 'weekly',
  label: 'Repeat weekly'
}];

export function getTimeSlotTimeOptions({firstHour, lastHour, freeTimeRanges, isEnd}) {
  const date = new Date();

  const options = [];

  for (let msecs = firstHour * HOUR; msecs <= (lastHour * HOUR); msecs += STEP) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(msecs);

    const isInRanges = freeTimeRanges.some(({timeStart, timeEnd}) => {
      return (isEnd ? (msecs > timeStart) : (msecs >= timeStart)) && (isEnd ? (msecs <= timeEnd) : (msecs < timeEnd));
    });

    if (isInRanges) {
      options.push({
        value: `${msecs}`,
        label: date.toLocaleString('en', {
          hour: 'numeric',
          hour12: true,
          minute: '2-digit'
        }).replace(' ', '').toLowerCase()
      });
    }
  }

  return options;
}
