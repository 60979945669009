import React from 'react';
import * as PropTypes from 'prop-types';

import {Link} from 'react-router-dom';

import classNames from 'classnames';

import {isExternalUrl} from '../../../lib/helper';

import styles from './styles.less';

class ClickableText extends React.Component {
  render() {
    const {disabled, domRef, text, title, href, target, userClassName, userStyle, onClick} = this.props;

    const clickableTextClassNames = classNames({
      [userClassName]: Boolean(userClassName),
      [styles['clickable-text']]: true,
      [styles.disabled]: disabled // TODO
    });

    if (disabled) {
      return (
        <span ref={domRef} className={clickableTextClassNames} style={userStyle} title={title}>
          {text}
        </span>
      );
    }

    if (!href) {
      return (
        <span ref={domRef} className={clickableTextClassNames} style={userStyle} title={title} onClick={onClick}>
          {text}
        </span>
      );
    }

    if (isExternalUrl(href) || target) {
      const linkProps = {
        href,
        title,
        ref: domRef,
        className: clickableTextClassNames,
        style: userStyle
      };

      if (target) {
        linkProps.target = target;
      }

      return (
        <a {...linkProps}>
          {text}
        </a>
      );
    }

    return (
      <Link innerRef={domRef} to={href} className={clickableTextClassNames} style={userStyle} title={title}>
        {text}
      </Link>
    );
  }
}

ClickableText.propTypes = {
  disabled: PropTypes.bool,
  domRef: PropTypes.func,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onClick: PropTypes.func
};

ClickableText.defaultProps = {
  disabled: false,
  domRef: null,
  title: '',
  href: null,
  target: null,
  userClassName: null,
  userStyle: null,
  onClick: null
};

export default ClickableText;
