import {connect} from 'react-redux';

import history from '../../../lib/history';

import createComponent from './component.jsx';

function mapStateToProps(state, ownProps) {
  const _isLoggedIn = Boolean(state.user.user);

  const {pathname, search, hash} = history.location;

  const _redirect = `/login?redirect=${encodeURIComponent(pathname + search + (hash || ''))}`;

  return {_isLoggedIn, _redirect, ...ownProps};
}

export default WrappedComponent => {
  return connect(
    mapStateToProps
  )(createComponent(WrappedComponent));
};
