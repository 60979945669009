import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {createDispatchPromise} from '../../../lib/api';

import {api} from '../../../redux/actions';

import TreeView from '../tree-view';

import Component from './component.jsx';

function _getEntities(entityType, state) {
  switch (entityType) {
  case TreeView.ENTITY_TYPE.CAMPAIGN:
    return state.campaigns;
  case TreeView.ENTITY_TYPE.SCHEDULE:
    return state.schedules;
  case TreeView.ENTITY_TYPE.SCREEN:
    return state.screens;
  default:
    return null;
  }
}

function mapStateToProps(state, {entityType}) {
  const entities = _getEntities(entityType, state);
  const {folders, organisations} = state;
  const {role, organisationId} = state.user.user;

  return {
    entities,
    folders,
    organisations,
    organisationId,
    role
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createFolder: api.folders.createSingle,
    deleteFolder: api.folders.deleteSingle,
    updateFolder: api.folders.updateSingle
  }, dispatch);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {organisationId} = stateProps;

  return {
    ...ownProps,
    ...stateProps,
    createFolder: data => {
      return createDispatchPromise(dispatchProps.createFolder, ({data: {organisationId, ...data}}));
    },
    deleteFolder: id => {
      return createDispatchPromise(dispatchProps.deleteFolder, ({data: {id}}));
    },
    updateFolder: (id, data) => {
      return createDispatchPromise(dispatchProps.updateFolder, ({data: {id, ...data}}));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);
