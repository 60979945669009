export function getCampaignDefaultValues(campaign) {
  return {
    name: campaign ? campaign.name : '',
    description: campaign ? campaign.description : '',
    folderId: campaign ? campaign.folderId : null
  };
}

export function getScreenDefaultValues(screen) {
  return {
    name: screen ? screen.name : '',
    description: screen ? screen.description : '',
    defaultCampaignId: screen ? screen.defaultCampaignId : null,
    folderId: screen ? screen.folderId : null
  };
}

export function getScheduleDefaultValues(schedule) {
  return {
    name: schedule ? schedule.name : '',
    description: schedule ? schedule.description : '',
    screenIds: schedule ? schedule.screenIds : [],
    folderId: schedule ? schedule.folderId : null
  };
}

export function getTimeSlotDefaultValues(timeSlot, timeStart, timeEnd, date) {
  return {
    campaignId: timeSlot ? timeSlot.campaignId : null,
    repeat: timeSlot ? timeSlot.repeat : 'once',
    timeStart: timeSlot ? timeSlot.timeStart.toString() : (timeStart || null),
    timeEnd: timeSlot ? timeSlot.timeEnd.toString() : (timeEnd || null),
    date: timeSlot ? (timeSlot.date || null) : (date || null)
  };
}

export function getUserSelfDefaultValues(user) {
  return {
    photoUrl: user ? user.photoUrl : null,
    name: user ? user.name : '',
    title: user ? user.title : '',
    email: user ? user.email : ''
  };
}
