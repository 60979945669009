import React from 'react';

import history from '../../../lib/history';

import styles from './styles.less';

export default class NotFoundPage extends React.Component {
  render() {
    const {pathname, search, hash} = history.location;

    if (!/^\/404\/?/.test(pathname)) {
      setTimeout(() => {
        history.replace(`/404?href=${pathname}${search}${hash}`);
      }, 0);
    }

    return (
      <div className={styles['not-found-page']}>
        <div className={styles['not-found-message']}>
          Page not found :(
        </div>
      </div>
    );
  }
}
