import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {createDispatchPromise} from '../../../lib/api';

import {api} from '../../../redux/actions';

import Component from './component.jsx';

function mapStateToProps(state) {
  const {items: campaigns, isFetching} = state.campaigns;
  const {role, organisationId} = state.user.user;
  const {items: folders} = state.folders;
  const {items: organisations} = state.organisations;
  const {items: schedules} = state.schedules;
  const {items: screens} = state.screens;

  return {
    folders,
    isFetching,
    role,
    organisationId,
    campaigns,
    schedules,
    screens,
    organisations: (role === 'root') ? organisations : null
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createCampaign: api.campaigns.createSingle,
    deleteCampaign: api.campaigns.deleteSingle,
    updateCampaign: api.campaigns.updateSingle
  }, dispatch);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {organisationId} = stateProps;

  return {
    ...ownProps,
    ...stateProps,
    createCampaign: data => {
      return createDispatchPromise(dispatchProps.createCampaign, ({data: {organisationId, ...data}}));
    },
    deleteCampaign: id => {
      return createDispatchPromise(dispatchProps.deleteCampaign, ({data: {id}}));
    },
    updateCampaign: (id, data) => {
      return createDispatchPromise(dispatchProps.updateCampaign, ({data: {id, ...data}}));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);
