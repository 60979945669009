import React from 'react';
import * as PropTypes from 'prop-types';

import Calendar from 'react-calendar';

import classNames from 'classnames';

import {getDateString} from '../../../lib/helper';

import MenuContainer from '../menu-container';

import styles from './styles.less';

class DateInput extends React.Component {
  render() {
    const {userClassName, userStyle} = this.props;

    const wrapperClassNames = classNames(styles['input-wrapper'], userClassName);

    return (
      <MenuContainer
        position={MenuContainer.POSITION.TOP_LEFT}
        renderButton={this._renderButton}
        renderMenu={this._renderMenu}
        userClassName={wrapperClassNames}
        userStyle={userStyle}
      />
    );
  }

  _renderButton = toggle => {
    const {name, value, disabled, placeholder} = this.props;

    return (
      <input
        readOnly
        name={name}
        value={value ? ((typeof value === 'string') ? getDateString(new Date(value)) : getDateString(value)) : ''}
        disabled={disabled}
        placeholder={placeholder}
        className={styles.input}
        onClick={toggle}
      />
    );
  };

  _renderMenu = setVisible => {
    const {value} = this.props;

    return (
      <Calendar
        value={(typeof value === 'string') ? new Date(value) : value}
        className={styles.calendar}
        onChange={this._handleChange(setVisible)}
      />
    );
  };

  _handleChange = setVisible => date => {
    const {onChange} = this.props;

    setVisible(false, () => {
      if (onChange) {
        onChange(date);
      }
    });
  };
}

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onChange: PropTypes.func
};

DateInput.defaultProps = {
  value: null,
  placeholder: null,
  disabled: false,
  userClassName: null,
  userStyle: null,
  onChange: null
};

export default DateInput;
