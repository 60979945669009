import React from 'react';

import * as firebase from 'firebase/app';

import {getResetPasswordErrors} from '../../../lib/form/error-getters';
import {PASSWORD_RESETTING_FORM_FIELDS} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';

import AuthPage from '../../common/auth-page';

class PasswordResettingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isResetting: false,
      hasReset: false
    };
  }

  render() {
    const {isResetting, hasReset} = this.state;

    const formProps = {
      children: hasReset ? this._renderStatic() : null,
      actionText: hasReset ? null : 'Reset',
      fields: hasReset ? null : PASSWORD_RESETTING_FORM_FIELDS,
      disabled: isResetting,
      onSubmit: this._resetPassword,
      onSubmitFailure: handleSubmitFailure(getResetPasswordErrors)
    };

    return (
      <AuthPage formProps={formProps}/>
    );
  }

  _renderStatic() {
    return (
      <div>
        <p>
          We sent instructions for resetting password to your email
        </p>
      </div>
    );
  }

  _resetPassword = async ({email}) => {
    this.setState({isResetting: true}, async () => {
      try {
        await firebase.auth().sendPasswordResetEmail(email);

        this.setState({
          isResetting: false,
          hasReset: true
        });
      } catch (err) {
        this.setState({isResetting: false});

        throw err;
      }
    });
  };
}

export default PasswordResettingPage;
