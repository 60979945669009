import {createReducer} from '@ololoepepe/redux-tools';

import IOrganisation from '../../models/organisation';

import {API, ORGANISATION} from '../action-types';

interface IState {
  error: any;
  isFetching: boolean;
  item: IOrganisation | null;
}

const INITIAL_STATE: IState = {
  error: null,
  isFetching: false,
  item: null
};

function pending(state: IState): IState {
  return {
    ...state,
    isFetching: true,
    item: null
  };
}

function success(state: IState, {payload}): IState {
  return {
    ...state,
    error: null,
    isFetching: false,
    item: {...payload.response.item}
  };
}

function error(state: IState, {payload}): IState {
  return {
    ...state,
    error: payload.error,
    isFetching: false
  };
}

export default createReducer(INITIAL_STATE, {
  [API.ORGANISATION.GET_SINGLE.PENDING]: pending,
  [API.ORGANISATION.GET_SINGLE.SUCCESS]: success,
  [API.ORGANISATION.GET_SINGLE.ERROR]: error,
  [ORGANISATION.CLEAR.ACTION]: () => ({...INITIAL_STATE})
});
