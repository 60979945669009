import {createReducer} from '@ololoepepe/redux-tools';

import ICampaign from '../../models/campaign';

import {CAMPAIGNS} from '../action-types';

interface IState {
  error: any;
  isFetching: boolean;
  items: ICampaign[];
}

const INITIAL_STATE: IState = {
  error: null,
  isFetching: true,
  items: []
};

function addSingle(state: IState, {payload}): IState {
  if (state.items.some(item => item.id === payload.id)) {
    return state;
  }

  return {
    ...state,
    items: [...state.items, payload]
  };
}

function addList(state: IState, {payload}): IState {
  const list = payload.filter(({id}) => {
    return state.items.every(item => item.id !== id);
  });

  if (list.length < 1) {
    return state;
  }

  return {
    ...state,
    items: [...state.items, ...list]
  };
}

function deleteSingle(state: IState, {payload}): IState {
  const {id} = payload;

  const index = state.items.findIndex(item => item.id === id);

  if (index < 0) {
    return state;
  }

  const newItems = [...state.items];
  newItems.splice(index, 1);

  return {
    ...state,
    items: newItems
  };
}

function setFetched(state: IState, {payload}): IState {
  return {
    ...state,
    isFetching: !payload
  };
}

function updateSingle(state: IState, {payload}): IState {
  const {id, ...rest} = payload;

  const index = state.items.findIndex(item => item.id === id);

  if (index < 0) {
    return state;
  }

  const newItems = [...state.items];
  newItems[index] = {
    ...newItems[index],
    ...rest
  };

  return {
    ...state,
    items: newItems
  };
}

export default createReducer(INITIAL_STATE, {
  [CAMPAIGNS.ADD_SINGLE]: addSingle,
  [CAMPAIGNS.ADD_LIST]: addList,
  [CAMPAIGNS.CLEAR]: () => ({...INITIAL_STATE}),
  [CAMPAIGNS.DELETE_SINGLE]: deleteSingle,
  [CAMPAIGNS.SET_FETCHED]: setFetched,
  [CAMPAIGNS.UPDATE_SINGLE]: updateSingle
});
