import {defineAction} from 'redux-define';

const campaigns = defineAction('campaigns');

export const ADD_SINGLE = defineAction('ADD_SINGLE', campaigns);
export const ADD_LIST = defineAction('ADD_LIST', campaigns);
export const CLEAR = defineAction('CLEAR', campaigns);
export const DELETE_SINGLE = defineAction('DELETE_SINGLE', campaigns);
export const SET_FETCHED = defineAction('SET_FETCHED', campaigns);
export const UPDATE_SINGLE = defineAction('UPDATE_SINGLE', campaigns);
