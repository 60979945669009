import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import {findElement} from '../../../lib/helper';

import Icon from '../icon';

import styles from './styles.less';

class EntityInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuVisible: false
    };
  }

  componentDidMount() {
    window.document.addEventListener('click', this._handleClickOutside);
  }

  componentWillUnmount() {
    window.document.removeEventListener('click', this._handleClickOutside);
  }

  render() {
    const {name, description, menuItems, badges, disabled, contentRight} = this.props;
    const {isMenuVisible} = this.state;

    return (
      <div>
        <div className={styles['info-header']}>
          <div className={styles['info-name']}>{name}</div>
          {
            !disabled && (menuItems.length > 0) && (
              <div ref={this._setMenuRef} className={styles['menu-button-wrapper']}>
                <div className={styles['menu-button']} onClick={this._toggleMenu}>···</div>
                {
                  isMenuVisible && (
                    <div ref={this._setOverlayMenuRef} className={styles.menu}>
                      {
                        menuItems.map(this._renderMenuItem)
                      }
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
        {
          (badges.length > 0) && (
            <div className={styles['info-subheader']}>
              {
                badges.map(this._renderBadge)
              }
            </div>
          )
        }
        {
          (description || contentRight) && (
            <div className={styles['info-description-container']}>
              {
                description && (
                  <div className={styles['info-description']}>
                    {description}
                  </div>
                )
              }
              {contentRight}
            </div>
          )
        }
      </div>
    );
  }

  _renderMenuItem = (item, index) => {
    if (item === 'separator') {
      return (
        <div key={index} className={styles['menu-separator']}/>
      );
    }

    const {title, secondary, onClick} = item;

    const itemClassNames = classNames({
      [styles['menu-item']]: true,
      [styles['menu-item-secondary']]: Boolean(secondary)
    });

    return (
      <div key={index} className={itemClassNames} onClick={onClick}>
        {title}
      </div>
    );
  };

  _renderBadge = ({type, text}, index) => {
    const isFolder = type === 'folder';
    const isScreen = type === 'screen';

    const badgeClassNames = classNames({
      [styles.badge]: true,
      [styles['badge-live']]: type === 'live',
      [styles['badge-folder']]: isFolder,
      [styles['badge-screen']]: isScreen
    });

    return (
      <div key={index} className={badgeClassNames}>
        {
          (type === 'live') ? (
            <div className={styles['badge-dot']}/>
          ) : (
            <div className={styles['badge-icon']}>
              <Icon type={isFolder ? Icon.TYPE.FOLDER : Icon.TYPE.SCREEN}/>
            </div>
          )
        }
        <div className={styles['badge-text']}>{text}</div>
      </div>
    );
  };

  _toggleMenu = () => {
    this.setState(state => ({isMenuVisible: !state.isMenuVisible}));
  };

  _handleClickOutside = e => {
    if (findElement(e.target, this._menuRef) || findElement(e.target, this._overlayMenuRef)) {
      return;
    }

    this.setState({isMenuVisible: false});
  };

  _setMenuRef = el => {
    this._menuRef = el;
  };

  _setOverlayMenuRef = el => {
    this._overlayMenuRef = el;
  };
}

EntityInfo.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.oneOf(['separator']),
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      secondary: PropTypes.bool,
      onClick: PropTypes.func
    })
  ])),
  badges: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['live', 'folder', 'screen']).isRequired,
    text: PropTypes.string.isRequired
  })),
  disabled: PropTypes.bool,
  contentRight: PropTypes.node
};

EntityInfo.defaultProps = {
  description: null,
  menuItems: [],
  badges: [],
  disabled: false,
  contentRight: null
};

export default EntityInfo;
