import {put, takeLatest} from 'redux-saga/effects';

import {API} from '../action-types';

import {
  user as userActions
} from '../actions';

function * doLogout() {
  yield put(userActions.clear());
}

export default function * logout() {
  yield takeLatest(API.AUTH.LOGOUT.ACTION, doLogout);
}
