import React from 'react';
import * as PropTypes from 'prop-types';

import CampaignPreview from '../../common/campaign-preview';

import styles from './styles.less';

class CampaignPreviewPage extends React.Component {
  render() {
    const {campaign} = this.props;

    return (
      <div className={styles['page-content']}>
        {
          campaign ? (
            <CampaignPreview campaign={campaign} userClassName={styles['campaign-preview']}/>
          ) : (
            <div>Loading...</div>
          )
        }
      </div>
    );
  }
}

CampaignPreviewPage.propTypes = {
  campaign: PropTypes.object
};

CampaignPreviewPage.defaultProps = {
  campaign: null
};

export default CampaignPreviewPage;
