import * as firebase from 'firebase/app';
import React from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';
import uuid from 'uuid/v4';

import {deepCopy, getTimeString, getMsecsFromTimeString} from '../../../../lib/helper';

import Button from '../../../common/button';
import FileUpload from '../../../common/file-upload';
import Icon from '../../../common/icon';
import IconCheckboxGroup from '../../../common/icon-checkbox-group';
import OverlaySpinner from '../../../common/overlay-spinner';
import Slide from '../../../common/slide';

import styles from './styles.less';

const EDIT_MODES = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  LAYOUT: 'layout'
};

function _modifyLayoutTypeSingle(item, layoutType) {
  const layoutItem = item.layoutData[0];

  switch (layoutType) {
  case Slide.LAYOUT_TYPE.TWO_COLUMNS:
  case Slide.LAYOUT_TYPE.TWO_ROWS:
    item.layoutData.push(deepCopy(layoutItem));

    break;
  case Slide.LAYOUT_TYPE.THREE_THIRDS:
    item.layoutData.push(deepCopy(layoutItem), deepCopy(layoutItem));

    break;
  case Slide.LAYOUT_TYPE.FOUR_QUARTERS:
    item.layoutData.push(deepCopy(layoutItem), deepCopy(layoutItem), deepCopy(layoutItem));

    break;
  default:
    break;
  }
}

function _modifyLayoutTypeTwoColumns(item, layoutType) {
  switch (layoutType) {
  case Slide.LAYOUT_TYPE.SINGLE:
    item.layoutData.splice(1, 1);

    break;
  case Slide.LAYOUT_TYPE.THREE_THIRDS:
    item.layoutData.push(deepCopy(item.layoutData[1]));

    break;
  case Slide.LAYOUT_TYPE.FOUR_QUARTERS:
    item.layoutData.push(deepCopy(item.layoutData[0]), deepCopy(item.layoutData[1]));

    break;
  default:
    break;
  }
}

function _modifyLayoutTypeTwoRows(item, layoutType) {
  switch (layoutType) {
  case Slide.LAYOUT_TYPE.SINGLE:
    item.layoutData.splice(1, 1);

    break;
  case Slide.LAYOUT_TYPE.THREE_THIRDS:
    item.layoutData.splice(1, 0, deepCopy(item.layoutData[0]));

    break;
  case Slide.LAYOUT_TYPE.FOUR_QUARTERS:
    item.layoutData.splice(1, 0, deepCopy(item.layoutData[0]));
    item.layoutData.push(deepCopy(item.layoutData[1]));
    break;
  default:
    break;
  }
}

function _modifyLayoutTypeThreeThirds(item, layoutType) {
  switch (layoutType) {
  case Slide.LAYOUT_TYPE.SINGLE:
    item.layoutData.splice(1, 2);

    break;
  case Slide.LAYOUT_TYPE.TWO_COLUMNS:
    item.layoutData.splice(2, 1);

    break;
  case Slide.LAYOUT_TYPE.TWO_ROWS:
    item.layoutData.splice(1, 1);

    break;
  case Slide.LAYOUT_TYPE.FOUR_QUARTERS:
    item.layoutData.splice(2, 0, deepCopy(item.layoutData[0]));
    break;
  default:
    break;
  }
}

function _modifyLayoutTypeFourQuarters(item, layoutType) {
  switch (layoutType) {
  case Slide.LAYOUT_TYPE.SINGLE:
    item.layoutData.splice(1, 3);

    break;
  case Slide.LAYOUT_TYPE.TWO_COLUMNS:
    item.layoutData.splice(2, 2);

    break;
  case Slide.LAYOUT_TYPE.TWO_ROWS:
    item.layoutData.splice(3, 1);
    item.layoutData.splice(1, 1);

    break;
  case Slide.LAYOUT_TYPE.THREE_THIRDS:
    item.layoutData.splice(2, 1);

    break;
  default:
    break;
  }
}

function _modifyLayoutData(item, layoutType) {
  switch (item.layoutType) {
  case Slide.LAYOUT_TYPE.SINGLE:
    _modifyLayoutTypeSingle(item, layoutType);

    break;
  case Slide.LAYOUT_TYPE.TWO_COLUMNS:
    _modifyLayoutTypeTwoColumns(item, layoutType);

    break;
  case Slide.LAYOUT_TYPE.TWO_ROWS:
    _modifyLayoutTypeTwoRows(item, layoutType);

    break;
  case Slide.LAYOUT_TYPE.THREE_THIRDS:
    _modifyLayoutTypeThreeThirds(item, layoutType);

    break;
  case Slide.LAYOUT_TYPE.FOUR_QUARTERS:
    _modifyLayoutTypeFourQuarters(item, layoutType);

    break;
  default:
    break;
  }
}

function _mapEditMode(editMode) {
  switch (editMode) {
  case EDIT_MODES.TEXT:
    return Slide.EDIT_MODE.TEXT;
  case EDIT_MODES.LAYOUT:
  case EDIT_MODES.IMAGE:
    return Slide.EDIT_MODE.LAYOUT;
  default:
    return Slide.EDIT_MODE.NONE;
  }
}

const createUploadPathBackgroundAsset =
    campaign => name => `campaigns/${campaign.id}/background-assets/${uuid()}-${name}`;

const createUploadPathLayoutItemBackgroundAsset =
    campaign => name => `campaigns/${campaign.id}/layout-item-background-assets/${uuid()}-${name}`;

const createUploadPath = campaign => (name, type) => {
  return type.startsWith('video/') ?
    createUploadPathBackgroundAsset(campaign)(name) :
    createUploadPathLayoutItemBackgroundAsset(campaign)(name);
};

const EDIT_MODE_ITEMS = [{
  value: EDIT_MODES.LAYOUT,
  icon: Icon.TYPE.EDITOR_MODE_LAYOUT,
  tooltip: 'Layout'
}, {
  value: EDIT_MODES.TEXT,
  icon: Icon.TYPE.EDITOR_MODE_TEXT,
  tooltip: 'Text'
}, {
  value: EDIT_MODES.IMAGE,
  icon: Icon.TYPE.EDITOR_MODE_IMAGE,
  tooltip: 'Image'
}, {
  value: EDIT_MODES.VIDEO,
  icon: Icon.TYPE.EDITOR_MODE_VIDEO,
  tooltip: 'Video'
}];

const TEXT_ALIGNMENT_ITEMS = [{
  value: 'left',
  icon: Icon.TYPE.EDITOR_TEXT_ALIGN_LEFT
}, {
  value: 'center',
  icon: Icon.TYPE.EDITOR_TEXT_ALIGN_CENTER
}, {
  value: 'right',
  icon: Icon.TYPE.EDITOR_TEXT_ALIGN_RIGHT
}, {
  value: 'justify',
  icon: Icon.TYPE.EDITOR_TEXT_ALIGN_JUSTIFY
}];

const TEXT_STYLE_ITEMS = [{
  value: 'italic',
  icon: Icon.TYPE.EDITOR_TEXT_STYLE_ITALIC
}, {
  value: 'underlined',
  icon: Icon.TYPE.EDITOR_TEXT_STYLE_UNDERLINED
}, {
  value: 'strikeout',
  icon: Icon.TYPE.EDITOR_TEXT_STYLE_STRIKEOUT
}];

class Editor extends React.Component {
  static get EDIT_MODE() {
    return EDIT_MODES;
  }

  constructor(props) {
    super(props);

    this.state = {
      campaign: deepCopy(props.campaign),
      selectedSlideIndex: 0,
      selectedSlideIndices: [0],
      selectedTextIndex: -1,
      editMode: Editor.EDIT_MODE.LAYOUT,
      time: null,
      slideBackgroundColor: null,
      isDragging: false,
      isUploading: false
    };
  }

  render() {
    const {onCloseRequested} = this.props;
    const {campaign, selectedSlideIndex, selectedSlideIndices, editMode, isDragging, isUploading} = this.state;

    const {resolution, items} = campaign.data;
    const slide = (selectedSlideIndex >= 0) ? items[selectedSlideIndex] : null;

    const extentType = (resolution.width < resolution.height) ? Slide.EXTENT_TYPE.HEIGHT : Slide.EXTENT_TYPE.WIDTH;

    return (
      <div className={styles.editor}>
        <div className={styles.toolbar}>
          <div className={styles['campaign-name']}>{campaign.name}</div>
          <IconCheckboxGroup
            withBackground
            items={EDIT_MODE_ITEMS}
            selected={editMode}
            onChange={this._selectEditMode}
          />
          <div className={styles['action-container']}>
            <Button
              variant={Button.VARIANT.OUTLINED}
              color={Button.COLOR.SECONDARY}
              text="Cancel"
              onClick={onCloseRequested}
            />
            <Button text="Save" onClick={this._handleSave}/>
          </div>
        </div>
        <div className={styles.content}>
          <div
            className={styles.sidebar}
            onDragEnter={this._handleDragEnter}
            onDragOver={this._handleDragEnter}
            onDragExit={this._handleDragLeave}
            onDragLeave={this._handleDragLeave}
            onDrop={this._handleDrop}
          >
            <DragDropContext onDragEnd={this._handleDragEnd}>
              <Droppable droppableId="droppable">
                {
                  provided => (
                    <div ref={provided.innerRef} className={styles.slides} {...provided.droppableProps}>
                      {
                        items.map(this._renderSlide)
                      }
                      {provided.placeholder}
                    </div>
                  )
                }
              </Droppable>
            </DragDropContext>
            <div className={styles['sidebar-actions']}>
              {
                (editMode === EDIT_MODES.LAYOUT) && (
                  <div className={styles['sidebar-selection']}>
                    <div className={styles['select-all-button']} onClick={this._selectAll}>
                      <div className={styles['slide-checkbox']}>
                        <Icon
                          active={selectedSlideIndices.length === items.length}
                          disabled={selectedSlideIndices.length < items.length}
                          type={Icon.TYPE.EDITOR_MULTI_SELECT}
                        />
                      </div>
                      <div className={styles['select-all-button-text']}>Select All</div>
                    </div>
                    <div className={styles['deselect-all-button']} onClick={this._deselectAll}>Deselect All</div>
                  </div>
                )
              }
              <FileUpload
                multi
                createPath={createUploadPath(campaign)}
                accept="image/*, video/*"
                onUpload={this._handleUpload}
                onError={this._handleUploadError}
              >
                <Button
                  color={Button.COLOR.SECONDARY}
                  icon={Icon.TYPE.EDITOR_UPLOAD}
                  variant={Button.VARIANT.OUTLINED}
                />
              </FileUpload>
              <Button
                color={Button.COLOR.SECONDARY}
                variant={Button.VARIANT.OUTLINED}
                text="Add Slide"
                userStyle={{marginLeft: '16px', width: '100%'}}
                onClick={this._addSlide}
              />
            </div>
            {
              isDragging && (
                <div className={styles['sidebar-drag-mask']}>
                  <div className={styles['sidebar-drag-mask-content']}>
                    Drag & drop images or videos here to create multiple slides.
                  </div>
                </div>
              )
            }
          </div>
          <div className={styles['editing-area']}>
            {
              (selectedSlideIndices.length > 1) ? (
                <div className={styles['multiple-slides-text']}>Multiple Slides</div>
              ) : (
                <Slide
                  editable
                  editMode={_mapEditMode(editMode)}
                  extentType={extentType}
                  extentValue="100%"
                  resolution={resolution}
                  backgroundColor={slide.backgroundColor}
                  backgroundAsset={slide.backgroundAsset}
                  layoutType={slide.layoutType}
                  layoutData={slide.layoutData}
                  texts={slide.texts}
                  isUploading={isUploading}
                  onChange={this._handleSlideChange}
                  onTextFocus={this._handleTextFocus}
                  onUploadRequested={this._handleUploadRequested}
                />
              )
            }
          </div>
          <div className={styles.settings}>
            {
              this._renderSettings()
            }
          </div>
        </div>
        <OverlaySpinner text="Uploading..." visible={isUploading}/>
      </div>
    );
  }

  _renderSlide = ({backgroundColor, backgroundAsset, layoutType, layoutData, texts, time}, index) => {
    const {campaign, editMode, selectedSlideIndices} = this.state;

    const isSelected = selectedSlideIndices.includes(index);

    const slideWrapperClassNames = classNames({
      [styles['slide-wrapper']]: true,
      [styles['slide-wrapper-selected']]: isSelected
    });

    const slideCheckboxClassNames = classNames({
      [styles['slide-checkbox']]: true,
      [styles['slide-checkbox-disabled']]: isSelected && (selectedSlideIndices.length < 2)
    });

    return (
      <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
        {
          provided => (
            <div
              key={index}
              ref={provided.innerRef}
              className={slideWrapperClassNames}
              onClick={this._selectSlide(index)}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Slide
                extentType={Slide.EXTENT_TYPE.WIDTH}
                extentValue="100%"
                resolution={campaign.data.resolution}
                backgroundColor={backgroundColor}
                backgroundAsset={backgroundAsset}
                layoutType={layoutType}
                layoutData={layoutData}
                texts={texts}
              />
              <div className={styles['slide-number']}>{index + 1}</div>
              <div className={styles['slide-time']}>{getTimeString(time).replace(/^00:/, '')}</div>
              {
                isSelected && (
                  <div className={styles['selected-slide-border']}/>
                )
              }
              {
                (editMode === EDIT_MODES.LAYOUT) && (
                  <div
                    className={slideCheckboxClassNames}
                    onClick={(!isSelected || (selectedSlideIndices.length > 1)) ? this._toggleSlide(index) : null}
                  >
                    <Icon active={isSelected} type={Icon.TYPE.EDITOR_MULTI_SELECT}/>
                  </div>
                )
              }
            </div>
          )
        }
      </Draggable>
    );
  };

  _toggleSlide = index => e => {
    e.stopPropagation();

    this.setState(({selectedSlideIndex, selectedSlideIndices}) => {
      const list = [...selectedSlideIndices];

      const ind = list.indexOf(index);

      if (ind >= 0) {
        list.splice(ind, 1);
      } else {
        list.push(index);
      }

      return {
        selectedSlideIndex: (index === selectedSlideIndex) ? list[0] : selectedSlideIndex,
        selectedSlideIndices: list
      };
    });
  };

  _renderSettings() {
    const {editMode} = this.state;

    switch (editMode) {
    case Editor.EDIT_MODE.TEXT:
      return this._renderSettingsText();
    case Editor.EDIT_MODE.IMAGE:
      return this._renderSettingsImage();
    case Editor.EDIT_MODE.VIDEO:
      return this._renderSettingsVideo();
    case Editor.EDIT_MODE.LAYOUT:
      return this._renderSettingsLayout();
    default:
      return null;
    }
  }

  _renderSettingsText() {
    const {campaign, selectedSlideIndex, selectedTextIndex} = this.state;

    const {texts} = campaign.data.items[selectedSlideIndex];

    const text = (selectedTextIndex >= 0) && texts[selectedTextIndex];

    const inputColorSampleStyle = text && {backgroundColor: text.color};

    return (
      <>
        <div className={styles['settings-panel']}>
          <div className={styles['settings-panel-header']}>
            Edit Text
          </div>
          <div className={styles['settings-panel-row']}>
            <Button
              disabled={selectedTextIndex < 0}
              variant={Button.VARIANT.OUTLINED}
              color={Button.COLOR.SECONDARY}
              text="Delete Text"
              onClick={this._removeText}
            />
            <Button text="Add Text" onClick={this._addText}/>
          </div>
        </div>
        {
          text && (
            <>
              <div className={styles['settings-panel']}>
                <div className={styles['settings-panel-row']}>
                  <Button
                    disabled={selectedTextIndex <= 0}
                    variant={Button.VARIANT.OUTLINED}
                    color={Button.COLOR.SECONDARY}
                    text="Backward"
                    onClick={this._moveTextBackward}
                  />
                  <Button
                    disabled={(selectedTextIndex < 0) || (selectedTextIndex >= (texts.length - 1))}
                    variant={Button.VARIANT.OUTLINED}
                    color={Button.COLOR.SECONDARY}
                    text="Forward"
                    onClick={this._moveTextForward}
                  />
                </div>
                <div className={styles['settings-panel-row']}>
                  <div className={styles['settings-label']}>Position</div>
                  <div className={styles['settings-value-wrapper']}>
                    <input
                      type="number"
                      step="0.1"
                      value={(text.position.x >= 0) ? text.position.x.toString() : ''}
                      className={`${styles['settings-input']} ${styles['settings-input-small']} ${styles['settings-input-with-adornment']}`}
                      onChange={this._handleSlideTextChange('position', (x, old) => ({...old, x: Math.round(Number(x))}))}
                    />
                    <input
                      type="number"
                      step="0.1"
                      value={(text.position.y >= 0) ? text.position.y.toString() : ''}
                      className={`${styles['settings-input']} ${styles['settings-input-small']} ${styles['settings-input-with-adornment']}`}
                      onChange={this._handleSlideTextChange('position', (y, old) => ({...old, y: Math.round(Number(y))}))}
                    />
                    <div className={`${styles['settings-input-adornment']} ${styles['settings-input-adornment-left']}`}>
                      X
                    </div>
                    <div className={styles['settings-input-adornment']}>
                      Y
                    </div>
                  </div>
                </div>
                <div className={styles['settings-panel-row']}>
                  <div className={styles['settings-label']}>Size</div>
                  <div className={styles['settings-value-wrapper']}>
                    <input
                      type="number"
                      step="0.1"
                      value={(text.dimensions.width >= 0) ? text.dimensions.width.toString() : ''}
                      className={`${styles['settings-input']} ${styles['settings-input-small']} ${styles['settings-input-with-adornment']}`}
                      onChange={this._handleSlideTextChange('dimensions', (width, old) => ({...old, width: Math.round(Number(width))}))}
                    />
                    <input
                      type="number"
                      step="0.1"
                      value={(text.dimensions.height >= 0) ? text.dimensions.height.toString() : ''}
                      className={`${styles['settings-input']} ${styles['settings-input-small']} ${styles['settings-input-with-adornment']}`}
                      onChange={this._handleSlideTextChange('dimensions', (height, old) => ({...old, height: Math.round(Number(height))}))}
                    />
                    <div className={`${styles['settings-input-adornment']} ${styles['settings-input-adornment-left']}`}>
                      W
                    </div>
                    <div className={styles['settings-input-adornment']}>
                      H
                    </div>
                  </div>
                </div>
                <div className={styles['settings-panel-row']}>
                  <div className={styles['settings-label']}>Opacity</div>
                  <div className={styles['settings-value-wrapper']}>
                    <input
                      type="number"
                      step="0.1"
                      value={text.opacity ? text.opacity.toString() : ''}
                      className={`${styles['settings-input']} ${styles['settings-input-small']} ${styles['settings-input-with-adornment']}`}
                      onChange={this._handleSlideTextChange('opacity', value => Number(value))}
                    />
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={text.opacity}
                      className={`${styles['settings-input']} ${styles['settings-input-small']}`}
                      onChange={this._handleSlideTextChange('opacity', value => Number(value))}
                    />
                    <div className={`${styles['settings-input-adornment']} ${styles['settings-input-adornment-left']}`}>
                      %
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles['settings-panel']}>
                <div className={styles['settings-panel-row']}>
                  <div className={styles['settings-label']}>Font</div>
                  <div className={styles['settings-value-wrapper']}>
                    <select
                      className={styles['settings-select']}
                      value={text.font}
                      onChange={this._handleSlideTextChange('font')}
                    >
                      <option value="arial" className={styles['settings-select-option']}>
                        Arial
                      </option>
                      <option value="helvetica" className={styles['settings-select-option']}>
                        Helvetica
                      </option>
                      <option value="times_new_roman" className={styles['settings-select-option']}>
                        Times New Roman
                      </option>
                      <option value="times" className={styles['settings-select-option']}>
                        Times
                      </option>
                      <option value="courier_new" className={styles['settings-select-option']}>
                        Courier New
                      </option>
                      <option value="courier" className={styles['settings-select-option']}>
                        Courier
                      </option>
                      <option value="verdana" className={styles['settings-select-option']}>
                        Verdana
                      </option>
                      <option value="georgia" className={styles['settings-select-option']}>
                        Georgia
                      </option>
                      <option value="palatino" className={styles['settings-select-option']}>
                        Palatino
                      </option>
                      <option value="garamond" className={styles['settings-select-option']}>
                        Garamond
                      </option>
                      <option value="bookman" className={styles['settings-select-option']}>
                        Bookman
                      </option>
                      <option value="comic_sans_ms" className={styles['settings-select-option']}>
                        Comic Sans MS
                      </option>
                      <option value="trebuchet_ms" className={styles['settings-select-option']}>
                        Trebuchet MS
                      </option>
                      <option value="arial_black" className={styles['settings-select-option']}>
                        Arial Black
                      </option>
                      <option value="impact" className={styles['settings-select-option']}>
                        Impact
                      </option>
                    </select>
                  </div>
                </div>
                <div className={styles['settings-panel-row']}>
                  <div className={styles['settings-label']}>Weight</div>
                  <div className={styles['settings-value-wrapper']}>
                    <select
                      className={styles['settings-select']}
                      value={text.weight}
                      onChange={this._handleSlideTextChange('weight')}
                    >
                      <option value="100" className={styles['settings-select-option']}>
                        Thin
                      </option>
                      <option value="200" className={styles['settings-select-option']}>
                        Extra-Light
                      </option>
                      <option value="300" className={styles['settings-select-option']}>
                        Light
                      </option>
                      <option value="400" className={styles['settings-select-option']}>
                        Regular
                      </option>
                      <option value="500" className={styles['settings-select-option']}>
                        Medium
                      </option>
                      <option value="600" className={styles['settings-select-option']}>
                        Semi-Bold
                      </option>
                      <option value="700" className={styles['settings-select-option']}>
                        Bold
                      </option>
                      <option value="800" className={styles['settings-select-option']}>
                        Extra-Bold
                      </option>
                      <option value="900" className={styles['settings-select-option']}>
                        Black
                      </option>
                    </select>
                  </div>
                </div>
                <div className={styles['settings-panel-row']}>
                  <div className={styles['settings-label']}>Size</div>
                  <div className={styles['settings-value-wrapper']}>
                    <input
                      type="number"
                      step="0.1"
                      value={text.size ? text.size.toString() : ''}
                      className={`${styles['settings-input']} ${styles['settings-input-with-adornment']}`}
                      onChange={this._handleSlideTextChange('size', value => Math.round(Number(value)))}
                    />
                    <div className={styles['settings-input-adornment']}>
                      PX
                    </div>
                  </div>
                </div>
                <div className={styles['settings-panel-row']}>
                  <div className={styles['settings-label']}>Color</div>
                  <div className={styles['settings-value-wrapper']}>
                    <input
                      className={`${styles['settings-input']} ${styles['settings-input-color']}`}
                      value={text.color}
                      onChange={this._handleSlideTextChange('color')}
                    />
                    <div className={styles['settings-input-color-sample']} style={inputColorSampleStyle}/>
                  </div>
                </div>
                <div className={styles['settings-panel-row']}>
                  <div className={styles['settings-label']}>Align</div>
                  <div className={styles['settings-value-wrapper']}>
                    <IconCheckboxGroup
                      items={TEXT_ALIGNMENT_ITEMS}
                      selected={text.alignment}
                      onChange={this._handleSlideTextChange('alignment', value => value, value => value)}
                    />
                  </div>
                </div>
                <div className={styles['settings-panel-row']}>
                  <div className={styles['settings-label']}>Style</div>
                  <div className={styles['settings-value-wrapper']}>
                    <IconCheckboxGroup
                      multiSelect
                      items={TEXT_STYLE_ITEMS}
                      selected={text.styles}
                      onChange={this._handleSlideTextChange('styles', value => value, value => value)}
                    />
                  </div>
                </div>
              </div>
            </>
          )
        }
      </>
    );
  }

  _renderSettingsImage() {
    const {campaign, selectedSlideIndex} = this.state;

    const slide = campaign.data.items[selectedSlideIndex];

    return (
      <>
        {
          slide.layoutData.map(this._renderLayoutDataItemSettingsRow)
        }
      </>
    );
  }

  _renderSettingsVideo() {
    const {campaign, selectedSlideIndex} = this.state;

    const {backgroundAsset} = campaign.data.items[selectedSlideIndex];

    return (
      <>
        <div className={styles['settings-panel']}>
          <div className={styles['settings-panel-header']}>
            Background Video
          </div>
          <div className={styles['settings-panel-row']}>
            <div className={styles['settings-label']}>Video</div>
            <div className={`${styles['settings-value-wrapper']} ${styles['settings-value-wrapper-space']}`}>
              <Button
                disabled={!backgroundAsset}
                variant={Button.VARIANT.OUTLINED}
                color={Button.COLOR.SECONDARY}
                size={Button.SIZE.COMPACT}
                text="Clear"
                onClick={this._handleBackgroundVideoClear}
              />
              <FileUpload
                createPath={createUploadPathBackgroundAsset(campaign)}
                accept="video/*"
                onUpload={this._handleBackgroundVideoSelect}
                onError={this._handleUploadError}
              >
                <Button color={Button.COLOR.PRIMARY} size={Button.SIZE.COMPACT} text="Upload"/>
              </FileUpload>
            </div>
          </div>
          {
            backgroundAsset && (
              <>
                <div className={styles['settings-panel-row']}>
                  <div className={styles['settings-label']}>Resize</div>
                  <div className={styles['settings-value-wrapper']}>
                    <select
                      className={styles['settings-select']}
                      value={backgroundAsset.resizeMode}
                      onChange={this._handleSlideBackgroundAssetChange('resizeMode')}
                    >
                      <option
                        value={Slide.BACKGROUND_ASSET_RESIZE_MODE.CONTAIN}
                        className={styles['settings-select-option']}
                      >
                        Contain
                      </option>
                      <option
                        value={Slide.BACKGROUND_ASSET_RESIZE_MODE.COVER}
                        className={styles['settings-select-option']}
                      >
                        Cover
                      </option>
                    </select>
                  </div>
                </div>
              </>
            )
          }
        </div>
      </>
    );
  }

  _renderSettingsLayout() {
    const {campaign, time, slideBackgroundColor, selectedSlideIndex, selectedSlideIndices} = this.state;

    const slide = campaign.data.items[selectedSlideIndex];
    const differentColors = (selectedSlideIndices.length > 1) && ((new Set(selectedSlideIndices
      .map(index => campaign.data.items[index].backgroundColor))).size > 1);
    const inputColorSampleStyle = {
      backgroundColor: (slideBackgroundColor === null) ? slide.backgroundColor : slideBackgroundColor
    };

    return (
      <>
        <div className={styles['settings-panel']}>
          <div className={styles['settings-panel-header']}>
            Slide Timer
          </div>
          <div className={styles['settings-panel-row']}>
            <div className={styles['settings-label']}>Time</div>
            <div className={styles['settings-value-wrapper']}>
              <input
                className={styles['settings-input']}
                value={(time === null) ? getTimeString(slide.time) : time}
                onChange={this._handleSlideTimeChange}
                onBlur={this._handleSlideTimeBlur}
              />
            </div>
          </div>
        </div>
        <div className={styles['settings-panel']}>
          <div className={styles['settings-panel-header']}>
            Background
          </div>
          <div className={styles['settings-panel-row']}>
            <div className={styles['settings-label']}>Color</div>
            <div className={styles['settings-value-wrapper']}>
              <input
                className={`${styles['settings-input']} ${styles['settings-input-color']}`}
                value={
                  (slideBackgroundColor === null) ?
                    (differentColors ? '' : slide.backgroundColor) :
                    slideBackgroundColor
                }
                onBlur={this._handleSlideBackgroundColorBlur}
                onChange={this._handleSlideBackgroundColorChange}
              />
              {
                differentColors ? (
                  <div
                    className={styles['settings-input-color-sample']}
                    style={{
                      backgroundColor: '#222128',
                      border: '1px solid rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <div className={styles['settings-input-color-sample-dots-container']}>
                      <div className={styles['settings-input-color-sample-dot']}/>
                      <div className={styles['settings-input-color-sample-dot']}/>
                      <div className={styles['settings-input-color-sample-dot']}/>
                    </div>
                  </div>
                ) : (
                  <div className={styles['settings-input-color-sample']} style={inputColorSampleStyle}/>
                )
              }
            </div>
          </div>
        </div>
        {
          (selectedSlideIndices.length === 1) && (
            <div className={styles['settings-panel']}>
              <div className={styles['settings-panel-header']}>
                Layout
              </div>
              <div className={styles['settings-panel-row']}>
                <div className={styles['settings-label']}>Type</div>
                <div className={styles['settings-value-wrapper']}>
                  <select
                    className={styles['settings-select']}
                    value={slide.layoutType}
                    onChange={this._handleSlideLayoutTypeChange}
                  >
                    <option value={Slide.LAYOUT_TYPE.SINGLE} className={styles['settings-select-option']}>Single</option>
                    <option
                      value={Slide.LAYOUT_TYPE.TWO_COLUMNS}
                      className={styles['settings-select-option']}
                    >
                      Two Columns
                    </option>
                    <option
                      value={Slide.LAYOUT_TYPE.TWO_ROWS}
                      className={styles['settings-select-option']}
                    >
                      Two Rows
                    </option>
                    <option
                      value={Slide.LAYOUT_TYPE.THREE_THIRDS}
                      className={styles['settings-select-option']}
                    >
                      Three Thirds
                    </option>
                    <option
                      value={Slide.LAYOUT_TYPE.FOUR_QUARTERS}
                      className={styles['settings-select-option']}
                    >
                      Four Quarters
                    </option>
                  </select>
                </div>
              </div>
            </div>
          )
        }
        <div className={styles['settings-panel']}>
          <div className={styles['settings-panel-row']}>
            <Button
              disabled={
                (campaign.data.items.length <= 1) ||
                (selectedSlideIndices.length >= campaign.data.items.length)
              }
              variant={Button.VARIANT.OUTLINED}
              color={Button.COLOR.SECONDARY}
              text="Delete Slide(s)"
              onClick={this._removeSlide}
            />
          </div>
        </div>
      </>
    );
  }

  _renderLayoutDataItemSettingsRow = ({backgroundAsset}, index) => {
    const {campaign} = this.state;

    return (
      <div key={index} className={styles['settings-panel']}>
        <div className={styles['settings-panel-header']}>
          {`Layout item ${index + 1}`}
        </div>
        <div className={styles['settings-panel-row']}>
          <div className={styles['settings-label']}>Image</div>
          <div className={`${styles['settings-value-wrapper']} ${styles['settings-value-wrapper-space']}`}>
            <Button
              disabled={!backgroundAsset}
              variant={Button.VARIANT.OUTLINED}
              color={Button.COLOR.SECONDARY}
              size={Button.SIZE.COMPACT}
              text="Clear"
              onClick={this._handleSlideLayoutDataItemBackgroundImageClear(index)}
            />
            <FileUpload
              createPath={name => `campaigns/${campaign.id}/layout-item-background-assets/${uuid()}-${name}`}
              accept="image/*"
              onUpload={this._handleSlideLayoutDataItemBackgroundImageSelect(index)}
              onError={this._handleUploadError}
            >
              <Button color={Button.COLOR.PRIMARY} size={Button.SIZE.COMPACT} text="Upload"/>
            </FileUpload>
          </div>
        </div>
        {
          backgroundAsset && (
            <>
              <div className={styles['settings-panel-row']}>
                <div className={styles['settings-label']}>Resize</div>
                <div className={styles['settings-value-wrapper']}>
                  <select
                    className={styles['settings-select']}
                    value={backgroundAsset.resizeMode}
                    onChange={this._handleSlideLayoutDataItemBackgroundAssetChange(index, 'resizeMode')}
                  >
                    <option
                      value={Slide.BACKGROUND_ASSET_RESIZE_MODE.CONTAIN}
                      className={styles['settings-select-option']}
                    >
                      Contain
                    </option>
                    <option
                      value={Slide.BACKGROUND_ASSET_RESIZE_MODE.COVER}
                      className={styles['settings-select-option']}
                    >
                      Cover
                    </option>
                  </select>
                </div>
              </div>
            </>
          )
        }
      </div>
    );
  };

  _handleDragEnd = result => {
    if (!result.destination) {
      return;
    }

    this.setState(({campaign}) => {
      const items = [...campaign.data.items];

      const [removed] = items.splice(result.source.index, 1);

      items.splice(result.destination.index, 0, removed);

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        },
        selectedSlideIndex: result.destination.index
      };
    });
  };

  _selectEditMode = mode => {
    this.setState(state => ({
      editMode: mode,
      selectedTextIndex: -1,
      selectedSlideIndices: [state.selectedSlideIndex]
    }));
  };

  _selectSlide = index => () => {
    this.setState(state => {
      if (state.selectedSlideIndex === index) {
        return state;
      }

      return {
        selectedSlideIndex: index,
        selectedSlideIndices: [index],
        editMode: EDIT_MODES.LAYOUT,
        selectedTextIndex: -1,
        time: null,
        slideBackgroundColor: null
      };
    });
  };

  _addSlide = () => {
    this.setState(({campaign, selectedSlideIndices}) => {
      const items = [...campaign.data.items, {
        backgroundColor: '#fff',
        layoutType: 'single',
        layoutData: [{
          backgroundAsset: null
        }],
        texts: [],
        time: 90 * 1000
      }];

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        },
        selectedSlideIndex: items.length - 1,
        selectedSlideIndices: [...selectedSlideIndices, items.length - 1],
        editMode: EDIT_MODES.LAYOUT
      };
    });
  };

  _handleSlideChange = ({texts}) => {
    this.setState(({campaign, selectedSlideIndex}) => {
      const items = [...campaign.data.items];

      items[selectedSlideIndex].texts = texts;

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        }
      };
    });
  };

  _handleTextFocus = index => {
    this.setState({selectedTextIndex: index});
  };

  _handleUploadRequested = (file, uploadType, layoutItemIndex) => {
    const {campaign} = this.state;

    const isBackgroundAsset = uploadType === 'background-asset';

    const createPath = isBackgroundAsset ? createUploadPathBackgroundAsset : createUploadPathLayoutItemBackgroundAsset;

    this.setState({isUploading: true}, async () => {
      try {
        const ref = firebase.storage()
          .ref()
          .child(createPath(campaign)(file));

        await ref.put(file);

        if (isBackgroundAsset) {
          await this._handleBackgroundVideoSelect(ref);
        } else {
          await this._handleSlideLayoutDataItemBackgroundImageSelect(layoutItemIndex)(ref);
        }
      } catch (err) {
        console.warn(err.message);

        this._handleUploadError(err);
      }

      this.setState({isUploading: false});
    });
  };

  _removeText = () => {
    this.setState(({campaign, selectedSlideIndex, selectedTextIndex}) => {
      const items = [...campaign.data.items];

      const {texts} = items[selectedSlideIndex];

      texts.splice(selectedTextIndex, 1);

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        },
        selectedTextIndex: -1
      };
    });
  };

  _addText = () => {
    this.setState(({campaign, selectedSlideIndex}) => {
      const {width, height} = campaign.data.resolution;

      const items = [...campaign.data.items];

      const {texts} = items[selectedSlideIndex];

      texts.push({
        text: '',
        color: '#000000',
        size: 60,
        font: 'arial',
        weight: '400',
        alignment: 'left',
        opacity: 100,
        styles: [],
        position: {
          x: Math.round((width - 400) / 2),
          y: Math.round((height - 100) / 2)
        },
        dimensions: {
          width: 400,
          height: 100
        }
      });

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        },
        selectedTextIndex: texts.length - 1
      };
    });
  };

  _moveTextBackward = () => {
    this.setState(({campaign, selectedSlideIndex, selectedTextIndex}) => {
      const items = [...campaign.data.items];

      const {texts} = items[selectedSlideIndex];

      const [text] = texts.splice(selectedTextIndex, 1);
      texts.splice(selectedSlideIndex - 1, 0, text);

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        },
        selectedTextIndex: selectedTextIndex - 1
      };
    });
  };

  _moveTextForward = () => {
    this.setState(({campaign, selectedSlideIndex, selectedTextIndex}) => {
      const items = [...campaign.data.items];

      const {texts} = items[selectedSlideIndex];

      const [text] = texts.splice(selectedTextIndex, 1);
      texts.splice(selectedSlideIndex + 1, 0, text);

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        },
        selectedTextIndex: selectedTextIndex + 1
      };
    });
  };

  _handleSlideTextChange = (propName, getter = value => value, valueGetter = e => e.target.value) => e => {
    const v = valueGetter(e);

    this.setState(({campaign, selectedSlideIndex, selectedTextIndex}) => {
      const items = [...campaign.data.items];

      const text = items[selectedSlideIndex].texts[selectedTextIndex];
      text[propName] = getter(v, text[propName]);

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        }
      };
    });
  };

  _handleSlideTimeChange = e => {
    const timeString = e.target.value;
    const timeValue = getMsecsFromTimeString(timeString);

    this.setState(({campaign, selectedSlideIndices}) => {
      const items = [...campaign.data.items];

      selectedSlideIndices.forEach(index => {
        items[index].time = timeValue;
      });

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        },
        time: timeString
      };
    });
  };

  _handleSlideTimeBlur = () => {
    this.setState({time: null});
  };

  _handleSlideBackgroundColorBlur = () => {
    this.setState(({slideBackgroundColor, campaign, selectedSlideIndices}) => {
      if (!slideBackgroundColor) {
        return {};
      }

      const items = [...campaign.data.items];

      selectedSlideIndices.forEach(index => {
        items[index].backgroundColor = slideBackgroundColor;
      });

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        },
        slideBackgroundColor: null
      };
    });
  };

  _handleSlideBackgroundColorChange = e => {
    const backgroundColor = e.target.value;

    this.setState({slideBackgroundColor: backgroundColor});
  };

  _handleSlideLayoutTypeChange = e => {
    const layoutType = e.target.value;

    this.setState(({campaign, selectedSlideIndices}) => {
      const items = [...campaign.data.items];

      selectedSlideIndices.forEach(index => {
        const item = items[index];

        _modifyLayoutData(item, layoutType);

        item.layoutType = layoutType;
      });

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        }
      };
    });
  };

  _removeSlide = () => {
    this.setState(({campaign, selectedSlideIndices, editMode}) => {
      const items = [...campaign.data.items];

      selectedSlideIndices
        .sort((i1, i2) => i2 - i1)
        .forEach(index => {
          items.splice(index, 1);
        });

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        },
        selectedSlideIndex: (items.length > 0) ? 0 : -1,
        selectedSlideIndices: (items.length > 0) ? [0] : [],
        editMode: (items.length > 0) ? editMode : EDIT_MODES.LAYOUT
      };
    });
  };

  _selectAll = () => {
    this.setState(({campaign}) => ({
      selectedSlideIndices: campaign.data.items.map((_, index) => index)
    }));
  };

  _deselectAll = () => {
    this.setState(({selectedSlideIndex}) => ({
      selectedSlideIndices: [selectedSlideIndex]
    }));
  };

  _handleBackgroundVideoClear = () => {
    this.setState(({campaign, selectedSlideIndex}) => {
      const items = [...campaign.data.items];

      items[selectedSlideIndex].backgroundAsset = null;

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        }
      };
    });
  };

  _handleDragEnter = e => {
    e.preventDefault();

    if (this.state.isUploading) {
      return;
    }

    this.setState({isDragging: true});
  };

  _handleDragLeave = e => {
    e.preventDefault();

    if (this.state.isUploading) {
      return;
    }

    this.setState({isDragging: false});
  };

  _handleDrop = e => {
    const {campaign} = this.state;

    const files = [].filter.call(
      e.dataTransfer.files,
      ({type}) => type.startsWith('image/') || type.startsWith('video/')
    );

    e.preventDefault();

    if (this.state.isUploading) {
      return;
    }

    this.setState({
      isDragging: false,
      isUploading: true
    }, async () => {
      try {
        for (const file of files) {
          /* eslint-disable no-await-in-loop */
          const ref = firebase.storage()
            .ref()
            .child(createUploadPath(campaign)(file.name, file.type));

          await ref.put(file);

          await this._handleUpload(ref, file.type);
          /* eslint-enable no-await-in-loop */
        }
      } catch (err) {
        console.warn(err.message);

        this._handleUploadError(err);
      }

      this.setState({isUploading: false});
    });
  };

  _handleUploadError = err => {
    // eslint-disable-next-line no-alert
    alert(err.message);
  };

  _handleUpload = async (ref, type) => {
    const url = await ref.getDownloadURL();

    this.setState(({campaign}) => {
      const slide = {
        backgroundColor: '#fff',
        layoutType: 'single',
        layoutData: [{
          backgroundAsset: null // TODO
        }],
        texts: [],
        time: 90 * 1000
      };

      if (type.startsWith('video/')) {
        slide.backgroundAsset = {
          url,
          type: Slide.BACKGROUND_ASSET_TYPE.VIDEO,
          resizeMode: Slide.BACKGROUND_ASSET_RESIZE_MODE.CONTAIN
        };
      } else {
        slide.layoutData[0].backgroundAsset = {
          url,
          type: Slide.BACKGROUND_ASSET_TYPE.IMAGE,
          resizeMode: Slide.BACKGROUND_ASSET_RESIZE_MODE.CONTAIN
        };
      }

      const items = [...campaign.data.items, slide];

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        },
        selectedSlideIndex: items.length - 1,
        selectedSlideIndices: [items.length - 1],
        editMode: EDIT_MODES.LAYOUT
      };
    });
  };

  _handleBackgroundVideoSelect = async ref => {
    const url = await ref.getDownloadURL();

    this.setState(({campaign, selectedSlideIndex}) => {
      const items = [...campaign.data.items];

      const slide = items[selectedSlideIndex];

      if (!slide.backgroundAsset) {
        slide.backgroundAsset = {
          type: Slide.BACKGROUND_ASSET_TYPE.VIDEO,
          url: '',
          resizeMode: Slide.BACKGROUND_ASSET_RESIZE_MODE.CONTAIN
        };
      }

      slide.backgroundAsset.url = url;

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        }
      };
    });
  };

  _handleSlideBackgroundAssetChange = propName => e => {
    const value = e.target.value;

    this.setState(({campaign, selectedSlideIndex}) => {
      const items = [...campaign.data.items];

      const slide = items[selectedSlideIndex];

      if (value === '-') {
        slide.backgroundAsset = null;
      } else {
        if (!slide.backgroundAsset) {
          slide.backgroundAsset = {
            type: Slide.BACKGROUND_ASSET_TYPE.VIDEO,
            url: '',
            resizeMode: Slide.BACKGROUND_ASSET_RESIZE_MODE.CONTAIN
          };
        }

        slide.backgroundAsset[propName] = value;
      }

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        }
      };
    });
  };

  _handleSlideLayoutDataItemBackgroundImageClear = index => () => {
    this.setState(({campaign, selectedSlideIndex}) => {
      const items = [...campaign.data.items];

      const layoutItem = items[selectedSlideIndex].layoutData[index];
      layoutItem.backgroundAsset = null;

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        }
      };
    });
  };

  _handleSlideLayoutDataItemBackgroundImageSelect = index => async ref => {
    const url = await ref.getDownloadURL();

    this.setState(({campaign, selectedSlideIndex}) => {
      const items = [...campaign.data.items];

      const layoutItem = items[selectedSlideIndex].layoutData[index];

      if (!layoutItem.backgroundAsset) {
        layoutItem.backgroundAsset = {
          type: Slide.BACKGROUND_ASSET_TYPE.IMAGE,
          url: '',
          resizeMode: Slide.BACKGROUND_ASSET_RESIZE_MODE.CONTAIN
        };
      }

      layoutItem.backgroundAsset.url = url;

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        }
      };
    });
  };

  _handleSlideLayoutDataItemBackgroundAssetChange = (index, propName) => e => {
    const value = e.target.value;

    this.setState(({campaign, selectedSlideIndex}) => {
      const items = [...campaign.data.items];

      const layoutItem = items[selectedSlideIndex].layoutData[index];

      if (value === '-') {
        layoutItem.backgroundAsset = null;
      } else {
        if (!layoutItem.backgroundAsset) {
          layoutItem.backgroundAsset = {
            type: Slide.BACKGROUND_ASSET_TYPE.IMAGE,
            url: '',
            resizeMode: Slide.BACKGROUND_ASSET_RESIZE_MODE.CONTAIN
          };
        }

        layoutItem.backgroundAsset[propName] = value;
      }

      return {
        campaign: {
          ...campaign,
          data: {
            ...campaign.data,
            items
          }
        }
      };
    });
  };

  _handleSave = () => {
    const {onCloseRequested, onSaveRequested} = this.props;
    const {campaign} = this.state;

    onSaveRequested(campaign).then(onCloseRequested);
  };
}

Editor.propTypes = {
  campaign: PropTypes.object.isRequired,
  onCloseRequested: PropTypes.func.isRequired,
  onSaveRequested: PropTypes.func.isRequired
};

export default Editor;
