import {createAction} from 'redux-actions';

import {SCHEDULES} from '../action-types';

export const addSingle = createAction(SCHEDULES.ADD_SINGLE.ACTION);
export const addList = createAction(SCHEDULES.ADD_LIST.ACTION);
export const clear = createAction(SCHEDULES.CLEAR.ACTION);
export const deleteSingle = createAction(SCHEDULES.DELETE_SINGLE.ACTION);
export const setFetched = createAction(SCHEDULES.SET_FETCHED.ACTION);
export const updateSingle = createAction(SCHEDULES.UPDATE_SINGLE.ACTION);
