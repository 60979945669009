import {defineAction} from 'redux-define';

const organisations = defineAction('organisations');

export const ADD_SINGLE = defineAction('ADD_SINGLE', organisations);
export const ADD_LIST = defineAction('ADD_LIST', organisations);
export const CLEAR = defineAction('CLEAR', organisations);
export const DELETE_SINGLE = defineAction('DELETE_SINGLE', organisations);
export const SET_FETCHED = defineAction('SET_FETCHED', organisations);
export const UPDATE_SINGLE = defineAction('UPDATE_SINGLE', organisations);
