import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import Icon from '../icon';
import MenuContainer from '../menu-container';
import TreeView from '../tree-view';

import styles from './styles.less';

function _getPath(folders, organisations, value) {
  if (!value) {
    return null;
  }

  let folder = folders
    .find(fld => fld.id === value);

  if (!folder) {
    folder = organisations.find(org => `organisation-${org.id}` === value);
  }

  const path = [folder.name];

  while (folder.parentId) {
    folder = folders.find(fld => fld.id === folder.parentId);

    path.unshift(folder.name);
  }

  if (organisations) {
    const organisation = organisations.find(org => org.id === folder.organisationId);

    if (organisation) {
      path.unshift(organisation.name);
    }
  }

  return path;
}

class FolderSelect extends React.Component {
  render() {
    const {userClassName, userStyle} = this.props;

    const wrapperClassNames = classNames(styles['select-wrapper'], userClassName);

    return (
      <MenuContainer
        renderButton={this._renderButton}
        renderMenu={this._renderMenu}
        userClassName={wrapperClassNames}
        userStyle={userStyle}
        menuWrapperUserClassName={styles['menu-wrapper']}
      />
    );
  }

  _renderButton = (toggle, setVisible, isMenuVisible) => {
    const {folders, organisations, value, placeholder} = this.props;

    const path = _getPath(folders, organisations, value);

    const buttonClassNames = classNames({
      [styles.button]: true,
      [styles['button-active']]: isMenuVisible
    });

    return (
      <div className={buttonClassNames} onClick={toggle}>
        {
          path ? this._renderPath(path) : placeholder
        }
      </div>
    );
  };

  _renderMenu = setVisible => {
    const {entityType, folders, organisations, value} = this.props;

    return (
      <TreeView
        folderSelectable
        entityType={entityType}
        folders={folders}
        organisations={organisations}
        defaultOpenedFolderId={value}
        userClassName={styles.menu}
        onFolderSelect={this._handleFolderSelect(setVisible)}
      />
    );
  };

  _renderPath(path) {
    return (
      <>
        {
          path
            .reduce((acc, part) => acc.concat(part, 'separator'), [])
            .slice(0, -1)
            .map(this._renderPathPart)
        }
      </>
    );
  }

  _renderPathPart = (part, index) => {
    if (part === 'separator') {
      return (
        <Icon key={index} type={Icon.TYPE.CHEVRON_RIGHT} userClassName={styles['path-part-separator']}/>
      );
    }

    return (
      <div key={index} className={styles['path-part']}>{part}</div>
    );
  };

  _handleFolderSelect = setVisible => folderId => {
    const {onChange} = this.props;

    setVisible(false, () => {
      if (onChange) {
        onChange(folderId);
      }
    });
  };
}

FolderSelect.propTypes = {
  entityType: PropTypes.oneOf(Object.values(TreeView.ENTITY_TYPE)).isRequired,
  folders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    organisationId: PropTypes.string.isRequired,
    parentId: PropTypes.string
  })).isRequired,
  organisations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onChange: PropTypes.func
};

FolderSelect.defaultProps = {
  organisations: null,
  value: null,
  placeholder: null,
  userClassName: null,
  userStyle: null,
  onChange: null
};

export default FolderSelect;
