exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1CurTB7J{width:100%;height:100%;-js-display:flex;display:flex;flex-direction:column}._1CurTB7J._3dDQHpcl{justify-content:center;align-items:center}._8JgVNS56{flex-grow:1}._7e9jgePL{width:100%;height:80px;-js-display:flex;display:flex;justify-content:space-between;align-items:center;padding:0 20px;border-top:2px solid rgba(77,35,66,.06)}._19ufg2xP{padding:0;min-height:400px}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,WACE,UAAW,CACX,WAAY,CACZ,gBAAa,CAAb,YAAa,CACb,qBACF,CACA,qBACE,sBAAuB,CACvB,kBACF,CACA,WACE,WACF,CACA,WACE,UAAW,CACX,WAAY,CACZ,gBAAa,CAAb,YAAa,CACb,6BAA8B,CAC9B,kBAAmB,CACnB,cAAe,CACf,uCACF,CACA,WACE,SAAU,CACV,gBACF","file":"styles.less","sourcesContent":[".folder-view {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.folder-view.folder-view-loading {\n  justify-content: center;\n  align-items: center;\n}\n.tree {\n  flex-grow: 1;\n}\n.actions {\n  width: 100%;\n  height: 80px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 20px;\n  border-top: 2px solid rgba(77, 35, 66, 0.06);\n}\n.folder-dialog-form {\n  padding: 0;\n  min-height: 400px;\n}\n"]}]);
// Exports
exports.locals = {
	"folder-view": "_1CurTB7J",
	"folder-view-loading": "_3dDQHpcl",
	"tree": "_8JgVNS56",
	"actions": "_7e9jgePL",
	"folder-dialog-form": "_19ufg2xP"
};