import {connect} from 'react-redux';

import Component from './component.jsx';

function mapStateToProps(state, ownProps) {
  const {items} = state.screens;
  const {screenId} = ownProps.match.params;

  return {
    screen: items.find(item => item.id === screenId)
  };
}

export default connect(
  mapStateToProps
)(Component);
