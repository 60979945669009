import {put, select, takeLatest} from 'redux-saga/effects';

import {API_INITIALIZED} from '../../lib/api';

import {API} from '../action-types';
import {api as apiActions} from '../actions';

function * fetchCommonData() {
  const user = yield select(state => state.user.user);

  if (!user) {
    return yield;
  }

  yield put(apiActions.user.getSelf({
    data: {
      uid: user.uid
    }
  }));
  yield put(apiActions.organisation.getSingle({
    data: {
      id: user.organisationId
    }
  }));
}

export default function * commonData() {
  yield takeLatest(API_INITIALIZED.ACTION, fetchCommonData);
  yield takeLatest(API.AUTH.LOGIN.SUCCESS, fetchCommonData);
  yield takeLatest(API.AUTH.REGISTER.SUCCESS, fetchCommonData);
}
