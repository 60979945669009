import * as PropTypes from 'prop-types';

import Component from './component.jsx';

export default Component;

export const DIALOG_SHAPE = {
  isDialogOpen: PropTypes.bool.isRequired,
  renderDialog: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  dialogData: PropTypes.any
};
