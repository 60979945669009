import * as firebase from 'firebase/app';

import {createApiAction} from '../../../lib/api';

import EntityType from '../../../models/entity-type';

import {API} from '../../action-types';

interface ICreateSingleData {
  organisationId: string;
  name: string;
  type: EntityType;
  parentId: string | null;
}

interface IDeleteSingleData {
  id: string;
}

interface IUpdateSingleData extends IDeleteSingleData, ICreateSingleData {}

export const createSingle = createApiAction(
  API.FOLDERS.CREATE_SINGLE.ACTION,
  async ({data}: {data: ICreateSingleData}) => {
    const folders = firebase.firestore().collection('folders');

    const folderRef = await folders.add({...data});

    return {
      item: {
        ...data,
        id: folderRef.id
      }
    };
  }
);

export const deleteSingle = createApiAction(
  API.FOLDERS.DELETE_SINGLE.ACTION,
  async ({data: {id}}: {data: IDeleteSingleData}) => {
    const folders = firebase.firestore().collection('folders');

    await folders.doc(id).delete();
  }
);

export const updateSingle = createApiAction(
  API.FOLDERS.UPDATE_SINGLE.ACTION,
  async ({data: {id, ...rest}}: {data: IUpdateSingleData}) => {
    const folders = firebase.firestore().collection('folders');

    await folders
      .doc(id)
      .update({...rest});
  }
);
