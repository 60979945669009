import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';
import {MD5 as md5} from 'object-hash';

import Slide from '../slide';

import styles from './styles.less';

class CampaignPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlideIndex: 0
    };
  }

  componentDidMount() {
    this._setTimer();
  }

  componentDidUpdate(prevProps) {
    if (md5(this.props.campaign.data) === md5(prevProps.campaign.data)) {
      return;
    }

    if (this._timer) {
      clearTimeout(this._timer);
      this._timer = null;
    }

    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({currentSlideIndex: 0}, this._setTimer);
  }

  componentWillUnmount() {
    if (this._timer) {
      clearTimeout(this._timer);
      this._timer = null;
    }
  }

  render() {
    const {campaign, userClassName, userStyle} = this.props;
    const {currentSlideIndex} = this.state;

    const {items} = campaign.data;

    const campaignPreviewClassNames = classNames({
      [styles['campaign-preview']]: true,
      [userClassName]: Boolean(userClassName)
    });

    return (
      <div className={campaignPreviewClassNames} style={userStyle}>
        {
          (items.length > currentSlideIndex) && this._renderSlide()
        }
      </div>
    );
  }

  _renderSlide() {
    const {campaign} = this.props;
    const {currentSlideIndex} = this.state;

    const {resolution, items} = campaign.data;
    const {backgroundColor, backgroundAsset, layoutType, layoutData, texts} = items[currentSlideIndex];

    return (
      <Slide
        extentType={Slide.EXTENT_TYPE.WIDTH}
        extentValue="100%"
        resolution={resolution}
        backgroundColor={backgroundColor}
        backgroundAsset={backgroundAsset}
        layoutType={layoutType}
        layoutData={layoutData}
        texts={texts}
      />
    );
  }

  _setTimer = () => {
    const {campaign} = this.props;
    const {currentSlideIndex} = this.state;

    this._timer = setTimeout(this._switchSlide, campaign.data.items[currentSlideIndex].time);
  };

  _switchSlide = () => {
    const {campaign} = this.props;
    const {currentSlideIndex} = this.state;

    const {items} = campaign.data;

    const nextSlideIndex = (currentSlideIndex >= (items.length - 1)) ? 0 : (currentSlideIndex + 1);

    this.setState({currentSlideIndex: nextSlideIndex}, this._setTimer);
  };
}

CampaignPreview.propTypes = {
  campaign: PropTypes.object.isRequired,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object
};

CampaignPreview.defaultProps = {
  userClassName: null,
  userStyle: null
};

export default CampaignPreview;
