import {createAction} from 'redux-actions';

import {ORGANISATIONS} from '../action-types';

export const addSingle = createAction(ORGANISATIONS.ADD_SINGLE.ACTION);
export const addList = createAction(ORGANISATIONS.ADD_LIST.ACTION);
export const clear = createAction(ORGANISATIONS.CLEAR.ACTION);
export const deleteSingle = createAction(ORGANISATIONS.DELETE_SINGLE.ACTION);
export const setFetched = createAction(ORGANISATIONS.SET_FETCHED.ACTION);
export const updateSingle = createAction(ORGANISATIONS.UPDATE_SINGLE.ACTION);
