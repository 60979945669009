import React from 'react';
import * as PropTypes from 'prop-types';

import {getLoginByEmailErrors} from '../../../lib/form/error-getters';
import {LOGIN_FORM_FIELDS} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';

import AuthPage from '../../common/auth-page';

class LoginPage extends React.Component {
  render() {
    const {isLoggingIn, login} = this.props;

    const formProps = {
      actionText: 'Log In',
      fields: LOGIN_FORM_FIELDS,
      disabled: isLoggingIn,
      onSubmit: login,
      onSubmitFailure: handleSubmitFailure(getLoginByEmailErrors)
    };
    const footer = {
      text: '',
      action: {
        text: 'Forgot your password?',
        href: '/reset-password'
      }
    };

    return (
      <AuthPage formProps={formProps} footer={footer}/>
    );
  }
}

LoginPage.propTypes = {
  isLoggingIn: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired
};

export default LoginPage;
