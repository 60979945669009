import {connect} from 'react-redux';

import Component from './component.jsx';

function mapStateToProps(state) {
  const {items: campaigns} = state.campaigns;
  const {items: schedules} = state.schedules;

  return {campaigns, schedules};
}

export default connect(
  mapStateToProps
)(Component);
