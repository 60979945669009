import {createReducer} from '@ololoepepe/redux-tools';

import {API} from '../../action-types';

interface IState {
  error: any;
  isLoggingIn: boolean;
}

const INITIAL_STATE: IState = {
  error: null,
  isLoggingIn: false
};

function pending(state: IState): IState {
  return {
    ...state,
    isLoggingIn: true
  };
}

function success(state: IState): IState {
  return {
    ...state,
    error: null,
    isLoggingIn: false
  };
}

function error(state: IState, {payload}): IState {
  return {
    ...state,
    error: payload.error,
    isLoggingIn: false
  };
}

export default createReducer(INITIAL_STATE, {
  [API.AUTH.LOGIN.PENDING]: pending,
  [API.AUTH.LOGIN.SUCCESS]: success,
  [API.AUTH.LOGIN.ERROR]: error
});
