import {connect} from 'react-redux';

import Component from './component.jsx';

function mapStateToProps(state, ownProps) {
  const {items} = state.campaigns;
  const {campaignId} = ownProps.match.params;

  return {
    campaign: items.find(item => item.id === campaignId)
  };
}

export default connect(
  mapStateToProps
)(Component);
