import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import {getCreateFolderFormFields} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';
import {getCreateFolderErrors} from '../../../lib/form/error-getters';

import Button from '../button';
import Form from '../form';
import Icon from '../icon';
import TreeView from '../tree-view';
import withDialog, {DIALOG_SHAPE} from '../with-dialog';

import styles from './styles.less';

function _getOrganisations(organisations, role) {
  return (role === 'root') ? organisations.items : null;
}

@withDialog({
  propName: 'createFolderDialog',
  submitButtonText: 'Create Folder'
})
class FolderView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      folderFormHasErrors: false,
      isCreatingFolder: false
    };
  }

  render() {
    const {
      createFolderDialog,
      entityType,
      createEntityText,
      folders,
      entities,
      organisations,
      role,
      selectedEntityId,
      alternateLiveEntityColor,
      userClassName,
      userStyle,
      deleteFolder,
      updateFolder,
      checkEntityLiveCallback,
      onEntitySelect,
      badgesDisabled
    } = this.props;

    const isLoading = folders.isFetching || entities.isFetching || ((role === 'root') && organisations.isFetching);
    const isReadOnly = (role === 'user') && (entityType === 'screen');

    const treeViewClassNames = classNames({
      [styles['folder-view']]: true,
      [styles['folder-view-loading']]: isLoading,
      [userClassName]: Boolean(userClassName)
    });

    return (
      <div className={treeViewClassNames} style={userStyle}>
        {
          isLoading ? 'Loading...' : (
            <>
              <div className={styles.tree}>
                <TreeView
                  badgesDisabled={badgesDisabled}
                  editable={!isReadOnly}
                  entityType={entityType}
                  folders={folders.items}
                  entities={entities.items}
                  organisations={_getOrganisations(organisations, role)}
                  selectedEntityId={selectedEntityId}
                  alternateLiveEntityColor={alternateLiveEntityColor}
                  selectEntityCallback={this._setSelectEntityCallback}
                  openFolderCallback={this._setOpenFolderCallback}
                  editFolderCallback={this._setEditFolderCallback}
                  checkEntityLiveCallback={checkEntityLiveCallback}
                  onEntitySelect={onEntitySelect}
                  onFolderCreateRequested={this._createFolder}
                  onFolderDeleteRequested={deleteFolder}
                  onFolderEditRequested={updateFolder}
                />
              </div>
              <div className={styles.actions}>
                <Button
                  disabled={isReadOnly}
                  color={Button.COLOR.SECONDARY_ALTERNATE}
                  icon={Icon.TYPE.FOLDER_ADD}
                  onClick={createFolderDialog.openDialog}
                />
                <Button
                  disabled={isReadOnly}
                  color={Button.COLOR.PRIMARY_ALTERNATE}
                  text={createEntityText}
                  onClick={this._createEntity}
                />
              </div>
            </>
          )
        }
        {
          createFolderDialog.isDialogOpen && this._renderCreateFolderDialog()
        }
      </div>
    );
  }

  _renderCreateFolderDialog() {
    const {createFolderDialog} = this.props;
    const {isCreatingFolder} = this.state;

    return createFolderDialog.renderDialog({
      renderTitle: () => 'Create Folder',
      renderContent: this._renderCreateFolderDialogContent,
      submitCallback: this._setSubmitFolderForm,
      closeDisabledCallback: () => isCreatingFolder,
      submitDisabledCallback: () => {
        const {isCreatingFolder, folderFormHasErrors} = this.state;

        return isCreatingFolder || folderFormHasErrors;
      }
    });
  }

  _renderCreateFolderDialogContent = () => {
    const {entityType, folders, entities, organisations, role, selectedEntityId} = this.props;
    const {isCreatingFolder} = this.state;

    const entity = selectedEntityId && entities.items.find(ent => ent.id === selectedEntityId);

    const defaultValues = {
      parentId: entity ? entity.folderId : null
    };

    if (entity && !entity.folderId && (role === 'root')) {
      defaultValues.parentId = `organisation-${entity.organisationId}`;
    }

    const fields = getCreateFolderFormFields({
      entityType,
      folders: folders.items,
      organisations: _getOrganisations(organisations, role)
    });

    return (
      <Form
        disabled={isCreatingFolder}
        fields={fields}
        defaultValues={defaultValues}
        userClassName={styles['folder-dialog-form']}
        submitCallbackRef={this._setSubmitFolderFormCallbackRef}
        onChange={this._handleFolderFormChange}
        onSubmit={this._handleCreateFolderFormSubmit}
        onSubmitFailure={handleSubmitFailure(getCreateFolderErrors)}
      />
    );
  };

  _createFolder = async (parentId, organisationId) => {
    const {entityType, entities, selectedEntityId, createFolder} = this.props;

    const entity = selectedEntityId && entities.items.find(ent => ent.id === selectedEntityId);

    const data = {
      name: 'Untitled folder',
      type: entityType,
      parentId: null
    };

    if (entity) {
      data.organisationId = entity.organisationId;
      data.parentId = entity.folderId;
    }

    if (parentId) {
      data.parentId = parentId;
    }

    if (organisationId) {
      data.organisationId = organisationId;
    }

    try {
      const {item} = await createFolder(data);

      setTimeout(() => {
        this._editFolderCallback(item.id);
      }, 0);
    } catch (err) {
      console.error(err);
    }
  };

  _createEntity = async () => {
    const {onEntityCreateRequested} = this.props;

    if (!onEntityCreateRequested) {
      return;
    }

    const {entities, organisations, selectedEntityId} = this.props;

    const entity = selectedEntityId ? entities.items.find(ent => ent.id === selectedEntityId) : null;

    let folderId = entity ? entity.folderId : null;

    if (entity && !entity.folderId && organisations) {
      folderId = `organisation-${entity.organisationId}`;
    }

    try {
      const item = await onEntityCreateRequested(folderId);

      if (!item) {
        return;
      }

      this._selectEntityCallback(item.id);
    } catch (err) {
      console.error(err);
    }
  };

  _handleCreateFolderFormSubmit = async ({name, parentId}) => {
    const {createFolderDialog, entityType, createFolder} = this.props;

    const organisationSpecified = Boolean(parentId) && parentId.startsWith('organisation-');

    const data = {
      name,
      parentId: organisationSpecified ? null : parentId,
      type: entityType
    };

    if (organisationSpecified) {
      data.organisationId = parentId.match(/^organisation-(.+)/)[1];
    }

    return new Promise((resolve, reject) => {
      this.setState({isCreatingFolder: true}, async () => {
        try {
          const {item} = await createFolder(data);

          this.setState({isCreatingFolder: false}, () => {
            createFolderDialog.closeDialog();

            setTimeout(() => {
              this._openFolderCallback(item.id);
            }, 0);

            resolve();
          });
        } catch (err) {
          this.setState({isCreatingFolder: false}, () => {
            reject(err);
          });
        }
      });
    });
  };

  _setSelectEntityCallback = callback => {
    this._selectEntityCallback = callback;
  };

  _setOpenFolderCallback = callback => {
    this._openFolderCallback = callback;
  };

  _setEditFolderCallback = callback => {
    this._editFolderCallback = callback;
  };

  _handleFolderFormChange = ({errors}) => {
    this.setState({folderFormHasErrors: Boolean(errors)});
  };

  _setSubmitFolderForm = () => {
    if (!this._submitFolderForm) {
      return;
    }

    this._submitFolderForm();
  };

  _setSubmitFolderFormCallbackRef = callback => {
    this._submitFolderForm = callback;
  };
}

FolderView.WrappedComponent.propTypes = {
  createFolderDialog: PropTypes.shape(DIALOG_SHAPE).isRequired
};

FolderView.propTypes = {
  entityType: PropTypes.oneOf(Object.values(TreeView.ENTITY_TYPE)).isRequired,
  createEntityText: PropTypes.string.isRequired,
  folders: PropTypes.object.isRequired,
  entities: PropTypes.object.isRequired,
  organisations: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  createFolder: PropTypes.func.isRequired,
  deleteFolder: PropTypes.func.isRequired,
  updateFolder: PropTypes.func.isRequired,
  selectedEntityId: PropTypes.string,
  alternateLiveEntityColor: PropTypes.bool,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  checkEntityLiveCallback: PropTypes.func,
  onEntitySelect: PropTypes.func,
  onEntityCreateRequested: PropTypes.func,
  badgesDisabled: PropTypes.bool
};

FolderView.defaultProps = {
  selectedEntityId: null,
  alternateLiveEntityColor: false,
  userClassName: null,
  userStyle: null,
  checkEntityLiveCallback: null,
  onEntitySelect: null,
  onEntityCreateRequested: null,
  badgesDisabled: false
};

export default FolderView;
