import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {createDispatchPromise} from '../../../lib/api';

import {api} from '../../../redux/actions';

import Component from './component.jsx';

function mapStateToProps(state) {
  const {user, isUpdating: isUpdatingUser} = state.user;
  const {items: users, isFetching: isFetchingUsers} = state.users;
  const {item: organisation} = state.organisation;
  const {items: organisations, isFetching: isFetchingOrganisations} = state.organisations;
  const {items: screens} = state.screens;

  return {
    user,
    isUpdatingUser,
    users,
    isFetchingUsers,
    organisations,
    isFetchingOrganisations,
    organisation,
    screens
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createOrganisation: api.organisations.createSingle,
    deleteOrganisation: api.organisations.deleteSingle,
    getUserList: api.users.getList,
    setSubscription: api.user.setSubscription,
    updateSelf: api.user.updateSelf,
    deleteUser: api.users.deleteSingle
  }, dispatch);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {uid, role, organisationId} = stateProps.user;

  return {
    ...ownProps,
    ...stateProps,
    createOrganisation: name => {
      return createDispatchPromise(dispatchProps.createOrganisation, {data: {name}});
    },
    deleteOrganisation: id => {
      return createDispatchPromise(dispatchProps.deleteOrganisation, {
        data: {id}
      });
    },
    getUserList: () => {
      return createDispatchPromise(dispatchProps.getUserList, ({data: {role, organisationId}}));
    },
    setSubscription: (type, card) => {
      const data = {type};

      if (card) {
        data.card = card;
      }

      return createDispatchPromise(dispatchProps.setSubscription, ({data}));
    },
    updateSelf: ({photoUrl, name, title}) => {
      return createDispatchPromise(dispatchProps.updateSelf, ({
        data: {
          uid,
          photoUrl,
          name,
          title
        }
      }));
    },
    deleteUser: uid => {
      return createDispatchPromise(dispatchProps.deleteUser, ({
        data: {uid}
      }));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);
