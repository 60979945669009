import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {createDispatchPromise} from '../../../lib/api';

import {api} from '../../../redux/actions';

import Component from './component.jsx';

function mapStateToProps(state) {
  const {items: screens, isFetching} = state.screens;
  const {role, organisationId, subscriptionType, isStripeSubscriptionActive} = state.user.user;
  const {items: folders} = state.folders;
  const {items: organisations} = state.organisations;
  const {items: schedules} = state.schedules;
  const {items: campaigns} = state.campaigns;

  return {
    folders,
    isFetching,
    role,
    organisationId,
    subscriptionType,
    isStripeSubscriptionActive,
    screens,
    schedules,
    campaigns,
    organisations: (role === 'root') ? organisations : null
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createScreen: api.screens.createSingle,
    deleteScreen: api.screens.deleteSingle,
    updateScreen: api.screens.updateSingle
  }, dispatch);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {organisationId} = stateProps;

  return {
    ...ownProps,
    ...stateProps,
    createScreen: data => {
      return createDispatchPromise(dispatchProps.createScreen, ({data: {organisationId, ...data}}));
    },
    deleteScreen: id => {
      return createDispatchPromise(dispatchProps.deleteScreen, ({data: {id}}));
    },
    updateScreen: (id, data) => {
      return createDispatchPromise(dispatchProps.updateScreen, ({data: {id, ...data}}));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);
