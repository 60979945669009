exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Imports
var getUrl = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL___0___ = getUrl(require("../../../../assets/img/logo/default.png"));
// Module
exports.push([module.id, "._2ibGGazm{width:100%;height:72px;flex-shrink:0;justify-content:space-between;padding:0 15px;background-color:#fff;box-shadow:0 2px 0 0 rgba(77,35,66,.04);z-index:1}._2ibGGazm,._3WwigI0H{-js-display:flex;display:flex}._3-R1lpmY,._3WwigI0H{height:100%}._3-R1lpmY{width:80px;margin:0 16px;background:transparent url(" + ___CSS_LOADER_URL___0___ + ") 50% no-repeat;background-size:contain}._3_HxlqyW{height:100%;padding:0 16px;-js-display:flex;display:flex;justify-content:center;align-items:center;font-size:17px;font-weight:500}._2hKc3QhD{color:#ff2d17}", "",{"version":3,"sources":["styles.less"],"names":[],"mappings":"AAAA,WACE,UAAW,CACX,WAAY,CACZ,aAAc,CAEd,6BAA8B,CAC9B,cAAe,CACf,qBAAsB,CACtB,uCAA4C,CAC5C,SACF,CACA,sBAPE,gBAAa,CAAb,YAUF,CACA,sBAHE,WASF,CANA,WACE,UAAW,CAEX,aAAc,CACd,kEAAuF,CACvF,uBACF,CACA,WACE,WAAY,CACZ,cAAe,CACf,gBAAa,CAAb,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,cAAe,CACf,eACF,CACA,WACE,aACF","file":"styles.less","sourcesContent":[".navbar {\n  width: 100%;\n  height: 72px;\n  flex-shrink: 0;\n  display: flex;\n  justify-content: space-between;\n  padding: 0 15px;\n  background-color: #fff;\n  box-shadow: 0 2px 0 0 rgba(77, 35, 66, 0.04);\n  z-index: 1;\n}\n.navbar-items {\n  height: 100%;\n  display: flex;\n}\n.logo {\n  width: 80px;\n  height: 100%;\n  margin: 0 16px;\n  background: transparent url('../../../../assets/img/logo/default.png') center no-repeat;\n  background-size: contain;\n}\n.navbar-item {\n  height: 100%;\n  padding: 0 16px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 17px;\n  font-weight: 500;\n}\n.navbar-item-selected-text {\n  color: #ff2d17;\n}\n"]}]);
// Exports
exports.locals = {
	"navbar": "_2ibGGazm",
	"navbar-items": "_3WwigI0H",
	"logo": "_3-R1lpmY",
	"navbar-item": "_3_HxlqyW",
	"navbar-item-selected-text": "_2hKc3QhD"
};