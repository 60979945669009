export default {
  overrides: {
    MuiChip: {
      root: {
        backgroundColor: '#fff'
      },
      label: {
        color: '#72536a'
      },
      deleteIcon: {
        color: '#72536a',
        opacity: 0.74,
        '&:hover': {
          color: '#72536a',
          opacity: 0.87
        }
      }
    },
    MuiTableCell: {
      root: {
        paddingLeft: ['24px', '!important'],
        paddingRight: ['24px', '!important'],
        '@media (max-width: 599.95px)': {
          paddingLeft: ['10px', '!important'],
          paddingRight: ['10px', '!important']
        }
      }
    },
    MuiDialogContent: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }
    },
    MuiDialogActions: {
      root: {
        padding: 16
      }
    },
    MuiButton: {
      root: {
        height: 64,
        borderRadius: 10,
        textTransform: 'none'
      },
      label: {
        lineHeight: '22px',
        fontSize: '17px'
      },
      containedPrimary: {
        backgroundColor: '#ff2d17'
      }
    },
    MuiFormControl: {
      root: {
        width: '100%'
      }
    },
    MuiInputLabel: {
      root: {
        zIndex: 1,
        paddingLeft: '25px',
        pointerEvents: 'none',
        transform: ['translate(0, 46px)', '!important'],
        fontSize: '17px'
      },
      shrink: {
        transform: ['translate(0, 5px)', '!important'],
        fontSize: '13px'
      }
    },
    MuiInput: {
      root: {
        height: 64,
        marginTop: ['22px', '!important'],
        padding: '0 25px',
        boxSizing: 'border-box',
        border: '2px solid rgba(76, 35, 66, 0.12)',
        borderRadius: 10,
        backgroundColor: '#fff',
        '@media (max-width: 599.95px)': {
          padding: ['0 1px 0 7px', '!important']
        },
        '&$error': {
          borderColor: ['#f54949', '!important']
        },
        '&:not($error)$focused': {
          borderColor: ['rgba(255, 45, 23, 0.2)', '!important']
        }
      },
      multiline: {
        height: 'auto'
      },
      input: {
        lineHeight: '38px',
        padding: 0,
        fontSize: '17px',
        '&[type=number]': {
          '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
          }
        }
      },
      inputMultiline: {
        padding: '4px 11px',
        lineHeight: ['16px', '!important'],
        boxSizing: 'border-box'
      },
      underline: {
        '&::before': {
          borderBottom: ['none', '!important']
        },
        '&::after': {
          borderBottom: 'none'
        }
      }
    },
    MuiInputAdornment: {
      root: {
        marginTop: 3
      },
      positionEnd: {
        marginLeft: 2,
        width: 24,
        '& > button': {
          width: 24,
          height: 24
        }
      }
    },
    MuiSelect: {
      select: {
        height: 64
      },
      icon: {
        fill: '#72536a'
      }
    },
    MuiList: {
      root: {
        paddingTop: 0,
        paddingBottom: 0
      },
      padding: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    MuiListItem: {
      root: {
        padding: ['8px 12px', '!important'],
        fontSize: ['17px', '!important']
      }
    }
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#4d2342'
    },
    secondary: {
      main: '#2da1f8'
    },
    error: {
      main: '#f54949'
    },
    text: {
      primary: '#72536a',
      secondary: '#ac90a5',
      disabled: 'rgba(76, 35, 66, 0.12)',
      hint: '#ff0000'
    },
    background: {
      default: '#fcfafa',
      paper: '#fff'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Inter, sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  }
};
