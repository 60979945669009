import {createAction} from 'redux-actions';
import {defineAction} from 'redux-define';

import * as firebase from 'firebase/app';

export const STATE = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};
export const STATES = Object.values(STATE);

export const API_NAMESPACE_NAME = 'api';
export const API_NAMESPACE = defineAction(API_NAMESPACE_NAME);
export const API_AUTH_NAMESPACE = defineAction('auth.ts.js', API_NAMESPACE);
export const API_INITIALIZED = defineAction('API_INITIALIZED');

export const apiInitialized = createAction(API_INITIALIZED.ACTION);

const requestQueue = [];
let hasActiveRequest = false;

const REALTIME_UPDATE_OBSERVERS = new Map();

function _tryCallDispatchCallback(dispatchCallback, ...args) {
  if (typeof dispatchCallback !== 'function') {
    return;
  }

  dispatchCallback(...args);
}

function _checkQueue() {
  if (requestQueue.length < 1) {
    hasActiveRequest = false;

    return;
  }

  const {params, resolve, reject} = requestQueue.shift();

  _request(params).then(resolve).catch(reject);
}

async function _request(params) {
  const {method, data, dispatchCallback} = params;

  try {
    const result = await method({data});

    _tryCallDispatchCallback(dispatchCallback, null, result);

    _checkQueue();

    return result;
  } catch (err) {
    _tryCallDispatchCallback(dispatchCallback, err);

    _checkQueue();

    throw err;
  }
}

export function createApiAction(action, method) {
  return createAction(action, (payload = {}) => {
    const {data, dispatchCallback} = payload;

    const apiAction = {
      method,
      dispatchCallback,
      sourceAction: {
        payload,
        type: action
      }
    };

    if (payload && (data !== undefined)) {
      apiAction.data = {...data};
    }

    return apiAction;
  });
}

export function createDispatchPromise(method, payload = {}) {
  return new Promise((resolve, reject) => {
    method({
      ...payload,
      dispatchCallback: (err, result) => {
        if (err) {
          reject(err);

          return;
        }

        resolve(result);
      }
    });
  });
}

export function defineApiActionType(name, namespace = API_NAMESPACE) {
  return defineAction(name, STATES, namespace);
}

export function defineApiSubNamespace(name) {
  return defineAction(name, API_NAMESPACE);
}

export function request(params) {
  if (hasActiveRequest) {
    return new Promise((resolve, reject) => {
      requestQueue.push({
        params,
        resolve,
        reject
      });
    });
  }

  hasActiveRequest = true;

  return _request(params);
}

const unsubscribeFromRealtimeUpdates = collectionName => () => {
  const unsubscribe = REALTIME_UPDATE_OBSERVERS.get(collectionName);

  if (unsubscribe) {
    REALTIME_UPDATE_OBSERVERS.delete(collectionName);

    unsubscribe();
  }
};

export function subscribeToRealtimeUpdates(collectionName, role, organisationId, onData) {
  const unsubscribe = unsubscribeFromRealtimeUpdates(collectionName);

  unsubscribe();

  let query = firebase.firestore().collection(collectionName);

  if (role !== 'root') {
    query = query.where('organisationId', '==', organisationId);
  }

  const observer = query.onSnapshot(snapshot => {
    const items = [];

    snapshot.docChanges().forEach(({type, doc}) => {
      if (type === 'added') {
        items.push({
          id: doc.id,
          ...doc.data()
        });
      } else {
        onData({type, doc});
      }
    });

    if (items.length > 0) {
      onData({
        items,
        type: 'added_bulk'
      });
    }

    onData({type: 'fetched'});
  }, err => {
    console.warn('Realtime updates error:', err);

    subscribeToRealtimeUpdates(collectionName, role, organisationId, onData);
  });

  REALTIME_UPDATE_OBSERVERS.set(collectionName, observer);

  return unsubscribe;
}
