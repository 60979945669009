import {createAction} from 'redux-actions';

import {FOLDERS} from '../action-types';

export const addSingle = createAction(FOLDERS.ADD_SINGLE.ACTION);
export const addList = createAction(FOLDERS.ADD_LIST.ACTION);
export const clear = createAction(FOLDERS.CLEAR.ACTION);
export const deleteSingle = createAction(FOLDERS.DELETE_SINGLE.ACTION);
export const setFetched = createAction(FOLDERS.SET_FETCHED.ACTION);
export const updateSingle = createAction(FOLDERS.UPDATE_SINGLE.ACTION);
