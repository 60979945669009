import * as firebase from 'firebase/app';

import {createApiAction} from '../../../lib/api';

import ITimeSlot from '../../../models/time-slot';

import {API} from '../../action-types';

interface ICreateSingleData {
  organisationId: string;
  name: string;
  description: string;
  folderId: string | null;
  timeSlots: ITimeSlot[];
}

interface IDeleteSingleData {
  id: string;
}

interface IUpdateSingleData extends IDeleteSingleData, Partial<ICreateSingleData> {}

export const createSingle = createApiAction(
  API.SCHEDULES.CREATE_SINGLE.ACTION,
  async ({data}: {data: ICreateSingleData}) => {
    const schedules = firebase.firestore().collection('schedules');

    const scheduleRef = await schedules.add({...data});

    return {
      item: {
        ...data,
        id: scheduleRef.id
      }
    };
  }
);

export const deleteSingle = createApiAction(
  API.SCHEDULES.DELETE_SINGLE.ACTION,
  async ({data: {id}}: {data: IDeleteSingleData}) => {
    const schedules = firebase.firestore().collection('schedules');

    await schedules.doc(id).delete();
  }
);

export const updateSingle = createApiAction(
  API.SCHEDULES.UPDATE_SINGLE.ACTION,
  async ({data: {id, ...rest}}: {data: IUpdateSingleData}) => {
    const schedules = firebase.firestore().collection('schedules');

    await schedules
      .doc(id)
      .update({...rest});
  }
);
