import React from 'react';
import * as PropTypes from 'prop-types';

import {withRouter} from 'react-router-dom';

import ClickableText from '../../clickable-text';

import UserMenu from './user-menu';

import styles from './styles.less';

class Navbar extends React.Component {
  render() {
    const ITEMS = [{
      title: 'Screens',
      href: '/screens'
    }, {
      title: 'Campaigns',
      href: '/campaigns'
    }, {
      title: 'Schedules',
      href: '/schedules'
    }];

    return (
      <div className={styles.navbar}>
        <div className={styles['navbar-items']}>
          <div className={styles.logo}/>
          {
            ITEMS.map(this._renderItem)
          }
        </div>
        <UserMenu/>
      </div>
    );
  }

  _renderItem = ({title, href}) => {
    const {location} = this.props;

    const textClassNames = (location.pathname === href) ? styles['navbar-item-selected-text'] : null;

    return (
      <div key={title} className={styles['navbar-item']}>
        <ClickableText text={title} href={href} userClassName={textClassNames}/>
      </div>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(Navbar);
