import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import {getScreenActiveCampaignId} from '../../../lib/helper';

import CampaignPreview from '../campaign-preview';
import withCurrentDate from '../with-current-date';

import styles from './styles.less';

@withCurrentDate()
class ScreenPreview extends React.Component {
  render() {
    const {currentDate, screen, campaigns, schedules, userClassName, userStyle} = this.props;

    const activeCampaignId = getScreenActiveCampaignId(screen.id, schedules, currentDate) || screen.defaultCampaignId;

    const activeCampaign = activeCampaignId && campaigns.find(cmp => cmp.id === activeCampaignId);

    const screenPreviewClassNames = classNames({
      [styles['screen-preview']]: true,
      [userClassName]: Boolean(userClassName)
    });

    return (
      <div className={screenPreviewClassNames} style={userStyle}>
        {
          activeCampaign && (
            <CampaignPreview campaign={activeCampaign} userClassName={styles['campaign-preview-wrapper']}/>
          )
        }
      </div>
    );
  }
}

ScreenPreview.WrappedComponent.propTypes = {
  currentDate: PropTypes.object.isRequired
};

ScreenPreview.propTypes = {
  screen: PropTypes.object.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  schedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object
};

ScreenPreview.defaultProps = {
  userClassName: null,
  userStyle: null
};

export default ScreenPreview;
