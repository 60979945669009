import React from 'react';
import * as PropTypes from 'prop-types';

import {findElement} from '../../../../../lib/helper';

import ClickableText from '../../../clickable-text';
import Icon from '../../../icon';

import styles from './styles.less';

class UserMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuVisible: false
    };
  }

  componentDidMount() {
    window.document.addEventListener('click', this._handleClickOutside);
  }

  componentWillUnmount() {
    window.document.removeEventListener('click', this._handleClickOutside);
  }

  render() {
    const {user, organisation} = this.props;
    const {isMenuVisible} = this.state;

    return (
      <div className={styles['user-menu-wrapper']}>
        <div ref={this._setMenuRef} className={styles['user-menu']} onClick={this._handleClickMenu}>
          <div className={styles['avatar-wrapper']}>
            {
              user.photoUrl ? (
                <img src={user.photoUrl} className={styles.avatar}/>
              ) : (
                <Icon type={Icon.TYPE.CAMERA} userClassName={styles['avatar-placeholder']}/>
              )
            }
          </div>
          <div className={styles['info-container']}>
            <div className={styles.email}>{user.name}</div>
            {
              organisation && (
                <div className={styles.organisation}>{organisation.name}</div>
              )
            }
          </div>
          <div className={styles['icon-wrapper']}>
            <Icon type={Icon.TYPE.CHEVRON_DOWN}/>
          </div>
        </div>
        {
          isMenuVisible && this._renderOverlayMenu()
        }
      </div>
    );
  }

  _renderOverlayMenu() {
    const {user, organisation} = this.props;

    return (
      <div ref={this._setOverlayMenuRef} className={styles['overlay-menu']}>
        <div className={styles['overlay-menu-content']}>
          <div className={styles['overlay-menu-info']}>
            {
              user.photoUrl ? (
                <img src={user.photoUrl} className={styles['overlay-menu-avatar']}/>
              ) : (
                <Icon type={Icon.TYPE.CAMERA} userClassName={styles['overlay-menu-avatar-placeholder']}/>
              )
            }
            <div className={styles['overlay-menu-info-container']}>
              <div className={styles.email}>{user.name}</div>
              {
                organisation && (
                  <div className={styles.organisation}>{organisation.name}</div>
                )
              }
            </div>
          </div>
          <div className={styles['overlay-menu-actions']}>
            <ClickableText text="Account Settings" href="/account" userClassName={styles['overlay-menu-action']}/>
          </div>
        </div>
        <div className={styles.separator}/>
        <div className={styles['logout-container']}>
          <ClickableText text="Log Out" userClassName={styles['logout-button']} onClick={this._handleLogOut}/>
        </div>
      </div>
    );
  }

  _handleClickOutside = e => {
    if (findElement(e.target, this._menuRef) || findElement(e.target, this._overlayMenuRef)) {
      return;
    }

    this.setState({isMenuVisible: false});
  };

  _handleClickMenu = () => {
    this.setState(state => ({isMenuVisible: !state.isMenuVisible}));
  };

  _handleLogOut = () => {
    const {logout} = this.props;

    this.setState(() => ({isMenuVisible: false}), logout);
  };

  _setMenuRef = el => {
    this._menuRef = el;
  };

  _setOverlayMenuRef = el => {
    this._overlayMenuRef = el;
  };
}

UserMenu.propTypes = {
  user: PropTypes.object.isRequired,
  organisation: PropTypes.object,
  logout: PropTypes.func.isRequired
};

UserMenu.defaultProps = {
  organisation: null
};

export default UserMenu;
