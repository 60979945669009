import {defineAction} from 'redux-define';

const schedules = defineAction('schedules');

export const ADD_SINGLE = defineAction('ADD_SINGLE', schedules);
export const ADD_LIST = defineAction('ADD_LIST', schedules);
export const CLEAR = defineAction('CLEAR', schedules);
export const DELETE_SINGLE = defineAction('DELETE_SINGLE', schedules);
export const SET_FETCHED = defineAction('SET_FETCHED', schedules);
export const UPDATE_SINGLE = defineAction('UPDATE_SINGLE', schedules);
