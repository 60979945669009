import React from 'react';
import * as PropTypes from 'prop-types';

import {getRegisterErrors} from '../../../lib/form/error-getters';
import {REGISTRATION_FORM_FIELDS} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';
import {validatePasswordsMatch} from '../../../lib/form/validators';

import AuthPage from '../../common/auth-page';

class RegistrationPage extends React.Component {
  render() {
    const {isRegistering, token, organisationName, email, userName, register} = this.props;

    const isLinkValid = Boolean(token && organisationName && userName);

    const header = isLinkValid ? (
      <div>
        {`${userName} has invited you to join the team `}
        <strong>{organisationName}</strong>.
        {' Enter your details below to create an account.'}
      </div>
    ) : 'You are probably using invalid link. Please, obtain a good one.';

    const formProps = isLinkValid ? {
      actionText: 'Create Account',
      fields: REGISTRATION_FORM_FIELDS,
      defaultValues: {email},
      validate: validatePasswordsMatch,
      disabled: isRegistering,
      onSubmit: register,
      onSubmitFailure: handleSubmitFailure(getRegisterErrors)
    } : null;

    return (
      <AuthPage formProps={formProps} header={header}/>
    );
  }
}

RegistrationPage.propTypes = {
  isRegistering: PropTypes.bool.isRequired,
  token: PropTypes.string,
  organisationName: PropTypes.string,
  email: PropTypes.string,
  userName: PropTypes.string,
  register: PropTypes.func.isRequired
};

RegistrationPage.defaultProps = {
  token: null,
  organisationName: null,
  email: null,
  userName: null
};

export default RegistrationPage;
