import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';
import {Rnd} from 'react-rnd';

import {deepCopy, measureElement} from '../../../lib/helper';

import styles from './styles.less';

function _getActualSize(extentType, resolution, {width, height}) {
  const actualExtent = (extentType === 'width') ? width : height;
  const dependentExtentType = (extentType === 'width') ? 'height' : 'width';
  const ratio = (dependentExtentType === 'width') ?
    (resolution.width / resolution.height) :
    (resolution.height / resolution.width);

  const size = {width, height};

  size[dependentExtentType] = actualExtent * ratio;

  return {...size, dependentExtentType};
}

function _getSlideStyle({extentType, extentValue, resolution, backgroundColor, width, height}) {
  const extent = (typeof extentValue === 'number') ? `${extentValue}px` : extentValue;

  const slideStyle = {
    backgroundColor,
    [extentType]: extent
  };

  if (!width && !height) {
    return slideStyle;
  }

  const actualSize = _getActualSize(extentType, resolution, {width, height});

  slideStyle[actualSize.dependentExtentType] = `${actualSize[actualSize.dependentExtentType]}px`;

  return slideStyle;
}

function _getFontFamily(font) {
  switch (font) {
  case 'arial':
    return 'Arial, Helvetica, sans-serif';
  case 'helvetica':
    return 'Helvetica, sans-serif';
  case 'times_new_roman':
    return '"Times New Roman", Times, serif';
  case 'times':
    return 'Times, serif';
  case 'courier_new':
    return '"Courier New", Courier, monospace';
  case 'courier':
    return 'Courier, monospace';
  case 'verdana':
    return 'Verdana, Geneva, sans-serif';
  case 'georgia':
    return 'Georgia, serif';
  case 'palatino':
    return 'Palatino, serif';
  case 'garamond':
    return 'Garamond, Georgia, serif';
  case 'bookman':
    return 'Bookman, "URW Bookman L", "Palatino Linotype", serif';
  case 'comic_sans_ms':
    return '"Comic Sans MS", cursive, sans-serif';
  case 'trebuchet_ms':
    return '"Trebuchet MS", Helvetica, sans-serif';
  case 'arial_black':
    return '"Arial Black", Gadget, sans-serif';
  case 'impact':
    return 'Impact, Charcoal, sans-serif';
  default:
    return 'inherit';
  }
}

const _scalePixelValue = (extentType, resolution, {width, height}) => value => {
  const actualExtent = (extentType === 'width') ? width : height;
  const desiredExtent = (extentType === 'width') ? resolution.width : resolution.height;

  return value * (actualExtent / desiredExtent);
};

const _unscalePixelValue = (extentType, resolution, {width, height}) => value => {
  const actualExtent = (extentType === 'width') ? width : height;
  const desiredExtent = (extentType === 'width') ? resolution.width : resolution.height;

  return Math.round(value * (desiredExtent / actualExtent));
};

class Slide extends React.Component {
  static get EXTENT_TYPE() {
    return {
      WIDTH: 'width',
      HEIGHT: 'height'
    };
  }

  static get EDIT_MODE() {
    return {
      NONE: 'none',
      LAYOUT: 'layout',
      TEXT: 'text'
    };
  }

  static get LAYOUT_TYPE() {
    return {
      SINGLE: 'single',
      TWO_COLUMNS: 'two_columns',
      TWO_ROWS: 'two_rows',
      THREE_THIRDS: 'three_thirds',
      FOUR_QUARTERS: 'four_quarters'
    };
  }

  static get BACKGROUND_ASSET_TYPE() {
    return {
      IMAGE: 'image',
      VIDEO: 'video'
    };
  }

  static get BACKGROUND_ASSET_RESIZE_MODE() {
    return {
      CONTAIN: 'contain',
      COVER: 'cover'
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
      isDragging: false
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this._handleWindowResize);

    this._handleWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleWindowResize);
  }

  render() {
    const {
      extentType,
      extentValue,
      resolution,
      backgroundColor,
      backgroundAsset,
      texts,
      editable,
      editMode,
      isUploading
    } = this.props;
    const {width, height, isDragging} = this.state;

    const slideClassNames = classNames({
      [styles.slide]: true,
      [styles['slide-with-border']]: editMode === Slide.EDIT_MODE.LAYOUT
    });
    const slideStyle = _getSlideStyle({extentType, extentValue, resolution, backgroundColor, width, height});

    const slideDragAreaClassNames = classNames({
      [styles['slide-drag-area']]: true,
      [styles['slide-drag-area-active']]: isDragging || isUploading,
      [styles['slide-drag-area-disabled']]: isUploading
    });

    const handleDrop = this._handleDrop(
      'video/',
      'background-asset',
      'Unsupported file type. Video types are allowed only.'
    );

    return (
      <div ref={this._setContentRef} className={slideClassNames} style={slideStyle}>
        {
          backgroundAsset && this._renderBackgroundAsset(backgroundAsset)
        }
        {
          this._renderLayout()
        }
        {
          texts.map(this._renderText)
        }
        {
          editable && (editMode === Slide.EDIT_MODE.NONE) && (
            <div
              className={slideDragAreaClassNames}
              onDragEnter={this._handleDragEnter()}
              onDragOver={this._handleDragEnter()}
              onDragExit={this._handleDragLeave}
              onDragLeave={this._handleDragLeave}
              onDrop={handleDrop}
            />
          )
        }
      </div>
    );
  }

  _renderLayout() {
    const {layoutType} = this.props;

    switch (layoutType) {
    case Slide.LAYOUT_TYPE.SINGLE:
      return this._renderLayoutSingle();
    case Slide.LAYOUT_TYPE.TWO_COLUMNS:
      return this._renderLayoutTwoColumns();
    case Slide.LAYOUT_TYPE.TWO_ROWS:
      return this._renderLayoutTwoRows();
    case Slide.LAYOUT_TYPE.THREE_THIRDS:
      return this._renderLayoutThreeThirds();
    case Slide.LAYOUT_TYPE.FOUR_QUARTERS:
      return this._renderLayoutFourQuarters();
    default:
      return null;
    }
  }

  _renderLayoutSingle() {
    const {layoutData} = this.props;

    return (
      <div className={styles['layout-single']}>
        {
          this._renderLayoutItemContent(layoutData[0], 0)
        }
      </div>
    );
  }

  _renderLayoutTwoColumns() {
    const {layoutData} = this.props;

    return (
      <>
        <div className={styles['layout-two-columns']} style={{left: 0, top: 0}}>
          {
            this._renderLayoutItemContent(layoutData[0], 0)
          }
        </div>
        <div className={styles['layout-two-columns']} style={{left: '50%', top: 0}}>
          {
            this._renderLayoutItemContent(layoutData[1], 1)
          }
        </div>
      </>
    );
  }

  _renderLayoutTwoRows() {
    const {layoutData} = this.props;

    return (
      <>
        <div className={styles['layout-two-rows']} style={{left: 0, top: 0}}>
          {
            this._renderLayoutItemContent(layoutData[0], 0)
          }
        </div>
        <div className={styles['layout-two-rows']} style={{left: 0, top: '50%'}}>
          {
            this._renderLayoutItemContent(layoutData[1], 1)
          }
        </div>
      </>
    );
  }

  _renderLayoutThreeThirds() {
    const {layoutData} = this.props;

    return (
      <>
        <div className={styles['layout-three-thirds-large']}>
          {
            this._renderLayoutItemContent(layoutData[0], 0)
          }
        </div>
        <div className={styles['layout-three-thirds-small']} style={{top: 0}}>
          {
            this._renderLayoutItemContent(layoutData[1], 1)
          }
        </div>
        <div className={styles['layout-three-thirds-small']} style={{top: '50%'}}>
          {
            this._renderLayoutItemContent(layoutData[2], 2)
          }
        </div>
      </>
    );
  }

  _renderLayoutFourQuarters() {
    const {layoutData} = this.props;

    return (
      <>
        <div className={styles['layout-four-quarters']} style={{left: 0, top: 0}}>
          {
            this._renderLayoutItemContent(layoutData[0], 0)
          }
        </div>
        <div className={styles['layout-four-quarters']} style={{right: 0, top: 0}}>
          {
            this._renderLayoutItemContent(layoutData[1], 1)
          }
        </div>
        <div className={styles['layout-four-quarters']} style={{left: 0, bottom: 0}}>
          {
            this._renderLayoutItemContent(layoutData[2], 2)
          }
        </div>
        <div className={styles['layout-four-quarters']} style={{right: 0, bottom: 0}}>
          {
            this._renderLayoutItemContent(layoutData[3], 3)
          }
        </div>
      </>
    );
  }

  _renderLayoutItemContent = ({backgroundAsset}, index) => {
    const {editable, editMode, isUploading} = this.props;
    const {isDragging, draggingLayoutItemIndex} = this.state;

    const isEditingLayout = editMode === Slide.EDIT_MODE.LAYOUT;

    const layoutItemContentClassNames = classNames({
      [styles['layout-item-content']]: true,
      [styles['layout-item-content-with-border']]: isEditingLayout
    });

    const slideDragAreaClassNames = classNames({
      [styles['slide-drag-area']]: true,
      [styles['slide-drag-area-active']]: (isDragging || isUploading) && (index === draggingLayoutItemIndex),
      [styles['slide-drag-area-disabled']]: isUploading
    });

    const handleDrop = this._handleDrop(
      'image/',
      'layout-item-background-asset',
      'Unsupported file type. Image types are allowed only.',
      index
    );

    return (
      <div className={layoutItemContentClassNames}>
        {
          backgroundAsset && this._renderBackgroundAsset(backgroundAsset)
        }
        {
          editable && isEditingLayout && (
            <div
              className={slideDragAreaClassNames}
              onDragEnter={this._handleDragEnter(index)}
              onDragOver={this._handleDragEnter(index)}
              onDragExit={this._handleDragLeave}
              onDragLeave={this._handleDragLeave}
              onDrop={handleDrop}
            />
          )
        }
        {
          isEditingLayout && (
            <div className={styles['layout-item-number']}>{index + 1}</div>
          )
        }
      </div>
    );
  };

  _renderBackgroundAsset({type, url, resizeMode}) {
    const assetStyle = {objectFit: resizeMode};

    if (type === Slide.BACKGROUND_ASSET_TYPE.IMAGE) {
      return (
        <img src={url} className={styles['background-asset']} style={assetStyle}/>
      );
    }

    if (type === Slide.BACKGROUND_ASSET_TYPE.VIDEO) {
      return (
        <video autoPlay muted src={url} className={styles['background-asset']} loop="loop" style={assetStyle}/>
      );
    }

    return null;
  }

  _renderText = (item, index) => {
    const {extentType, resolution, editable, editMode} = this.props;
    const {width, height} = this.state;

    const {text, position, dimensions, color, size, font, weight, alignment, opacity, styles: textStyles} = item;

    const scalePixelValue = _scalePixelValue(extentType, resolution, {width, height});

    const isEditing = editable && (editMode === Slide.EDIT_MODE.TEXT);

    const textClassNames = classNames({
      [styles.text]: true,
      [styles.editable]: isEditing
    });
    const textStyle = {
      color,
      fontFamily: _getFontFamily(font),
      fontSize: `${scalePixelValue(size)}px`,
      fontWeight: weight,
      textAlign: alignment,
      opacity: (opacity / 100).toString()
    };

    if (textStyles.includes('italic')) {
      textStyle.fontStyle = 'italic';
    }

    const textDecorations = [];

    if (textStyles.includes('underlined')) {
      textDecorations.push('underline');
    }

    if (textStyles.includes('strikeout')) {
      textDecorations.push('line-through');
    }

    if (textDecorations.length > 0) {
      textStyle.textDecoration = textDecorations.join(' ');
    }

    if (!isEditing) {
      textStyle.left = `${scalePixelValue(position.x)}px`;
      textStyle.top = `${scalePixelValue(position.y)}px`;
      textStyle.width = `${scalePixelValue(dimensions.width)}px`;
      textStyle.height = `${scalePixelValue(dimensions.height)}px`;

      return (
        <div key={index} className={textClassNames} style={textStyle}>
          {text}
        </div>
      );
    }

    const def = {
      width: 100,
      height: 20,
      x: 0,
      y: 0
    };
    const pos = {
      x: scalePixelValue(position.x),
      y: scalePixelValue(position.y)
    };
    const sz = {
      width: scalePixelValue(dimensions.width),
      height: scalePixelValue(dimensions.height)
    };

    return (
      <Rnd
        key={index}
        disableDragging
        default={def}
        minWidth={20}
        minHeight={20}
        position={pos}
        size={sz}
        onResizeStop={this._handleResizeStop(index)}
      >
        <textarea
          value={text}
          className={styles.textarea}
          style={textStyle}
          onChange={this._handleTextChange(index)}
          onFocus={this._handleTextFocus(index)}
          onBlur={this._handleTextBlur(index)}
        />
        <div className={styles['resizable-box']} style={{left: '-4px', top: '-4px'}}/>
        <div className={styles['resizable-line']} style={{left: '4px', top: '-1px', width: 'calc(50% - 8px)'}}/>
        <div className={styles['resizable-box']} style={{left: 'calc(50% - 4px)', top: '-4px'}}/>
        <div className={styles['resizable-line']} style={{right: '4px', top: '-1px', width: 'calc(50% - 8px)'}}/>
        <div className={styles['resizable-box']} style={{right: '-4px', top: '-4px'}}/>
        <div className={styles['resizable-line']} style={{right: '-1px', top: '4px', height: 'calc(50% - 8px)'}}/>
        <div className={styles['resizable-box']} style={{right: '-4px', top: 'calc(50% - 4px)'}}/>
        <div className={styles['resizable-line']} style={{right: '-1px', bottom: '4px', height: 'calc(50% - 8px)'}}/>
        <div className={styles['resizable-box']} style={{right: '-4px', bottom: '-4px'}}/>
        <div className={styles['resizable-line']} style={{right: '4px', bottom: '-1px', width: 'calc(50% - 8px)'}}/>
        <div className={styles['resizable-box']} style={{left: 'calc(50% - 4px)', bottom: '-4px'}}/>
        <div className={styles['resizable-line']} style={{left: '4px', bottom: '-1px', width: 'calc(50% - 8px)'}}/>
        <div className={styles['resizable-box']} style={{left: '-4px', bottom: '-4px'}}/>
        <div className={styles['resizable-line']} style={{left: '-1px', bottom: '4px', height: 'calc(50% - 8px)'}}/>
        <div className={styles['resizable-box']} style={{left: '-4px', bottom: 'calc(50% - 4px)'}}/>
        <div className={styles['resizable-line']} style={{left: '-1px', top: '4px', height: 'calc(50% - 8px)'}}/>
      </Rnd>
    );
  };

  _handleWindowResize = () => {
    const {extentType, resolution} = this.props;

    if (!this._contentRef) {
      return;
    }

    const {width, height} = _getActualSize(extentType, resolution, measureElement(this._contentRef));

    this.setState({width, height});
  };

  _handleResizeStop = index => (e, direction, ref, delta, position) => {
    const {extentType, resolution, texts, onChange} = this.props;
    const {width, height} = this.state;

    if (!onChange) {
      return;
    }

    const unscalePixelValue = _unscalePixelValue(extentType, resolution, {width, height});

    const newTexts = deepCopy(texts);
    const text = newTexts[index];

    text.position = {
      x: Math.max(0, unscalePixelValue(position.x)),
      y: Math.max(0, unscalePixelValue(position.y))
    };

    const dw = unscalePixelValue(delta.width);
    const dh = unscalePixelValue(delta.height);

    text.dimensions = {
      width: Math.min(text.dimensions.width + dw, resolution.width - text.position.x),
      height: Math.min(text.dimensions.height + dh, resolution.height - text.position.y)
    };

    onChange({texts: newTexts});
  };

  _handleTextChange = index => e => {
    const {texts, onChange} = this.props;

    if (onChange) {
      const newTexts = deepCopy(texts);

      newTexts[index].text = e.target.value;

      onChange({texts: newTexts});
    }
  };

  _handleTextFocus = index => () => {
    const {onTextFocus} = this.props;

    if (!onTextFocus) {
      return;
    }

    onTextFocus(index);
  };

  _handleTextBlur = index => () => {
    const {onTextBlur} = this.props;

    if (!onTextBlur) {
      return;
    }

    onTextBlur(index);
  };

  _handleDragEnter = layoutItemIndex => e => {
    e.preventDefault();

    this.setState({
      isDragging: true,
      draggingLayoutItemIndex: layoutItemIndex
    });
  };

  _handleDragLeave = e => {
    e.preventDefault();

    this.setState({isDragging: false});
  };

  _handleDrop = (fileType, uploadType, invalidFileTypeMessage, layoutItemIndex) => e => {
    const file = e.dataTransfer.files[0];

    e.preventDefault();

    this.setState({isDragging: false});

    if (this.props.isUploading) {
      return;
    }

    if (!file.type.startsWith(fileType)) {
      // eslint-disable-next-line no-alert
      alert(invalidFileTypeMessage);
    }

    this.props.onUploadRequested(file, uploadType, layoutItemIndex);
  };

  _setContentRef = el => {
    this._contentRef = el;
  };
}

Slide.propTypes = {
  extentType: PropTypes.oneOf(Object.values(Slide.EXTENT_TYPE)).isRequired,
  extentValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  resolution: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  }).isRequired,
  backgroundColor: PropTypes.string.isRequired,
  backgroundAsset: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(Slide.BACKGROUND_ASSET_TYPE)).isRequired,
    url: PropTypes.string.isRequired,
    resizeMode: PropTypes.oneOf(Object.values(Slide.BACKGROUND_ASSET_RESIZE_MODE)).isRequired
  }),
  layoutType: PropTypes.oneOf(Object.values(Slide.LAYOUT_TYPE)).isRequired,
  layoutData: PropTypes.arrayOf(PropTypes.shape({
    backgroundAsset: PropTypes.shape({
      type: PropTypes.oneOf(Object.values(Slide.BACKGROUND_ASSET_TYPE)).isRequired,
      url: PropTypes.string.isRequired,
      resizeMode: PropTypes.oneOf(Object.values(Slide.BACKGROUND_ASSET_RESIZE_MODE)).isRequired
    })
  })).isRequired,
  texts: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    weight: PropTypes.string.isRequired,
    alignment: PropTypes.string.isRequired,
    opacity: PropTypes.number.isRequired,
    styles: PropTypes.arrayOf(PropTypes.string).isRequired,
    position: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired
    }).isRequired,
    dimensions: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired
    }).isRequired
  })).isRequired,
  editable: PropTypes.bool,
  editMode: PropTypes.oneOf(Object.values(Slide.EDIT_MODE)),
  isUploading: PropTypes.bool,
  onChange: PropTypes.func,
  onTextFocus: PropTypes.func,
  onTextBlur: PropTypes.func,
  onUploadRequested: PropTypes.func
};

Slide.defaultProps = {
  editable: false,
  editMode: Slide.EDIT_MODE.NONE,
  backgroundAsset: null,
  isUploading: false,
  onChange: null,
  onTextFocus: null,
  onTextBlur: null,
  onUploadRequested: null
};

export default Slide;
