import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import {getScheduleDefaultValues, getTimeSlotDefaultValues} from '../../../lib/form/default-values';
import {
  getAddTimeSlotErrors,
  getCreateScheduleErrors,
  getEditScheduleErrors,
  getEditTimeSlotErrors
} from '../../../lib/form/error-getters';
import {
  getAddTimeSlotFormFields,
  getCreateScheduleFormFields,
  getEditTimeSlotFormFields,
  getEditScheduleFormFields
} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';
import {validateTimeSlot} from '../../../lib/form/validators';
import {capitalizeFirstLetter, deepCopy, getRelativeCoordinates, isScheduleActive} from '../../../lib/helper';

import EntityInfo from '../../common/entity-info';
import EntityPlaceholder from '../../common/entity-placeholder';
import FolderView from '../../common/folder-view';
import Form from '../../common/form';
import Icon from '../../common/icon';
import MenuContainer from '../../common/menu-container';
import Page from '../../common/page';
import Tooltip from '../../common/tooltip';
import TreeView from '../../common/tree-view';
import withCurrentDate from '../../common/with-current-date';
import withDialog, {DIALOG_SHAPE} from '../../common/with-dialog';

import styles from './styles.less';

const FIRST_HOUR = 0;
const LAST_HOUR = 24;

const SLOT_HEIGHT_1H = 144;

function _getWeekFirstDay(date = new Date()) {
  const dowDiff = (date.getDay() || 7) - 1;

  const d = new Date();
  d.setDate(d.getDate() - dowDiff);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);

  return d;
}

function _ld(date, options) {
  return date.toLocaleString('en', options);
}

function _formatTime(time) {
  const d = new Date();
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(Number(time));

  return d.toLocaleTimeString('en', {
    hour: '2-digit',
    minute: '2-digit'
  });
}

function _getWeekString(firstDay) {
  const lastDay = new Date(firstDay);
  lastDay.setDate(lastDay.getDate() + 6);

  let s = `${_ld(firstDay, {day: 'numeric'})} ${_ld(firstDay, {month: 'short'})} `;

  if (firstDay.getFullYear() !== lastDay.getFullYear()) {
    s += `${firstDay.getFullYear()} `;
  }

  s += `– ${_ld(lastDay, {day: 'numeric'})} ${_ld(lastDay, {month: 'short'})} ${lastDay.getFullYear()}`;

  return s;
}

function _createDaysOfWeek(firstDay) {
  const list = [];

  for (let i = 0; i < 7; ++i) {
    const date = new Date(firstDay);

    date.setDate(date.getDate() + i);

    list.push(date);
  }

  return list;
}

function _createHours(day = new Date()) {
  const list = [];

  for (let i = FIRST_HOUR; i <= LAST_HOUR; ++i) {
    const date = new Date(day);

    date.setHours(i);

    list.push(date);
  }

  return list;
}

const _filterTimeSlots = dow => ({repeat, date, dayOfWeek}) => {
  switch (repeat) {
  case 'daily':
    return true;
  case 'weekly':
    return dow.getDay() === dayOfWeek;
  case 'once':
    return (dow.getFullYear() === date.getFullYear()) &&
      (dow.getMonth() === date.getMonth()) &&
      (dow.getDate() === date.getDate());
  default:
    return false;
  }
};

function _getFreeTimeRanges(timeSlots, currentDate) {
  const ranges = timeSlots
    .filter(ts => !currentDate || (ts.repeat !== 'once') || (ts.date >= currentDate))
    .sort((ts1, ts2) => ts1.timeStart - ts2.timeStart);

  if (ranges.length < 1) {
    return [{
      timeStart: FIRST_HOUR * 60 * 60 * 1000,
      timeEnd: LAST_HOUR * 60 * 60 * 1000
    }];
  }

  const freeRanges = [];

  let time = FIRST_HOUR * 60 * 60 * 1000;

  ranges.forEach(({timeStart, timeEnd}) => {
    if (timeStart > time) {
      freeRanges.push({
        timeStart: time,
        timeEnd: timeStart
      });
    }

    time = timeEnd;
  });

  if (time < (LAST_HOUR * 60 * 60 * 1000)) {
    freeRanges.push({
      timeStart: time,
      timeEnd: LAST_HOUR * 60 * 60 * 1000
    });
  }

  return freeRanges;
}

function _mergeTimeSlots(timeSlots) {
  const result = [];
  let last;

  timeSlots
    .sort((ts1, ts2) => ts1.timeStart - ts2.timeStart || ts1.timeEnd - ts2.timeEnd)
    .forEach(timeSlot => {
      const {timeStart, timeEnd} = timeSlot;

      if (!last || (timeStart > last.timeEnd)) {
        result.push(last = timeSlot);
      } else if (timeEnd > last.timeEnd) {
        last.timeEnd = timeEnd;
      }
    });

  return result;
}

function _getOccupiedTimeSlots(timeSlots, schedules, dow, currentDate) {
  const occupiedTimeSlots = _mergeTimeSlots(schedules.reduce((acc, schedule) => {
    const list = schedule.timeSlots
      .filter(_filterTimeSlots(dow))
      .filter(ts => !currentDate || (ts.repeat !== 'once') || (ts.date >= currentDate));

    return acc.concat(deepCopy(list));
  }, []));

  const result = [];

  occupiedTimeSlots.forEach(ts => {
    const breaks = timeSlots.filter(({timeStart, timeEnd}) => (timeStart >= ts.timeStart) && (timeEnd <= ts.timeEnd));

    if (breaks.length < 1) {
      result.push({
        ...ts,
        repeat: 'occupied'
      });
    } else {
      let start = ts.timeStart;

      breaks.forEach(({timeStart, timeEnd}) => {
        if (timeStart > start) {
          result.push({
            ...ts,
            timeStart: start,
            timeEnd: timeStart,
            repeat: 'occupied'
          });
        }

        start = timeEnd;
      });

      if (start < ts.timeEnd) {
        result.push({
          ...ts,
          timeStart: start,
          repeat: 'occupied'
        });
      }
    }
  });

  return result;
}

function _getActiveTimeSlot(timeSlots, y, freeTimeRanges) {
  const range = (freeTimeRanges || _getFreeTimeRanges(timeSlots)).find(({timeStart, timeEnd}) => {
    const start = ((timeStart / (60 * 60 * 1000)) - FIRST_HOUR) * SLOT_HEIGHT_1H;
    const end = ((timeEnd / (60 * 60 * 1000)) - FIRST_HOUR) * SLOT_HEIGHT_1H;

    return (y > start) && (y < end);
  });

  if (!range) {
    return null;
  }

  const start = ((range.timeStart / (60 * 60 * 1000)) - FIRST_HOUR) * SLOT_HEIGHT_1H;
  const end = ((range.timeEnd / (60 * 60 * 1000)) - FIRST_HOUR) * SLOT_HEIGHT_1H;

  const dy = Math.floor(y / SLOT_HEIGHT_1H) * SLOT_HEIGHT_1H;
  const dyHalf = Math.floor(y / 36) * 36;

  let top = start;

  if ((dy >= start) && (dy <= end)) {
    top = dy;
  } else if ((dyHalf >= start) && (dyHalf <= end)) {
    top = dyHalf;
  }

  const height = Math.min(end - top, SLOT_HEIGHT_1H);

  return {
    timeStart: ((top / SLOT_HEIGHT_1H) + FIRST_HOUR) * (60 * 60 * 1000),
    timeEnd: (((top + height) / SLOT_HEIGHT_1H) + FIRST_HOUR) * (60 * 60 * 1000)
  };
}

function _getAddTimeSlotButtonStyle(timeSlots, y) {
  const activeTimeSlot = _getActiveTimeSlot(timeSlots, y);

  if (!activeTimeSlot) {
    return null;
  }

  return {
    top: `${((activeTimeSlot.timeStart / (60 * 60 * 1000)) - FIRST_HOUR) * SLOT_HEIGHT_1H}px`,
    height: `${((activeTimeSlot.timeEnd - activeTimeSlot.timeStart) / (60 * 60 * 1000)) * SLOT_HEIGHT_1H}px`
  };
}

@withDialog({
  propName: 'createScheduleDialog',
  submitButtonText: 'Create Schedule'
})
@withDialog({
  propName: 'updateScheduleDialog',
  submitButtonText: 'Update Schedule'
})
@withDialog({
  propName: 'addTimeSlotDialog',
  submitButtonText: 'Add Time Slot'
})
@withDialog({
  propName: 'editTimeSlotDialog',
  submitButtonText: 'Edit Time Slot'
})
@withCurrentDate()
class SchedulesPage extends React.Component {
  constructor(props) {
    super(props);

    this._verticalLineRefs = {};

    this.state = {
      selectedScheduleId: null,
      currentWeekFirstDay: _getWeekFirstDay(),
      hoveredDayOfWeek: null,
      isCreatingSchedule: false,
      isUpdatingSchedule: false,
      createScheduleFormHasErrors: false,
      updateScheduleFormHasErrors: false,
      addTimeSlotFormHasErrors: false,
      editTimeSlotFormHasErrors: false
    };
  }

  render() {
    const {createScheduleDialog, updateScheduleDialog, addTimeSlotDialog, editTimeSlotDialog, isFetching} = this.props;
    const {selectedScheduleId} = this.state;

    return (
      <Page title="Schedules" isLoading={isFetching}>
        <div className={styles.content}>
          <FolderView
            entityType={TreeView.ENTITY_TYPE.SCHEDULE}
            createEntityText="Create a Schedule"
            selectedEntityId={selectedScheduleId}
            userClassName={styles.sidebar}
            checkEntityLiveCallback={this._checkEntityLive}
            onEntitySelect={this._handleEntitySelect}
            onEntityCreateRequested={this._handleEntityCreateRequested}
          />
          {
            selectedScheduleId ? this._renderSchedule() : (
              <EntityPlaceholder text="Select a schedule to view"/>
            )
          }
          {
            createScheduleDialog.isDialogOpen && this._renderCreateScheduleDialog()
          }
          {
            updateScheduleDialog.isDialogOpen && this._renderUpdateScheduleDialog()
          }
          {
            addTimeSlotDialog.isDialogOpen && this._renderAddTimeSlotDialog()
          }
          {
            editTimeSlotDialog.isDialogOpen && this._renderEditTimeSlotDialog()
          }
        </div>
      </Page>
    );
  }

  _renderSchedule() {
    const {updateScheduleDialog, currentDate, schedules, folders, screens, role} = this.props;
    const {selectedScheduleId, currentWeekFirstDay} = this.state;

    const schedule = schedules.find(item => item.id === selectedScheduleId);

    const menuItems = [{
      title: 'Edit Details',
      onClick: updateScheduleDialog.openDialog
    }, {
      title: 'Pause Schedule'
    }, 'separator', {
      title: 'Delete Schedule',
      secondary: true,
      onClick: this._deleteSchedule
    }];
    const badges = [];

    if (isScheduleActive(schedule.id, schedules, currentDate)) {
      badges.push({
        type: 'live',
        text: 'LIVE'
      });
    }

    if (schedule.folderId) {
      badges.push({
        type: 'folder',
        text: folders.find(fld => fld.id === schedule.folderId).name
      });
    }

    badges.push({
      type: 'screen',
      text: (schedule.screenIds.length > 1) ?
        `${schedule.screenIds.length} screens` :
        screens.find(scr => scr.id === schedule.screenIds[0]).name
    });

    const daysOfWeek = _createDaysOfWeek(currentWeekFirstDay);

    const scheduleDataStyle = {height: `${120 + ((LAST_HOUR - FIRST_HOUR) * SLOT_HEIGHT_1H)}px`};

    return (
      <div className={styles.schedule}>
        <div className={styles['schedule-info']}>
          <EntityInfo
            disabled={role === 'user'}
            name={schedule.name}
            description={schedule.description}
            menuItems={menuItems}
            badges={badges}
            contentRight={this._renderScheduleWeekSwitch()}
          />
        </div>
        <div className={styles['schedule-data-wrapper']}>
          <div className={styles['schedule-data']} style={scheduleDataStyle}>
            <div className={styles['schedule-data-header']}>
              {
                daysOfWeek.map(this._renderScheduleDataHeaderItem)
              }
            </div>
            <div className={styles['schedule-data-body']}>
              <div className={styles['schedule-data-time-scale-wrapper']}>
                <div className={styles['schedule-data-time-scale']}>
                  {
                    _createHours().map(this._renderTime)
                  }
                </div>
              </div>
              <div className={styles['schedule-data-lines-vertical']}>
                {
                  daysOfWeek.map(this._renderScheduleDataLineVertical)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderScheduleWeekSwitch() {
    const {currentWeekFirstDay} = this.state;

    return (
      <div className={styles['week-switch']}>
        <div className={styles['week-range']}>
          {_getWeekString(currentWeekFirstDay)}
        </div>
        <div className={styles['week-switch-buttons']}>
          <div className={styles['week-switch-button']} onClick={this._previousWeek}>
            <Icon type={Icon.TYPE.CHEVRON_LEFT} userClassName={styles['switch-button-icon']}/>
          </div>
          <div className={styles['week-switch-button-separator']}/>
          <div className={styles['week-switch-button']} onClick={this._nextWeek}>
            <Icon type={Icon.TYPE.CHEVRON_RIGHT} userClassName={styles['switch-button-icon']}/>
          </div>
        </div>
      </div>
    );
  }

  _renderScheduleDataHeaderItem = dow => {
    const itemClassNames = classNames({
      [styles['schedule-data-header-item']]: true,
      [styles['schedule-data-header-item-current']]: dow.getDate() === (new Date()).getDate()
    });

    return (
      <div key={dow.getDay()} className={itemClassNames}>
        <div className={styles['schedule-data-header-item-dow']}>
          {_ld(dow, {weekday: 'short'})}
        </div>
        <div className={styles['schedule-data-header-item-date']}>
          {_ld(dow, {day: 'numeric'})}
        </div>
      </div>
    );
  };

  _renderTime = (date, index) => {
    return (
      <div key={`${date.getHours()}-${index}`} className={styles['schedule-data-line-time']}>
        {_ld(date, {hour: 'numeric', hour12: true})}
      </div>
    );
  };

  _renderScheduleDataLineVertical = dow => {
    const {schedules, role} = this.props;
    const {selectedScheduleId, hoveredDayOfWeek} = this.state;

    const isReadOnly = role === 'user';

    const schedule = schedules.find(item => item.id === selectedScheduleId);

    const hours = _createHours(dow);
    const timeSlots = schedule.timeSlots
      .map((ts, index) => ({...ts, index}))
      .filter(_filterTimeSlots(dow));
    const relevantSchedules = schedules.filter(scd => {
      return (scd.id !== selectedScheduleId) && scd.screenIds.some(id => schedule.screenIds.includes(id));
    });
    timeSlots.push(..._getOccupiedTimeSlots(timeSlots, relevantSchedules, dow, new Date()));
    const addTimeSlotButtonStyle = hoveredDayOfWeek && (hoveredDayOfWeek.dow.getDay() === dow.getDay()) &&
      _getAddTimeSlotButtonStyle(timeSlots, hoveredDayOfWeek.y);

    const scheduleDataLineVerticalStyle = {height: `${(LAST_HOUR - FIRST_HOUR) * SLOT_HEIGHT_1H}px`};

    return (
      <div
        key={`${selectedScheduleId}-${dow.getDay()}`}
        ref={this._setVerticalLineRef(dow)}
        className={styles['schedule-data-line-vertical']}
        style={scheduleDataLineVerticalStyle}
        onMouseEnter={isReadOnly ? null : this._handleVerticalLineMouseEnter(dow)}
        onMouseMove={isReadOnly ? null : this._handleVerticalLineMouseMove(dow)}
        onMouseLeave={isReadOnly ? null : this._handleVerticalLineMouseLeave}
      >
        {
          hours.slice(1).map(this._renderScheduleDataLineHorizontal)
        }
        {
          addTimeSlotButtonStyle && (
            <div className={styles['schedule-data-time-slot-wrapper']} style={addTimeSlotButtonStyle}>
              <div className={styles['schedule-data-add-time-slot-button']} onClick={this._addTimeSlot(timeSlots)}>
                <Icon type={Icon.TYPE.PLUS} userClassName={styles['schedule-data-add-time-slot-button-icon']}/>
              </div>
            </div>
          )
        }
        {
          timeSlots.map(this._renderTimeSlot(timeSlots, dow))
        }
      </div>
    );
  };

  _renderScheduleDataLineHorizontal = (date, index) => {
    const lineStyle = {
      top: `${index * SLOT_HEIGHT_1H}px`
    };

    return (
      <div key={`${date.getHours()}-${index}`} className={styles['schedule-data-line-horizontal']} style={lineStyle}/>
    );
  };

  _renderTimeSlot = (timeSlots, dayOfWeek) => (timeSlot, index) => {
    const {campaigns, role} = this.props;
    const {selectedScheduleId} = this.state;

    const {campaignId, repeat, timeStart, timeEnd} = timeSlot;

    const isReadOnly = role === 'user';

    const campaign = campaigns.find(item => item.id === campaignId);

    const start = (timeStart / (60 * 60 * 1000)) - FIRST_HOUR;
    const size = (timeEnd - timeStart) / (60 * 60 * 1000);

    const isRepeatable = ['daily', 'weekly'].includes(repeat);
    const isOccupied = repeat === 'occupied';

    const itemClassNames = classNames({
      [styles['schedule-data-time-slot-wrapper']]: true,
      [styles['schedule-data-time-slot-wrapper-read-only']]: isReadOnly,
      [styles['schedule-data-time-slot-wrapper-repeat']]: isRepeatable,
      [styles['schedule-data-time-slot-wrapper-occupied']]: isOccupied
    });
    const itemStyle = {
      top: `${start * SLOT_HEIGHT_1H}px`,
      height: `${size * SLOT_HEIGHT_1H}px`
    };

    return (
      <MenuContainer
        key={`${selectedScheduleId}-${dayOfWeek.getDay()}-${timeSlot.index || index}`}
        position={MenuContainer.POSITION.TOP_LEFT}
        renderButton={toggle => {
          return (
            <Tooltip
              tooltip={`${campaign.name} (${_formatTime(timeStart)} – ${_formatTime(timeEnd)})`}
              userClassName={styles['schedule-data-time-slot']}
              onClick={(isReadOnly || isOccupied) ? null : toggle}
            >
              <div className={styles['schedule-data-time-slot-content']}>
                {
                  !isOccupied && (
                    <>
                      <div className={styles['schedule-data-time-slot-info']}>
                        <div className={styles['schedule-data-time-slot-campaign-name']}>
                          {campaign.name}
                        </div>
                        {
                          isRepeatable && (
                            <div className={styles['schedule-data-time-slot-campaign-repeat-mode']}>
                              {`Repeat ${capitalizeFirstLetter(repeat)}`}
                            </div>
                          )
                        }
                      </div>
                      {
                        isRepeatable && (
                          <Icon type={Icon.TYPE.CYCLE} userClassName={styles['schedule-data-time-slot-icon']}/>
                        )
                      }
                    </>
                  )
                }
              </div>
            </Tooltip>
          );
        }}
        renderMenu={() => {
          return (
            <div className={styles['time-slot-menu']}>
              <div
                className={styles['time-slot-menu-item']}
                onClick={this._editTimeSlot(timeSlots, dayOfWeek, timeSlot)}
              >
                Edit
              </div>
              <div
                className={`${styles['time-slot-menu-item']} ${styles['time-slot-menu-item-secondary']}`}
                onClick={this._deleteTimeSlot(timeSlot)}
              >
                Delete
              </div>
            </div>
          );
        }}
        userClassName={itemClassNames}
        userStyle={itemStyle}
      />
    );
  };

  _renderCreateScheduleDialog() {
    const {createScheduleDialog} = this.props;
    const {isCreatingSchedule} = this.state;

    return createScheduleDialog.renderDialog({
      renderTitle: () => 'Create Schedule',
      renderContent: this._renderCreateScheduleDialogContent,
      submitCallback: this._submitScheduleForm,
      closeDisabledCallback: () => isCreatingSchedule,
      submitDisabledCallback: () => {
        const {isCreatingSchedule, createScheduleFormHasErrors} = this.state;

        return isCreatingSchedule || createScheduleFormHasErrors;
      }
    });
  }

  _renderCreateScheduleDialogContent = () => {
    const {createScheduleDialog, folders, organisations, screens} = this.props;
    const {isCreatingSchedule} = this.state;

    const {folderId} = createScheduleDialog.dialogData;

    const defaultValues = {
      ...getScheduleDefaultValues(),
      folderId
    };

    return (
      <Form
        disabled={isCreatingSchedule}
        fields={getCreateScheduleFormFields({folders, organisations, screens})}
        defaultValues={defaultValues}
        userClassName={styles['schedule-dialog-form']}
        submitCallbackRef={this._setSubmitCallbackRef}
        onChange={this._handleCreateScheduleFormChange}
        onSubmit={this._createSchedule}
        onSubmitFailure={handleSubmitFailure(getCreateScheduleErrors)}
      />
    );
  };

  _renderUpdateScheduleDialog() {
    const {updateScheduleDialog} = this.props;
    const {isUpdatingSchedule} = this.state;

    return updateScheduleDialog.renderDialog({
      renderTitle: () => 'Update Schedule',
      renderContent: this._renderUpdateScheduleDialogContent,
      submitCallback: this._submitScheduleForm,
      closeDisabledCallback: () => isUpdatingSchedule,
      submitDisabledCallback: () => {
        const {isUpdatingSchedule, updateScheduleFormHasErrors} = this.state;

        return isUpdatingSchedule || updateScheduleFormHasErrors;
      }
    });
  }

  _renderUpdateScheduleDialogContent = () => {
    const {schedules, folders, organisations, screens} = this.props;
    const {selectedScheduleId, isUpdatingSchedule} = this.state;

    const schedule = schedules.find(item => item.id === selectedScheduleId);

    const defaultValues = getScheduleDefaultValues(schedule);
    const fields = getEditScheduleFormFields({
      folders,
      organisations,
      screens,
      schedules,
      defaultScreenIds: defaultValues.screenIds
    });

    return (
      <Form
        disabled={isUpdatingSchedule}
        fields={fields}
        defaultValues={defaultValues}
        userClassName={styles['schedule-dialog-form']}
        submitCallbackRef={this._setSubmitCallbackRef}
        onChange={this._handleUpdateScheduleFormChange}
        onSubmit={this._updateSchedule}
        onSubmitFailure={handleSubmitFailure(getEditScheduleErrors)}
      />
    );
  };

  _renderAddTimeSlotDialog() {
    const {addTimeSlotDialog} = this.props;
    const {isUpdatingSchedule} = this.state;

    return addTimeSlotDialog.renderDialog({
      renderTitle: () => 'Add Time Slot',
      renderContent: this._renderAddTimeSlotDialogContent,
      submitCallback: this._submitScheduleForm,
      closeDisabledCallback: () => isUpdatingSchedule,
      submitDisabledCallback: () => {
        const {isUpdatingSchedule, addTimeSlotFormHasErrors} = this.state;

        return isUpdatingSchedule || addTimeSlotFormHasErrors;
      }
    });
  }

  _renderAddTimeSlotDialogContent = () => {
    const {addTimeSlotDialog, folders, organisations, campaigns} = this.props;
    const {isUpdatingSchedule} = this.state;

    const {dayOfWeek, timeSlots, freeTimeRanges, timeStart, timeEnd} = addTimeSlotDialog.dialogData;

    const fields = getAddTimeSlotFormFields({
      folders,
      organisations,
      campaigns,
      freeTimeRanges,
      firstHour: FIRST_HOUR,
      lastHour: LAST_HOUR
    });

    return (
      <Form
        disabled={isUpdatingSchedule}
        fields={fields}
        defaultValues={getTimeSlotDefaultValues(null, timeStart.toString(), timeEnd.toString(), dayOfWeek)}
        userClassName={styles['time-slot-dialog-form']}
        submitCallbackRef={this._setSubmitCallbackRef}
        validate={validateTimeSlot({timeSlots, freeTimeRanges, currentDate: new Date()})}
        onChange={this._handleAddTimeSlotFormChange}
        onSubmit={this._handleAddTimeSlotFormSubmit}
        onSubmitFailure={handleSubmitFailure(getAddTimeSlotErrors)}
      />
    );
  };

  _renderEditTimeSlotDialog() {
    const {editTimeSlotDialog} = this.props;
    const {isUpdatingSchedule} = this.state;

    return editTimeSlotDialog.renderDialog({
      renderTitle: () => 'Edit Time Slot',
      renderContent: this._renderEditTimeSlotDialogContent,
      submitCallback: this._submitScheduleForm,
      closeDisabledCallback: () => isUpdatingSchedule,
      submitDisabledCallback: () => {
        const {isUpdatingSchedule, editTimeSlotFormHasErrors} = this.state;

        return isUpdatingSchedule || editTimeSlotFormHasErrors;
      }
    });
  }

  _renderEditTimeSlotDialogContent = () => {
    const {editTimeSlotDialog, folders, organisations, campaigns} = this.props;
    const {selectedScheduleId, isUpdatingSchedule} = this.state;

    const {timeSlot, timeSlots, freeTimeRanges, freeTimeRangesNonWeek, dayOfWeek} = editTimeSlotDialog.dialogData;

    const fields = getEditTimeSlotFormFields({
      folders,
      organisations,
      campaigns,
      freeTimeRanges: freeTimeRangesNonWeek,
      firstHour: FIRST_HOUR,
      lastHour: LAST_HOUR
    });

    return (
      <Form
        key={`${selectedScheduleId}-${dayOfWeek.getDay()}-${timeSlot.index}`}
        disabled={isUpdatingSchedule}
        fields={fields}
        defaultValues={getTimeSlotDefaultValues(timeSlot)}
        userClassName={styles['time-slot-dialog-form']}
        submitCallbackRef={this._setSubmitCallbackRef}
        validate={validateTimeSlot({timeSlots, freeTimeRanges, freeTimeRangesNonWeek, currentDate: new Date()})}
        onChange={this._handleEditTimeSlotFormChange}
        onSubmit={this._handleEditTimeSlotFormSubmit}
        onSubmitFailure={handleSubmitFailure(getEditTimeSlotErrors)}
      />
    );
  };

  _handleEntitySelect = id => {
    this.setState({
      selectedScheduleId: id,
      currentWeekFirstDay: _getWeekFirstDay()
    });
  };

  _checkEntityLive = ({id}, currentDate) => {
    const {schedules} = this.props;

    return isScheduleActive(id, schedules, currentDate);
  };

  _handleEntityCreateRequested = folderId => {
    const {createScheduleDialog} = this.props;

    return new Promise((resolve, reject) => {
      createScheduleDialog.openDialog({resolve, reject, folderId});
    });
  };

  _nextWeek = () => {
    this.setState(({currentWeekFirstDay}) => {
      const date = new Date(currentWeekFirstDay);

      date.setDate(date.getDate() + 7);

      return {currentWeekFirstDay: date};
    });
  };

  _previousWeek = () => {
    this.setState(({currentWeekFirstDay}) => {
      const date = new Date(currentWeekFirstDay);

      date.setDate(date.getDate() - 7);

      return {currentWeekFirstDay: date};
    });
  };

  _handleVerticalLineMouseEnter = dow => e => {
    const {y} = getRelativeCoordinates(this._verticalLineRefs[dow.getDay()], e);

    this.setState({
      hoveredDayOfWeek: {dow, y}
    });
  };

  _handleVerticalLineMouseMove = dow => e => {
    const {y} = getRelativeCoordinates(this._verticalLineRefs[dow.getDay()], e);

    this.setState(state => {
      const {hoveredDayOfWeek} = state;

      if (!hoveredDayOfWeek || (hoveredDayOfWeek.dow.getDay() !== dow.getDay())) {
        return state;
      }

      return {
        hoveredDayOfWeek: {dow, y}
      };
    });
  };

  _handleVerticalLineMouseLeave = () => {
    this.setState({hoveredDayOfWeek: null});
  };

  _addTimeSlot = timeSlots => () => {
    const {addTimeSlotDialog, schedules} = this.props;
    const {selectedScheduleId, currentWeekFirstDay, hoveredDayOfWeek} = this.state;

    const freeTimeRanges = _getFreeTimeRanges(timeSlots, new Date());
    const {timeStart, timeEnd} = _getActiveTimeSlot(timeSlots, hoveredDayOfWeek.y, freeTimeRanges);

    const schedule = schedules.find(item => item.id === selectedScheduleId);

    const weekTimeSlots = schedule.timeSlots;
    const relevantSchedules = schedules.filter(scd => {
      return (scd.id !== selectedScheduleId) && scd.screenIds.some(id => schedule.screenIds.includes(id));
    });
    _createDaysOfWeek(currentWeekFirstDay).forEach(dow => {
      weekTimeSlots.push(..._getOccupiedTimeSlots(timeSlots, relevantSchedules, dow, new Date()));
    });

    addTimeSlotDialog.openDialog({
      freeTimeRanges,
      timeStart,
      timeEnd,
      timeSlots: weekTimeSlots,
      dayOfWeek: hoveredDayOfWeek.dow
    });
  };

  _editTimeSlot = (timeSlots, dayOfWeek, timeSlot) => () => {
    const {editTimeSlotDialog, schedules} = this.props;
    const {selectedScheduleId, currentWeekFirstDay} = this.state;

    const schedule = schedules.find(item => item.id === selectedScheduleId);

    const weekTimeSlots = schedule.timeSlots;

    const relevantSchedules = schedules.filter(scd => {
      return (scd.id !== selectedScheduleId) && scd.screenIds.some(id => schedule.screenIds.includes(id));
    });
    _createDaysOfWeek(currentWeekFirstDay).forEach(dow => {
      weekTimeSlots.push(..._getOccupiedTimeSlots(weekTimeSlots, relevantSchedules, dow, new Date()));
    });

    const freeTimeRanges = _mergeTimeSlots(_getFreeTimeRanges(weekTimeSlots, new Date()).concat({
      timeStart: timeSlot.timeStart,
      timeEnd: timeSlot.timeEnd
    }));

    const freeTimeRangesNonWeek = _mergeTimeSlots(_getFreeTimeRanges(timeSlots, new Date()).concat({
      timeStart: timeSlot.timeStart,
      timeEnd: timeSlot.timeEnd
    }));

    editTimeSlotDialog.openDialog({
      freeTimeRanges,
      dayOfWeek,
      timeSlot,
      freeTimeRangesNonWeek,
      timeSlots: weekTimeSlots
    });
  };

  _deleteTimeSlot = timeSlot => () => {
    const {schedules, updateSchedule} = this.props;
    const {selectedScheduleId} = this.state;

    const schedule = schedules.find(item => item.id === selectedScheduleId);

    // eslint-disable-next-line no-alert
    if (!confirm('Are you sure?')) {
      return;
    }

    this.setState({isUpdatingSchedule: true}, async () => {
      try {
        const newTimeSlots = [...schedule.timeSlots];

        newTimeSlots.splice(timeSlot.index, 1);

        await updateSchedule(selectedScheduleId, {
          timeSlots: newTimeSlots
        });
      } catch (err) {
        console.warn(err);
      }

      this.setState({isUpdatingSchedule: false});
    });
  };

  _createSchedule = ({name, description, folderId, screenIds}) => {
    const {createScheduleDialog, createSchedule} = this.props;

    const {dialogData} = createScheduleDialog;

    return new Promise((resolve, reject) => {
      this.setState({isCreatingSchedule: true}, async () => {
        try {
          const organisationSpecified = Boolean(folderId) && folderId.startsWith('organisation-');

          const data = {
            name,
            description,
            screenIds,
            folderId: organisationSpecified ? null : folderId,
            timeSlots: []
          };

          if (organisationSpecified) {
            data.organisationId = folderId.match(/^organisation-(.+)/)[1];
          }

          const {item} = await createSchedule(data);

          createScheduleDialog.closeDialog();

          setTimeout(() => {
            dialogData.resolve(item);

            this.setState({
              isCreatingSchedule: false,
              selectedScheduleId: item.id
            }, resolve);
          }, 0);
        } catch (err) {
          dialogData.reject(err);

          this.setState({isCreatingSchedule: false}, reject);
        }
      });
    });
  };

  _updateSchedule = ({folderId, ...rest}) => {
    const {updateScheduleDialog, updateSchedule} = this.props;
    const {selectedScheduleId} = this.state;

    return new Promise((resolve, reject) => {
      this.setState({isUpdatingSchedule: true}, async () => {
        try {
          const data = {
            ...rest,
            folderId: (folderId && folderId.startsWith('organisation-')) ? null : folderId
          };

          await updateSchedule(selectedScheduleId, data);

          updateScheduleDialog.closeDialog();

          this.setState({isUpdatingSchedule: false}, resolve);
        } catch (err) {
          this.setState({isUpdatingSchedule: false}, reject);
        }
      });
    });
  };

  _deleteSchedule = () => {
    const {deleteSchedule} = this.props;
    const {selectedScheduleId} = this.state;

    // eslint-disable-next-line no-alert
    if (!confirm('Are you sure?')) {
      return;
    }

    this.setState({selectedScheduleId: null}, async () => {
      try {
        await deleteSchedule(selectedScheduleId);
      } catch (err) {
        console.error(err);

        this.setState({selectedScheduleId});
      }
    });
  };

  _handleAddTimeSlotFormSubmit = ({campaignId, date, timeStart, timeEnd, repeat}) => {
    const {addTimeSlotDialog, schedules, updateSchedule} = this.props;
    const {selectedScheduleId} = this.state;

    const {dayOfWeek} = addTimeSlotDialog.dialogData;

    const schedule = schedules.find(item => item.id === selectedScheduleId);

    const data = {
      campaignId,
      repeat,
      timeStart: Number(timeStart),
      timeEnd: Number(timeEnd)
    };

    if (repeat === 'once') {
      data.date = new Date(date);
      data.date.setHours(0);
      data.date.setMinutes(0);
      data.date.setSeconds(0);
      data.date.setMilliseconds(0);
    }

    if (repeat === 'weekly') {
      data.dayOfWeek = dayOfWeek.getDay();
    }

    return new Promise((resolve, reject) => {
      this.setState({isUpdatingSchedule: true}, async () => {
        try {
          await updateSchedule(selectedScheduleId, {
            timeSlots: [...schedule.timeSlots, data]
          });

          addTimeSlotDialog.closeDialog();

          this.setState({isUpdatingSchedule: false}, resolve);
        } catch (err) {
          this.setState({isUpdatingSchedule: false}, reject);
        }
      });
    });
  };

  _handleEditTimeSlotFormSubmit = ({campaignId, date, timeStart, timeEnd, repeat}) => {
    const {editTimeSlotDialog, schedules, updateSchedule} = this.props;
    const {selectedScheduleId} = this.state;

    const {dayOfWeek, timeSlot} = editTimeSlotDialog.dialogData;

    const schedule = schedules.find(item => item.id === selectedScheduleId);

    const data = {
      campaignId,
      repeat,
      timeStart: Number(timeStart),
      timeEnd: Number(timeEnd)
    };

    if (repeat === 'once') {
      data.date = new Date(date);
      data.date.setHours(0);
      data.date.setMinutes(0);
      data.date.setSeconds(0);
      data.date.setMilliseconds(0);
    }

    if (repeat === 'weekly') {
      data.dayOfWeek = dayOfWeek.getDay();
    }

    return new Promise((resolve, reject) => {
      this.setState({isUpdatingSchedule: true}, async () => {
        try {
          const newTimeSlots = [...schedule.timeSlots];

          newTimeSlots.splice(timeSlot.index, 1, data);

          await updateSchedule(selectedScheduleId, {
            timeSlots: newTimeSlots
          });

          editTimeSlotDialog.closeDialog();

          this.setState({isUpdatingSchedule: false}, resolve);
        } catch (err) {
          this.setState({isUpdatingSchedule: false}, reject);
        }
      });
    });
  };

  _handleCreateScheduleFormChange = ({errors}) => {
    this.setState({createScheduleFormHasErrors: Boolean(errors)});
  };

  _handleUpdateScheduleFormChange = ({errors}) => {
    this.setState({updateScheduleFormHasErrors: Boolean(errors)});
  };

  _handleAddTimeSlotFormChange = ({errors}) => {
    this.setState({addTimeSlotFormHasErrors: Boolean(errors)});
  };

  _handleEditTimeSlotFormChange = ({errors}) => {
    this.setState({editTimeSlotFormHasErrors: Boolean(errors)});
  };

  _submitScheduleForm = () => {
    if (!this.submitCallback) {
      return;
    }

    this.submitCallback();
  };

  _setVerticalLineRef = dow => el => {
    this._verticalLineRefs[dow.getDay()] = el;
  };

  _setSubmitCallbackRef = callback => {
    this.submitCallback = callback;
  };
}

SchedulesPage.WrappedComponent.WrappedComponent.WrappedComponent.WrappedComponent.WrappedComponent.propTypes = {
  createScheduleDialog: PropTypes.shape(DIALOG_SHAPE).isRequired,
  updateScheduleDialog: PropTypes.shape(DIALOG_SHAPE).isRequired,
  addTimeSlotDialog: PropTypes.shape(DIALOG_SHAPE).isRequired,
  editTimeSlotDialog: PropTypes.shape(DIALOG_SHAPE).isRequired,
  currentDate: PropTypes.object.isRequired
};

SchedulesPage.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  schedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
  organisations: PropTypes.arrayOf(PropTypes.object),
  screens: PropTypes.arrayOf(PropTypes.object).isRequired,
  createSchedule: PropTypes.func.isRequired,
  deleteSchedule: PropTypes.func.isRequired,
  updateSchedule: PropTypes.func.isRequired
};

SchedulesPage.defaultProps = {
  organisations: null
};

export default SchedulesPage;
