import {put, takeLatest} from 'redux-saga/effects';

import {API} from '../action-types';

import {
  api as apiActions
} from '../actions';

function * handleLogin(action) {
  const id = action.payload.response.organisationId;

  yield put(apiActions.organisation.getSingle({data: {id}}));
}

export default function * login() {
  yield takeLatest(API.AUTH.LOGIN.SUCCESS, handleLogin);
}
