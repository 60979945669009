import {call, put, actionChannel, take} from 'redux-saga/effects';

import {STATE, STATES, API_NAMESPACE_NAME, request} from '../../lib/api';

function _testAction({type}) {
  return type.startsWith(`${API_NAMESPACE_NAME}/`) && STATES.every(state => !type.endsWith(`_${state}`));
}

export function * makeApiRequest(action) {
  const {type, payload} = action;

  yield put({
    type: `${type}_${STATE.PENDING}`,
    payload: {
      requestAction: action
    }
  });

  try {
    const response = yield call(request, payload);

    try {
      yield put({
        type: `${type}_${STATE.SUCCESS}`,
        payload: {
          response,
          requestAction: action
        }
      });
    } catch (err) {
      console.warn('Error while dispatching success', `${type}_${STATE.SUCCESS}`, err);
    }
  } catch (error) {
    try {
      yield put({
        type: `${type}_${STATE.ERROR}`,
        payload: {
          error,
          requestAction: action
        }
      });
    } catch (err) {
      console.warn('Error while dispatching error', `${type}_${STATE.SUCCESS}`, err);
    }
  }
}

export default function * api() {
  const channel = yield actionChannel(_testAction);

  for (;;) {
    const action = yield take(channel);

    yield call(makeApiRequest, action);
  }
}
