export default {
  dialogPaper: {
    width: 'calc(100vw - 80px)',
    maxWidth: 620,
    borderRadius: 10
  },
  dialogPaperExpanded: {
    width: 'calc(100vw - 80px)',
    maxWidth: 1200,
    borderRadius: 10
  },
  dialogPaperFullScreen: {
    width: '100%',
    maxWidth: '100%',
    borderRadius: 0
  }
};
