import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {createDispatchPromise} from '../../../lib/api';
import {parseHref} from '../../../lib/history';

import {api} from '../../../redux/actions';

import Component from './component.jsx';

function mapStateToProps(state) {
  const {isRegistering} = state.auth.registration;

  const {token, organisationName, email, userName} = parseHref().query;

  return {isRegistering, token, organisationName, email, userName};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    register: api.auth.register
  }, dispatch);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {token} = stateProps;

  return {
    ...ownProps,
    ...stateProps,
    register: data => {
      return createDispatchPromise(dispatchProps.register, {data: {...data, token}});
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);
