import React from 'react';
import * as PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.less';

const ICON_TYPES = {
  CAMERA: 'camera',
  CHEVRON_DOWN: 'chevron_down',
  CHEVRON_LEFT: 'chevron_left',
  CHEVRON_RIGHT: 'chevron_right',
  CROSS: 'cross',
  CYCLE: 'cycle',
  EDITOR_MODE_IMAGE: 'editor_mode_image',
  EDITOR_MODE_LAYOUT: 'editor_mode_layout',
  EDITOR_MODE_TEXT: 'editor_mode_text',
  EDITOR_MODE_VIDEO: 'editor_mode_video',
  EDITOR_MULTI_SELECT: 'editor_multi_select',
  EDITOR_TEXT_ALIGN_CENTER: 'editor_text_align_center',
  EDITOR_TEXT_ALIGN_JUSTIFY: 'editor_text_align_justify',
  EDITOR_TEXT_ALIGN_LEFT: 'editor_text_align_left',
  EDITOR_TEXT_ALIGN_RIGHT: 'editor_text_align_right',
  EDITOR_TEXT_STYLE_ITALIC: 'editor_text_style_italic',
  EDITOR_TEXT_STYLE_STRIKEOUT: 'editor_text_style_strikeout',
  EDITOR_TEXT_STYLE_UNDERLINED: 'editor_text_style_underlined',
  EDITOR_UPLOAD: 'editor_upload',
  FOLDER: 'folder',
  FOLDER_ADD: 'folder_add',
  INFO: 'info',
  OPTIONS: 'options',
  PLUS: 'plus',
  SCREEN: 'screen',
  TRIANGLE_DOWN: 'triangle_down',
  TRIANGLE_RIGHT: 'triangle_right'
};

const ICONS = new Map([
  [ICON_TYPES.CAMERA, require('../../../assets/img/icons/camera.svg')],
  [ICON_TYPES.CHEVRON_DOWN, {
    default: require('../../../assets/img/icons/arrow-regular-default.svg'),
    active: require('../../../assets/img/icons/arrow-regular-active.svg')
  }],
  [ICON_TYPES.CHEVRON_LEFT, require('../../../assets/img/icons/side-arrow-large-previous.svg')],
  [ICON_TYPES.CHEVRON_RIGHT, require('../../../assets/img/icons/side-arrow-large-next.svg')],
  [ICON_TYPES.CROSS, require('../../../assets/img/icons/icon-tag-remove.svg')],
  [ICON_TYPES.CYCLE, require('../../../assets/img/icons/icon-repeated-schedule.svg')],
  [ICON_TYPES.EDITOR_MODE_IMAGE, require('../../../assets/img/icons/editor-icon-add-image.svg')],
  [ICON_TYPES.EDITOR_MODE_LAYOUT, require('../../../assets/img/icons/editor-icon-add-frame.svg')],
  [ICON_TYPES.EDITOR_MODE_TEXT, require('../../../assets/img/icons/editor-icon-add-text.svg')],
  [ICON_TYPES.EDITOR_MODE_VIDEO, require('../../../assets/img/icons/editor-icon-add-video.svg')],
  [ICON_TYPES.EDITOR_MULTI_SELECT, {
    default: require('../../../assets/img/icons/editor-icon-multi-select-default.svg'),
    active: require('../../../assets/img/icons/editor-icon-multi-select-active.svg'),
    disabled: require('../../../assets/img/icons/editor-icon-multi-select-disabled.svg')
  }],
  [ICON_TYPES.EDITOR_TEXT_ALIGN_CENTER, require('../../../assets/img/icons/editor-icon-text-align-centre.svg')],
  [ICON_TYPES.EDITOR_TEXT_ALIGN_JUSTIFY, require('../../../assets/img/icons/editor-icon-text-align-justified.svg')],
  [ICON_TYPES.EDITOR_TEXT_ALIGN_LEFT, require('../../../assets/img/icons/editor-icon-text-align-left.svg')],
  [ICON_TYPES.EDITOR_TEXT_ALIGN_RIGHT, require('../../../assets/img/icons/editor-icon-text-align-right.svg')],
  [ICON_TYPES.EDITOR_TEXT_STYLE_ITALIC, require('../../../assets/img/icons/editor-icon-text-style-italic.svg')],
  [ICON_TYPES.EDITOR_TEXT_STYLE_STRIKEOUT, require('../../../assets/img/icons/editor-icon-text-style-strikethrough.svg')],
  [ICON_TYPES.EDITOR_TEXT_STYLE_UNDERLINED, require('../../../assets/img/icons/editor-icon-text-style-underline.svg')],
  [ICON_TYPES.EDITOR_UPLOAD, require('../../../assets/img/icons/editor-icon-multi-images.svg')],
  [ICON_TYPES.FOLDER, {
    default: require('../../../assets/img/icons/icon-folder-default.svg'),
    active: require('../../../assets/img/icons/icon-folder-active.svg')
  }],
  [ICON_TYPES.FOLDER_ADD, require('../../../assets/img/icons/icon-folder-add-new.svg')],
  [ICON_TYPES.INFO, require('../../../assets/img/icons/icon-alert.svg')],
  [ICON_TYPES.OPTIONS, {
    default: require('../../../assets/img/icons/options-large-default.svg'),
    active: require('../../../assets/img/icons/options-large-active.svg'),
    disabled: require('../../../assets/img/icons/options-medium-default.svg')
  }],
  [ICON_TYPES.PLUS, require('../../../assets/img/icons/icon-add-schedule.svg')],
  [ICON_TYPES.SCREEN, require('../../../assets/img/icons/icon-screen-default.svg')],
  [ICON_TYPES.TRIANGLE_DOWN, {
    default: require('../../../assets/img/icons/folder-arrow-open-default.svg'),
    active: require('../../../assets/img/icons/folder-arrow-open-active.svg')
  }],
  [ICON_TYPES.TRIANGLE_RIGHT, {
    default: require('../../../assets/img/icons/folder-arrow-closed-default.svg'),
    active: require('../../../assets/img/icons/folder-arrow-closed-active.svg')
  }]
]);

class Icon extends React.Component {
  static get TYPE() {
    return ICON_TYPES;
  }

  render() {
    const {type, active, disabled, userClassName, userStyle} = this.props;

    const iconClassNames = classNames({
      [styles.icon]: true,
      [userClassName]: Boolean(userClassName)
    });

    const icons = ICONS.get(type);
    const icon = (active && icons.active) || (disabled && icons.disabled) || (icons.default || icons);

    return (
      <img src={icon} className={iconClassNames} style={userStyle}/>
    );
  }
}

Icon.propTypes = {
  type: PropTypes.oneOf(Object.values(Icon.TYPE)).isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  userClassName: PropTypes.string,
  userStyle: PropTypes.string
};

Icon.defaultProps = {
  active: false,
  disabled: false,
  userClassName: null,
  userStyle: null
};

export default Icon;
