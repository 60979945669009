import {eventChannel, END} from 'redux-saga';
import {put, select, take, takeLatest} from 'redux-saga/effects';

import {API_INITIALIZED, subscribeToRealtimeUpdates} from '../../lib/api';

import {API} from '../action-types';
import {campaigns, folders, organisations, schedules, screens} from '../actions';

const ACTION_MAP = new Map([
  ['campaigns', campaigns],
  ['folders', folders],
  ['organisations', organisations],
  ['schedules', schedules],
  ['screens', screens]
]);

const COLLECTION_NAMES = Array.from(ACTION_MAP.keys());

const ROOT_ONLY_COLLECTIONS = new Set(['organisations']);

let subscriptions = null;

function * subscribeToUpdates() {
  const user = yield select(state => state.user.user);

  if (!user) {
    return yield;
  }

  const {role, organisationId} = user;

  const isRoot = role === 'root';

  const channel = eventChannel(emitter => {
    const unsubscribeList = COLLECTION_NAMES
      .filter(collectionName => isRoot || !ROOT_ONLY_COLLECTIONS.has(collectionName))
      .map(collectionName => {
        return subscribeToRealtimeUpdates(
          collectionName,
          role,
          organisationId,
          data => emitter({collectionName, data})
        );
      });

    subscriptions = {emitter, unsubscribeList};

    return () => {};
  });

  for (;;) {
    const {collectionName, data} = yield take(channel);
    const {type, doc, items} = data;
    const actions = ACTION_MAP.get(collectionName);

    switch (type) {
    case 'fetched':
      yield put(actions.setFetched(true));

      break;
    case 'added':
      yield put(actions.addSingle({
        id: doc.id,
        ...doc.data()
      }));

      break;
    case 'added_bulk':
      yield put(actions.addList(items));

      break;
    case 'modified':
      yield put(actions.updateSingle({
        id: doc.id,
        ...doc.data()
      }));

      break;
    case 'removed':
      yield put(actions.deleteSingle({id: doc.id}));

      break;
    default:
      break;
    }
  }
}

function * unsubscribeFromUpdates() {
  if (!subscriptions) {
    return yield;
  }

  const {emitter, unsubscribeList} = subscriptions;

  emitter(END);

  unsubscribeList.forEach(unsubscribe => unsubscribe());

  for (const collectionName of COLLECTION_NAMES) {
    const actions = ACTION_MAP.get(collectionName);

    yield put(actions.clear());
  }
}

export default function * realtimeUpdates() {
  yield takeLatest(API_INITIALIZED.ACTION, subscribeToUpdates);
  yield takeLatest(API.USER.GET_SELF.SUCCESS, subscribeToUpdates);
  yield takeLatest(API.AUTH.LOGOUT.ACTION, unsubscribeFromUpdates);
}
