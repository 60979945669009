import React from 'react';

const TIMER_INTERVAL = 500;

const withCurrentDate = (interval = TIMER_INTERVAL) => WrappedComponent => {
  class ComponentWithCurrentDate extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        currentDate: new Date()
      };
    }

    componentDidMount() {
      this._timer = setInterval(() => {
        this.setState({currentDate: new Date()});
      }, interval);
    }

    componentWillUnmount() {
      if (this._timer) {
        clearInterval(this._timer);
        this._timer = null;
      }
    }

    render() {
      const {currentDate} = this.state;

      const props = {
        currentDate,
        ...this.props
      };

      return (
        <WrappedComponent {...props}/>
      );
    }
  }

  ComponentWithCurrentDate.WrappedComponent = WrappedComponent;

  return ComponentWithCurrentDate;
};

export default withCurrentDate;
