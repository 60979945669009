import React from 'react';
import * as PropTypes from 'prop-types';

import * as firebase from 'firebase/app';

import classNames from 'classnames';

import OverlaySpinner from '../overlay-spinner';

import styles from './styles.less';

class FileUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUploading: false
    };
  }

  render() {
    const {children, accept, disabled, multi, userClassName, userStyle} = this.props;
    const {isUploading} = this.state;

    const fileUploadClassNames = classNames({
      [styles['file-upload']]: true,
      [styles['file-upload-disabled']]: disabled,
      [userClassName]: Boolean(userClassName)
    });

    return (
      <div className={fileUploadClassNames} style={userStyle}>
        {children}
        <input
          disabled={disabled}
          multiple={multi}
          type="file"
          accept={accept}
          className={styles.input}
          onChange={disabled ? null : this._handleFileSelect}
        />
        <OverlaySpinner text="Uploading..." visible={isUploading}/>
      </div>
    );
  }

  _handleFileSelect = e => {
    const {createPath, multi, onUpload, onError} = this.props;

    const files = multi ? e.target.files : [e.target.files[0]];

    this.setState({isUploading: true}, async () => {
      try {
        for (const file of files) {
          /* eslint-disable no-await-in-loop */
          const path = createPath(file.name, file.type);

          const ref = firebase.storage()
            .ref()
            .child(path);

          await ref.put(file);

          if (onUpload) {
            await onUpload(ref, file.type);
          }
          /* eslint-enable no-await-in-loop */
        }
      } catch (err) {
        console.warn(err.message);

        if (onError) {
          onError(err);
        }
      }

      this.setState({isUploading: false});
    });
  };
}

FileUpload.propTypes = {
  children: PropTypes.node.isRequired,
  createPath: PropTypes.func.isRequired,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
  userClassName: PropTypes.string,
  userStyle: PropTypes.object,
  onUpload: PropTypes.func,
  onError: PropTypes.func
};

FileUpload.defaultProps = {
  accept: null,
  disabled: false,
  multi: false,
  userClassName: null,
  userStyle: null,
  onUpload: null,
  onError: null
};

export default FileUpload;
