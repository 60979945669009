import {createReducer} from '@ololoepepe/redux-tools';

import ISchedule from '../../models/schedule';

import {SCHEDULES} from '../action-types';

interface IState {
  error: any;
  isFetching: boolean;
  items: ISchedule[];
}

const INITIAL_STATE: IState = {
  error: null,
  isFetching: true,
  items: []
};

function mapItem({timeSlots, ...rest}) {
  const mappedTimeSlots = timeSlots.map(ts => {
    if (!ts.date) {
      return ts;
    }

    return {
      ...ts,
      date: ts.date.toDate()
    };
  });

  return {
    ...rest,
    timeSlots: mappedTimeSlots
  };
}

function addSingle(state: IState, {payload}): IState {
  if (state.items.some(item => item.id === payload.id)) {
    return state;
  }

  return {
    ...state,
    items: [...state.items, mapItem(payload)]
  };
}

function addList(state: IState, {payload}): IState {
  const list = payload.filter(({id}) => {
    return state.items.every(item => item.id !== id);
  }).map(mapItem);

  if (list.length < 1) {
    return state;
  }

  return {
    ...state,
    items: [...state.items, ...list]
  };
}

function deleteSingle(state: IState, {payload}): IState {
  const {id} = payload;

  const index = state.items.findIndex(item => item.id === id);

  if (index < 0) {
    return state;
  }

  const newItems = [...state.items];
  newItems.splice(index, 1);

  return {
    ...state,
    items: newItems
  };
}

function setFetched(state: IState, {payload}): IState {
  return {
    ...state,
    isFetching: !payload
  };
}

function updateSingle(state: IState, {payload}): IState {
  const {id, ...rest} = payload;

  const index = state.items.findIndex(item => item.id === id);

  if (index < 0) {
    return state;
  }

  const newItems = [...state.items];
  newItems[index] = mapItem({
    ...newItems[index],
    ...rest
  });

  return {
    ...state,
    items: newItems
  };
}

export default createReducer(INITIAL_STATE, {
  [SCHEDULES.ADD_SINGLE]: addSingle,
  [SCHEDULES.ADD_LIST]: addList,
  [SCHEDULES.CLEAR]: () => ({...INITIAL_STATE}),
  [SCHEDULES.DELETE_SINGLE]: deleteSingle,
  [SCHEDULES.SET_FETCHED]: setFetched,
  [SCHEDULES.UPDATE_SINGLE]: updateSingle
});
