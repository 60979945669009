import React from 'react';
import * as PropTypes from 'prop-types';

import axios from 'axios';
import classNames from 'classnames';
import * as firebase from 'firebase/app';
import {MD5 as md5} from 'object-hash';
import StripeCheckout from 'react-stripe-checkout';
import uuid from 'uuid/v4';

import {getUserSelfDefaultValues} from '../../../lib/form/default-values';
import {
  CREATE_INVITE_FORM_FIELDS,
  CHANGE_PASSWORD_FORM_FIELDS,
  getUpdateUserSelfFormFields
} from '../../../lib/form/fields';
import {handleSubmitFailure} from '../../../lib/form/helper';
import {getChangePasswordErrors, getCreateInviteErrors, getUpdateUserSelfErrors} from '../../../lib/form/error-getters';
import {validatePasswordsMatch} from '../../../lib/form/validators';
import {capitalizeFirstLetter, stringifyQuery} from '../../../lib/helper';
import history, {parseHref} from '../../../lib/history';

import Button from '../../common/button';
import Form from '../../common/form';
import Icon from '../../common/icon';
import Input from '../../common/input';
import MenuContainer from '../../common/menu-container';
import OverlaySpinner from '../../common/overlay-spinner';
import Page from '../../common/page';
import Select from '../../common/select';
import withDialog, {DIALOG_SHAPE} from '../../common/with-dialog';

import styles from './styles.less';

const SUBSCRIPTION_TYPE = {
  None: 0,
  OneScreen: 1,
  ThreeScreens: 3,
  FiveScreens: 5,
  SixScreens: 6,
  NineScreens: 9,
  TenScreens: 10,
  TwentyScreens: 20
};

const PLAN_NAMES = {
  [SUBSCRIPTION_TYPE.OneScreen]: '1 Screen',
  [SUBSCRIPTION_TYPE.ThreeScreens]: '3 Screens',
  [SUBSCRIPTION_TYPE.FiveScreens]: '5 Screens',
  [SUBSCRIPTION_TYPE.SixScreens]: '6 Screens',
  [SUBSCRIPTION_TYPE.NineScreens]: '9 Screens',
  [SUBSCRIPTION_TYPE.TenScreens]: '10 Screens',
  [SUBSCRIPTION_TYPE.TwentyScreens]: '20 Screens'
};

const PLAN_PRICES = {
  [SUBSCRIPTION_TYPE.OneScreen]: 20,
  [SUBSCRIPTION_TYPE.ThreeScreens]: 60,
  [SUBSCRIPTION_TYPE.FiveScreens]: 100,
  [SUBSCRIPTION_TYPE.SixScreens]: 108,
  [SUBSCRIPTION_TYPE.NineScreens]: 144,
  [SUBSCRIPTION_TYPE.TenScreens]: 180,
  [SUBSCRIPTION_TYPE.TwentyScreens]: 320
};

const DELETE_ORGANISATION_CODE = 'delete';

const DELETE_ORGANISATION_MESSAGE = 'Are you sure you want to delete this organisation? ' +
  'All users, campaigns, screens, schedules and all other data will be deleted. ' +
  'All subscriptions will be canceled. This action irreversible. ' +
  `If you are absolutely sure, please type "${DELETE_ORGANISATION_CODE}" and confirm.`;

function _sortTeamMembers(u1, u2) {
  if (u1.role === 'root') {
    return (u2.role === 'root') ? 0 : -1;
  }

  if (u1.role === 'admin') {
    return (u2.role === 'admin') ? 0 : -1;
  }

  return 1;
}

@withDialog({propName: 'createInviteDialog'})
class AccountPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabId: 'profile',
      formValuesHash: null,
      isChangingPassword: false,
      selectedOrganisationId: null,
      inviteFormErrors: null,
      isCreatingInvite: false,
      isChangingPlan: false,
      isDeletingUser: false,
      isDeletingOrganisation: false
    };
  }

  componentDidMount() {
    const {getUserList} = this.props;

    getUserList();
  }

  componentDidUpdate() {
    const {user} = this.props;
    const {selectedTabId} = this.state;

    const {query} = parseHref();

    const TAB_IDS = new Set(['profile', 'password', 'team']);

    if (user && (user.role === 'admin')) {
      TAB_IDS.add('subscription');
    }

    if (!TAB_IDS.has(query.tab)) {
      return;
    }

    if (query.tab !== selectedTabId) {
      this._selectTab(query.tab)();
    }
  }

  render() {
    const {createInviteDialog, user, organisation} = this.props;
    const {isChangingPlan, isDeletingUser} = this.state;

    const isLoading = !user || !organisation;

    const TABS = [{
      id: 'profile',
      title: 'Profile'
    }, {
      id: 'password',
      title: 'Password'
    }, {
      id: 'team',
      title: 'Team'
    }];

    if (user && (user.role === 'admin')) {
      TABS.push({
        id: 'subscription',
        title: 'Subscription'
      });
    }

    return (
      <Page title="Account" isLoading={isLoading}>
        {
          !isLoading && (
            <>
              <div className={styles.content}>
                <div className={styles.container}>
                  <div className={styles.sidebar}>
                    {
                      TABS.map(this._renderSidebarItem)
                    }
                  </div>
                  <div className={styles.data}>
                    {
                      this._renderData()
                    }
                  </div>
                </div>
              </div>
              {
                createInviteDialog.isDialogOpen && this._renderCreateInviteDialog()
              }
              <OverlaySpinner text="Changing plan..." visible={isChangingPlan}/>
              <OverlaySpinner text="Deleting user..." visible={isDeletingUser}/>
            </>
          )
        }
      </Page>
    );
  }

  _renderSidebarItem = ({id, title}) => {
    const {selectedTabId} = this.state;

    const itemClassNames = classNames(
      styles['sidebar-item'],
      {[styles['sidebar-item-selected']]: id === selectedTabId}
    );

    return (
      <div key={id} className={itemClassNames} onClick={this._selectTab(id)}>
        {title}
      </div>
    );
  };

  _renderData() {
    const {selectedTabId} = this.state;

    switch (selectedTabId) {
    case 'profile':
      return this._renderDataProfile();
    case 'password':
      return this._renderDataPassword();
    case 'team':
      return this._renderDataTeam();
    case 'subscription':
      return this._renderDataSubscription();
    default:
      return null;
    }
  }

  _renderDataProfile() {
    const {user, organisation, isUpdatingUser, updateSelf} = this.props;
    const {formValuesHash} = this.state;

    const formApi = this._userFormApiRef;

    const defaultValues = getUserSelfDefaultValues(user);

    return (
      <div>
        {
          (formValuesHash && (md5(defaultValues) !== formValuesHash)) && (
            <div className={styles['user-form-action-bar']}>
              <div className={styles['user-form-action-bar-title']}>
                Unsaved changes
              </div>
              <div className={styles['user-form-action-container']}>
                <Button
                  disabled={isUpdatingUser}
                  color={Button.COLOR.SECONDARY}
                  size={Button.SIZE.COMPACT}
                  text="Cancel"
                  onClick={this._cancelUserChanges}
                />
                <Button
                  disabled={isUpdatingUser || Boolean(formApi && formApi.errors)}
                  color={Button.COLOR.PRIMARY}
                  size={Button.SIZE.COMPACT}
                  text="Save"
                  onClick={this._submitUserForm}
                />
              </div>
            </div>
          )
        }
        <Form
          formApiRef={this._setUserFormApiRef}
          disabled={isUpdatingUser}
          fields={getUpdateUserSelfFormFields(user, this._renderTeamField(organisation.name))}
          defaultValues={defaultValues}
          userClassName={styles['user-form']}
          submitCallbackRef={this._setSubmitUserFormCallbackRef}
          onChange={this._handleFormChange}
          onSubmit={updateSelf}
          onSubmitFailure={handleSubmitFailure(getUpdateUserSelfErrors)}
        />
      </div>
    );
  }

  _renderDataPassword() {
    const {isChangingPassword} = this.state;

    return (
      <div>
        <Form
          formApiRef={this._setPasswordFormApiRef}
          disabled={isChangingPassword}
          actionText="Change Password"
          fields={CHANGE_PASSWORD_FORM_FIELDS}
          validate={validatePasswordsMatch}
          onSubmit={this._changePassword}
          onSubmitFailure={handleSubmitFailure(getChangePasswordErrors)}
        />
      </div>
    );
  }

  _renderDataTeam() {
    const {createInviteDialog, user, users, organisations, isFetchingOrganisations, isFetchingUsers} = this.props;
    const {selectedOrganisationId, isDeletingOrganisation} = this.state;

    const isRoot = user.role === 'root';
    const isFetching = isFetchingUsers || (isRoot && isFetchingOrganisations);

    const dataTeamClassNames = classNames(styles['data-team'], {
      [styles['data-team-fetching']]: isFetching
    });

    if (isFetching) {
      return (
        <div className={dataTeamClassNames}>
          Loading...
        </div>
      );
    }

    const options = isRoot ? organisations.map(({id, name}) => ({
      value: id,
      label: name
    })) : [];

    const teamMembers = isRoot ?
      users.filter(({organisationId}) => organisationId === selectedOrganisationId) :
      users.slice(0);

    return (
      <div className={dataTeamClassNames}>
        {
          isRoot && (
            <>
              <Button
                color={Button.COLOR.SECONDARY}
                size={Button.SIZE.LARGE}
                text="Create a Team"
                userClassName={styles['team-create-button']}
                onClick={this._handleAddTeam}
              />
              <Select
                options={options}
                value={selectedOrganisationId || ''}
                placeholder="Select team..."
                userClassName={styles['team-select']}
                onChange={this._selectOrganisationId}
              />
            </>
          )
        }
        <div>
          {
            teamMembers
              .sort(_sortTeamMembers)
              .map(this._renderTeamMember)
          }
        </div>
        {
          (['root', 'admin'].includes(user.role) && (!isRoot || selectedOrganisationId)) && (
            <div className={styles['team-actions-container']}>
              <Button
                color={Button.COLOR.PRIMARY_ALTERNATE}
                size={Button.SIZE.LARGE}
                text="Invite Another Team Mate"
                userClassName={styles['invite-button']}
                onClick={createInviteDialog.openDialog}
              />
              {
                (user.role === 'root') && (
                  <Button
                    disabled={isDeletingOrganisation}
                    color={Button.COLOR.SECONDARY}
                    size={Button.SIZE.LARGE}
                    text="Delete Organisation"
                    userClassName={styles['delete-button']}
                    onClick={this._deleteOrganisation}
                  />
                )
              }
            </div>
          )
        }
      </div>
    );
  }

  _renderDataSubscription() {
    const {user, screens} = this.props;

    if (!user) {
      return (
        <div className={styles.placeholder}>
          Loading...
        </div>
      );
    }

    const planName = user.subscriptionType ? `${PLAN_NAMES[user.subscriptionType]} (Monthly)` : 'Not selected';

    const plans = [{
      id: SUBSCRIPTION_TYPE.OneScreen
    }];

    if (user.subscriptionType === SUBSCRIPTION_TYPE.ThreeScreens) {
      plans.push({
        id: SUBSCRIPTION_TYPE.ThreeScreens
      });
    }

    plans.push({
      id: SUBSCRIPTION_TYPE.FiveScreens
    });

    if (user.subscriptionType === SUBSCRIPTION_TYPE.SixScreens) {
      plans.push({
        id: SUBSCRIPTION_TYPE.SixScreens
      });
    }

    if (user.subscriptionType === SUBSCRIPTION_TYPE.NineScreens) {
      plans.push({
        id: SUBSCRIPTION_TYPE.NineScreens
      });
    }

    plans.push({
      id: SUBSCRIPTION_TYPE.TenScreens
    }, {
      id: SUBSCRIPTION_TYPE.TwentyScreens
    });

    const cancelSubscriptionDisabled = screens.length > 0;

    const cancelSubscriptionClassNames = classNames({
      [styles['cancel-subscription']]: true,
      [styles['cancel-subscription-disabled']]: cancelSubscriptionDisabled
    });

    return (
      <div className={styles['data-subscription']}>
        <div className={styles['data-subscription-header']}>Your plan</div>
        <div className={styles['data-subscription-name']}>{planName}</div>
        {
          Boolean(user.subscriptionType) && (
            <div className={styles['data-subscription-update-date']}>
              Your plan will renew next month for ${PLAN_PRICES[user.subscriptionType]}
            </div>
          )
        }
        <div className={styles['data-subscription-change-hint']}>
          You can change your plan to upgrade, downgrade, or cancel billing:
        </div>
        <div className={styles['plan-container']}>
          {
            plans.map(this._renderPlan)
          }
        </div>
        {
          (user.subscriptionType > 0) && (
            <div className={styles['cancel-subscription-wrapper']}>
              <div
                className={cancelSubscriptionClassNames}
                onClick={cancelSubscriptionDisabled ? null : () => this._setSubscription(SUBSCRIPTION_TYPE.None)()}
              >
                Cancel Plan
              </div>
            </div>
          )
        }
        <div className={styles['contact-container']}>
          <Icon type={Icon.TYPE.INFO} userClassName={styles['contact-info']}/>
          <div className={styles['contact-text']}>
            Need more than 20 screens? <a href="mailto:sam@voyr.com.au" className={styles['contact-get-in-touch']}>Get in touch</a>
          </div>
        </div>
        <div className={styles['data-subscription-screen-count']}>
          {user.subscriptionType} Screen{(user.subscriptionType > 1) ? 's' : ''}
        </div>
      </div>
    );
  }

  _renderTeamField = team => () => {
    return (
      <div className={styles['team-field-container']}>
        <Input
          disabled
          name="team"
          value={team}
          userClassName={styles['team-field-input']}
        />
        <Button
          color={Button.COLOR.PRIMARY_ALTERNATE}
          size={Button.SIZE.COMPACT}
          text="View Team"
          userClassName={styles['team-field-button']}
          onClick={this._selectTab('team')}
        />
      </div>
    );
  };

  _renderTeamMember = teamMember => {
    const {uid, photoUrl, name, email, role} = teamMember;

    return (
      <div key={uid} className={styles['team-member']}>
        <div className={styles['team-member-info']}>
          <div className={styles['team-member-avatar-wrapper']}>
            {
              photoUrl ? (
                <img src={photoUrl} className={styles['team-member-avatar']}/>
              ) : (
                <Icon type={Icon.TYPE.CAMERA} userClassName={styles['team-member-avatar-placeholder']}/>
              )
            }
          </div>
          <div className={styles['team-member-name-container']}>
            <div className={styles['team-member-name']}>
              {name}
            </div>
            <div className={styles['team-member-email']}>
              {email}
            </div>
          </div>
        </div>
        <div className={styles['team-member-info']}>
          <div className={styles['team-member-role']}>
            {capitalizeFirstLetter(role)}
          </div>
          {
            (this.props.user.role !== 'user') && (
              <MenuContainer
                renderButton={this._renderTeamMemberMenuButton(teamMember)}
                renderMenu={this._renderTeamMemberMenu(teamMember)}
                userClassName={styles['team-member-menu-container']}
              />
            )
          }
        </div>
      </div>
    );
  };

  _renderTeamMemberMenuButton = ({uid, role}) => toggle => {
    const {user} = this.props;

    return (
      <Button
        disabled={(user.uid === uid) || !['root', 'admin'].includes(user.role) || (role !== 'user')}
        color={Button.COLOR.NONE}
        size={Button.SIZE.COMPACT}
        icon={Icon.TYPE.OPTIONS}
        onClick={toggle}
      />
    );
  };

  _renderTeamMemberMenu = ({uid}) => setMenuVisible => {
    return (
      <div className={styles.menu}>
        <div className={styles.option} onClick={this._handleDeleteUser(setMenuVisible, uid)}>Delete user</div>
      </div>
    );
  };

  _renderCreateInviteDialog() {
    const {createInviteDialog} = this.props;

    return createInviteDialog.renderDialog({
      renderTitle: () => 'Create Invite',
      renderContent: this._renderCreateInviteDialogContent,
      submitCallback: this._submitInviteForm,
      closeDisabledCallback: () => this.state.isCreatingInvite,
      submitDisabledCallback: () => {
        const {isCreatingInvite, inviteFormErrors} = this.state;

        return isCreatingInvite || Boolean(inviteFormErrors);
      }
    });
  }

  _renderCreateInviteDialogContent = () => {
    const {isCreatingInvite} = this.state;

    return (
      <Form
        disabled={isCreatingInvite}
        fields={CREATE_INVITE_FORM_FIELDS}
        userClassName={styles['invite-form']}
        submitCallbackRef={this._setSubmitInviteFormCallbackRef}
        onChange={this._handleInviteFormChange}
        onSubmit={this._createInvite}
        onSubmitFailure={handleSubmitFailure(getCreateInviteErrors)}
      />
    );
  };

  _renderPlan = ({id}) => {
    const {user, screens} = this.props;
    const {isChangingPlan} = this.state;

    const isCurrentPlan = user.subscriptionType === id;
    const buttonText = isCurrentPlan ? 'Current Plan' : ((id > user.subscriptionType) ? 'Upgrade' : 'Downgrade');

    const isDisabled = isCurrentPlan || isChangingPlan || (id < screens.length);

    const button = (
      <Button
        text={buttonText}
        disabled={isDisabled}
        userClassName={styles['plan-card-button']}
      />
    );

    return (
      <div key={id} className={styles['plan-card-wrapper']}>
        <div className={styles['plan-card']}>
          <div className={styles['plan-card-title']}>{PLAN_NAMES[id]}</div>
          <div className={styles['plan-card-screen-count']}>Connect up to {id} screen{(id > 1) ? 's' : ''}</div>
          <div className={styles['plan-card-price']}>${PLAN_PRICES[id]}</div>
          <div className={styles['plan-card-period']}>Per month</div>
          <div className={styles['plan-card-button-wrapper']}>
            {
              isDisabled ? button : (
                <StripeCheckout
                  stripeKey="pk_live_yXO78IODaYZqdTbJBr7Jkt9i00kbkRJrpY"
                  ComponentClass="div"
                  name="Voyr"
                  description={PLAN_NAMES[id]}
                  amount={PLAN_PRICES[id] * 100}
                  currency="USD"
                  email={user.email}
                  token={isDisabled ? null : this._handleUpgrade(id)}
                >
                  {button}
                </StripeCheckout>
              )
            }
          </div>
        </div>
      </div>
    );
  };

  _selectTab = id => () => {
    history.push(`/account?tab=${id}`);

    this.setState({selectedTabId: id});
  };

  _selectOrganisationId = id => {
    this.setState({selectedOrganisationId: id});
  };

  _handleFormChange = ({values}) => {
    this.setState({formValuesHash: md5(values)});
  };

  _cancelUserChanges = () => {
    if (!this._userFormApiRef) {
      return;
    }

    this._userFormApiRef.resetAll();
  };

  _changePassword = async ({oldPassword, password}) => {
    return new Promise((resolve, reject) => {
      this.setState({isChangingPassword: true}, async () => {
        try {
          const user = firebase.auth().currentUser;

          const credentials = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);

          await user.reauthenticateWithCredential(credentials);

          await user.updatePassword(password);
        } catch (err) {
          this.setState({isChangingPassword: false}, () => {
            reject(err);
          });

          return;
        }

        if (this._passwordFormApiRef) {
          this._passwordFormApiRef.resetAll();
        }

        this.setState({isChangingPassword: false}, resolve);
      });
    });
  };

  _handleDeleteUser = (setMenuVisible, uid) => () => {
    const {deleteUser} = this.props;

    setMenuVisible(false);

    // eslint-disable-next-line no-alert
    if (!confirm('Are you sure?')) {
      return;
    }

    this.setState({isDeletingUser: true}, () => {
      deleteUser(uid).then(() => {
        // eslint-disable-next-line no-alert
        alert('Success!');

        this.setState({isDeletingUser: false});
      }).catch(err => {
        console.log(err.response.data);
        console.error(err);

        // eslint-disable-next-line no-alert
        alert('Something went wrong, please, try again');

        this.setState({isDeletingUser: false});
      });
    });
  };

  _handleAddTeam = () => {
    // eslint-disable-next-line no-alert
    const name = prompt('Team name');

    if (!name) {
      return;
    }

    const {createOrganisation} = this.props;

    createOrganisation(name);
  };

  _handleInviteFormChange = ({errors}) => {
    this.setState({inviteFormErrors: errors});
  };

  _createInvite = ({email, role}) => {
    const {createInviteDialog, organisations, organisation, user} = this.props;
    const {selectedOrganisationId} = this.state;

    const organisationId = selectedOrganisationId || user.organisationId;

    return new Promise((resolve, reject) => {
      this.setState({isCreatingInvite: true}, async () => {
        const token = uuid();

        try {
          const {currentUser} = firebase.auth();

          if (!currentUser) {
            throw new Error('currentUser is null');
          }

          const authToken = await currentUser.getIdToken(true);

          const query = stringifyQuery({
            email,
            role,
            token,
            organisationId
          });

          await axios.request({
            method: 'post',
            url: `https://us-central1-voyr-6753d.cloudfunctions.net/express/createInvite?${query}`,
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          });
        } catch (err) {
          reject(err);

          return;
        }

        createInviteDialog.closeDialog();

        const organisationName = organisations.concat(organisation)
          .find(item => item.id === organisationId).name;

        const qs = stringifyQuery({
          token,
          organisationName,
          email,
          userName: user.name
        });

        const url = `${location.protocol}//${location.host}/registration?${qs}`;

        // eslint-disable-next-line no-alert
        prompt('Copy this registration link', url);

        this.setState({isCreatingInvite: false}, resolve);
      });
    });
  };

  _handleUpgrade = subscriptionType => token => {
    return this._setSubscription(subscriptionType)(token.id);
  };

  _deleteOrganisation = () => {
    const {deleteOrganisation} = this.props;
    const {selectedOrganisationId} = this.state;

    // eslint-disable-next-line no-alert
    if (prompt(DELETE_ORGANISATION_MESSAGE) !== DELETE_ORGANISATION_CODE) {
      return;
    }

    this.setState({isDeletingOrganisation: true}, () => {
      deleteOrganisation(selectedOrganisationId).then(() => {
        // eslint-disable-next-line no-alert
        alert('Success!');

        this.setState({isDeletingOrganisation: false});
      }).catch(err => {
        console.log(err.response.data);
        console.error(err);

        // eslint-disable-next-line no-alert
        alert('Something went wrong, please, try again');

        this.setState({isDeletingOrganisation: false});
      });
    });
  };

  _setSubscription = subscriptionType => token => {
    const {setSubscription} = this.props;

    this.setState({isChangingPlan: true}, async () => {
      try {
        await setSubscription(subscriptionType, token);
      } catch (err) {
        console.error(err);
      }

      this.setState({isChangingPlan: false});
    });
  };

  _submitUserForm = () => {
    if (!this._submitUserFormCallback) {
      return;
    }

    this._submitUserFormCallback();
  };

  _setPasswordFormApiRef = formApi => {
    this._passwordFormApiRef = formApi;
  };

  _setUserFormApiRef = formApi => {
    this._userFormApiRef = formApi;
  };

  _setSubmitUserFormCallbackRef = callback => {
    this._submitUserFormCallback = callback;
  };

  _submitInviteForm = () => {
    if (!this._submitInviteFormCallback) {
      return;
    }

    this._submitInviteFormCallback();
  };

  _setSubmitInviteFormCallbackRef = callback => {
    this._submitInviteFormCallback = callback;
  };
}

AccountPage.WrappedComponent.propTypes = {
  createInviteDialog: PropTypes.shape(DIALOG_SHAPE).isRequired
};

AccountPage.propTypes = {
  user: PropTypes.object,
  organisation: PropTypes.object,
  organisations: PropTypes.arrayOf(PropTypes.object).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  screens: PropTypes.arrayOf(PropTypes.object).isRequired,
  isUpdatingUser: PropTypes.bool.isRequired,
  isFetchingOrganisations: PropTypes.bool.isRequired,
  isFetchingUsers: PropTypes.bool.isRequired,
  getUserList: PropTypes.func.isRequired,
  setSubscription: PropTypes.func.isRequired,
  updateSelf: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  createOrganisation: PropTypes.func.isRequired,
  deleteOrganisation: PropTypes.func.isRequired
};

AccountPage.defaultProps = {
  user: null,
  organisation: null
};

export default AccountPage;
