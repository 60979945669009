import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';

import * as firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import {createStore} from './redux';

import App from './components/app';

import './css/index.less';

firebase.initializeApp({
  apiKey: 'AIzaSyDWQTBBhEynftjLwIx_nbY5ukmDXn_mRgs',
  authDomain: 'voyr-6753d.firebaseapp.com',
  databaseURL: 'https://voyr-6753d.firebaseio.com',
  projectId: 'voyr-6753d',
  storageBucket: 'voyr-6753d.appspot.com',
  messagingSenderId: '294523103121',
  appId: '1:294523103121:web:472d7524dcb8c805'
});

const {store, persistor} = createStore();

ReactDOM.render((
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App/>
    </PersistGate>
  </Provider>
), document.getElementById('app'));
